import { connect } from 'react-redux'
import EditProject from './EditProject'

import { setRangePlannerData, updateRangePlannerData } from '../../../store/imagelibraryfilters/actions';
import { setMenu } from '../../../store/menu/actions'
import { setGradeFilter } from '../../../store/imagelibraryfilters/actions'

const mapStateToProps = state => ({
	settings: state.settings,
	isFilterPanelOpen: state.imageLibraryData.isFilterPanelOpen,
	rangePlannerData: state.imageLibraryData.rangePlannerData,
	selectedGrade: state.selectedGrade
})

const mapDispatchToProps = dispatch => ({
	setRangePlannerData: (rangeItems) => {
		dispatch(setRangePlannerData(rangeItems))    
	},
	updateRangePlannerData: (rangeItems) => {
		dispatch(updateRangePlannerData(rangeItems))    
	},
	setMenu: menu => {
		dispatch(setMenu(menu))
	},
	setGradeFilter: grade => {
    dispatch(setGradeFilter(grade));
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(EditProject)