import { actions } from './actions'

const user = (state = { userDisplayName: 'UNKNOWN', userDisplayCaps: 'UNKNOWN', userEmailAddress: 'UNKNOWN' }, action) => {
  switch (action.type) {
    case actions.set:
      return Object.assign(state, action.user)
    default:
      return state
  }
}

export default user