import React from 'react'
import PropTypes from 'prop-types'

/**
 * A generalised modal dialogue box. Add the desired child components and function handlers
 * as child elements under this Modal element.
 */

const Modal = (props) => {
  if (!props.isOpen) {
    return null
  }

  return (
    <div className="ln-c-modal js-modal is-open">
      <div className="ln-o-flag ln-u-fill-height">
        <div className="ln-o-flag__body">
          <div className="ln-c-modal__body js-modal-body" tabIndex="0" role="dialog">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
}

export default Modal
