import { ArrowUpDown } from "@jsluna/icons";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { logout } from "../../../api/auth";
import ClothingIcon from "../../../assets/images/ic_clothing.svg";
import LogoutIcon from "../../../assets/images/ic_logout.svg";
import ProjectsIcon from "../../../assets/images/ic_projects.svg";
import SainsburysLogo from "../../../assets/images/s-logo.svg";
import Constants from "../../../utils/constants";
import { withRouter } from "../../../utils/reactRouterDom";
import MainMenuButton from "../MainMenuButton";

class MainSideMenu extends Component {
  constructor(props) {
    super(props);
    this.handleProjectsClick = this.handleProjectsClick.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleClothingClick = this.handleClothingClick.bind(this);
    this.handleGradesClick = this.handleGradesClick.bind(this);
    this.navigate = this.props.router.navigate;
  }

  handleProjectsClick() {
    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.projects,
    });
    this.navigate("/projects");
  }

  handleClothingClick() {
    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.clothing,
      optionalMenuItems: [Constants.MenuOptions.clothing],
    });
  }

  handleGradesClick() {
    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.grades,
      optionalMenuItems: [Constants.MenuOptions.grades],
    });
  }

  handleLogoutClick() {
    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.logOut,
    });
    logout();
  }

  render() {
    return (
      <div
        className={`mainSideMenu ${
          this.props.hideMenu === true ? "mainSideMenu--hidden" : ""
        }`}
        id="MainSideMenu"
      >
        <div className="mainSideMenuLogo">
          <img src={SainsburysLogo} alt="Sainburys logo" />
        </div>
        <MainMenuButton
          title={Constants.MenuOptions.projects}
          handleClick={this.handleProjectsClick}
        >
          <img
            src={ProjectsIcon}
            alt={Constants.MenuOptions.projects}
            id="ProjectsMenuButton"
          />
        </MainMenuButton>
        {this.props.optionalMenuItems &&
          this.props.optionalMenuItems.includes(
            Constants.MenuOptions.clothing
          ) === true && (
            <MainMenuButton
              title={Constants.MenuOptions.clothing}
              handleClick={this.handleClothingClick}
            >
              <img
                src={ClothingIcon}
                alt={Constants.MenuOptions.clothing}
                id="ClothingMenuButton"
              />
            </MainMenuButton>
          )}
        {this.props.optionalMenuItems &&
          this.props.optionalMenuItems.includes(
            Constants.MenuOptions.grades
          ) === true && (
            <MainMenuButton
              title={Constants.MenuOptions.grades}
              handleClick={this.handleGradesClick}
            >
              <ArrowUpDown />
            </MainMenuButton>
          )}
        <MainMenuButton
          title={Constants.MenuOptions.logOut}
          handleClick={this.handleLogoutClick}
        >
          <img
            src={LogoutIcon}
            alt={Constants.MenuOptions.logOut}
            id="LogoutMenuButton"
          />
        </MainMenuButton>
      </div>
    );
  }
}

MainSideMenu.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(MainSideMenu);
