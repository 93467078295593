const BayConstants =
{
  spriteType: {
    bay: 'bay',
    item: 'item',
    itemHighlight: 'itemHighlight',
    itemSelect: 'itemSelect',
    button: 'button',
    hitPoint: 'hitPoint'
  },
  alpha: 1,
  dragAlpha: 0.5,
  bayBuilderSplitScreenMode: {
    none: 'none',
    select: 'splitScreenSelect',
    build: 'splitScreenBuild'
  }
}

export default BayConstants