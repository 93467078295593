import { Button } from "@jsluna/react";
import PropTypes from "prop-types";
import React from "react";
import baguette from "../../../assets/images/baguette-crumbs.png";

const handleGoBackButtonClick = (e, setSelectedMenu, onGoBackButtonClick) => {
  e.preventDefault();
  if (onGoBackButtonClick) {
    onGoBackButtonClick();
  } else {
    window.history.go(-2);
  }
};

const ApplicationError = ({
  title,
  children,
  fullscreen,
  setSelectedMenu,
  buttonText,
  onGoBackButtonClick,
}) => (
  <div>
    <div
      className={
        fullscreen
          ? "c-application-error-wrapper"
          : "c-application-error-wrapper c-application-error-wrapper--fullscreen"
      }
    >
      <section className="c-application-error c-application-error-center">
        <div className="c-application-error-content">
          <img
            className="c-application-error__image c-application-error__image--overlapping"
            alt=""
            src={baguette}
          />
          <div className="c-application-error__title">{title}</div>
          {children}
          <Button
            type="button"
            variant="filled"
            onClick={(e) =>
              handleGoBackButtonClick(e, setSelectedMenu, onGoBackButtonClick)
            }
          >
            {buttonText}
          </Button>
        </div>
      </section>
    </div>
  </div>
);

ApplicationError.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  fullscreen: PropTypes.bool,
  setSelectedMenu: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  onGoBackButtonClick: PropTypes.func,
};

export default ApplicationError;
