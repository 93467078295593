import React from 'react'
import { PropTypes } from 'prop-types'
import {
  ButtonGroupWrapper, OutlinedButton, FilledButton,
  ModalHeading, Modal, Card, ButtonGroupPrimary
} from '@jsluna/react';


function DeleteBayModal(props) {
  return (
    <Modal
      restrictClose
      open={props.isOpen}
      className={"delete-range-modal"}
    >
      <Card className={"delete-range-modal-body"}>
        <ModalHeading className={"modalheading"}>Are you sure you want to delete Bay {props.bayNumber} from Gondola {props.gondolaIndex + 1}?</ModalHeading>
        <p className={"delete-modal-description"}>Deleting this bay will remove all associated clothing layouts.</p>
        <p className={"delete-modal-description"}>You will not be able to undo this action.</p>
        <ButtonGroupWrapper actionBar className={"DeleteCancelButtonWrapper"}>
          <ButtonGroupPrimary>
            <FilledButton className={"yes-button"} onClick={props.onDeleteBay}>
              Delete Bay
            </FilledButton>
            <OutlinedButton color="dark" className={"no-button"} onClick={props.onModalClose}>
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Card>
    </Modal>
  )
}

DeleteBayModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDeleteBay: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  bayNumber: PropTypes.number,
  gondolaIndex: PropTypes.number,
}

export default DeleteBayModal

