import { Plus } from "@jsluna/icons";
import {
  Container,
  GridItem,
  GridWrapper,
  ProgressIndicator,
  ProgressSpinner,
} from "@jsluna/react";
import { PropTypes } from "prop-types";
import React from "react";
import Constants from "../../../utils/constants";
import { withRouter } from "../../../utils/reactRouterDom";
import Banner from "../../Banner/Banner";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import DeleteProjectModal from "../DeleteProjectModal";
import ProjectCard from "../ProjectCard/ProjectCard";

import { api } from "../../../api/api";
/* eslint-disable jsx-a11y/anchor-is-valid */

class ProjectsLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshImagesCount: -1,
      bannerOpen: false,
      bannerObject: { type: "", message: "" },
      refreshedProjectName: "",
      loading: true,
      projects: [],
      openOverflowMenuId: -1,
      showDeleteProjectModalProject: { id: -1, name: "" },
      showDeleteProjectModal: false,
    };
    this.handleCreateNewClick = this.handleCreateNewClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.refreshImages = this.refreshImages.bind(this);
    this.handleCloseBanner = this.handleCloseBanner.bind(this);
    this.navigate = this.props.router.navigate;
  }

  async componentDidMount() {
    this.props.setMenu({ selectedMenu: Constants.MenuOptions.projects });
    this.getProjectsData();
  }

  async getProjectsData() {
    api
      .getProjectItemsAll(this.props.settings.baseApiUrl)
      .then((data) => {
        data.sort((a, b) => {
          a = new Date(a.createdAt);
          b = new Date(b.createdAt);
          return a > b ? -1 : a < b ? 1 : 0;
        });
        this.setState({ projects: data, loading: false });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  handleCreateNewClick(e) {
    e.preventDefault();
    this.props.setProjectToClone(null);
    this.props.setSelectedGrade(Constants.DefaultGradeNumber);
    this.navigate(`/projects/newproject`);
  }

  handleEditClick = (id) => {
    this.props.setSelectedGrade(Constants.DefaultGradeNumber);
    this.navigate(`/projects/${id}/view`);
  };

  setOpenOverflowMenuId = (id) => {
    let { openOverflowMenuId } = this.state;
    if (openOverflowMenuId === id) {
      this.setState({ openOverflowMenuId: -1 });
    } else {
      this.setState({ openOverflowMenuId: id });
    }
  };

  manageProject = (project) => {
    this.props.setProjectToManage(project);
    this.navigate(`/projects/${project.id}/manageproject`);
  };

  cloneProject = (project) => {
    this.props.setProjectToClone(project);
    this.props.setSelectedGrade(Constants.DefaultGradeNumber);
    this.navigate(`projects/newproject`);
  };

  refreshImages = (projectItem) => {
    this.setState({ refreshedProjectName: projectItem.name, loading: true });
    api
      .updateProjectImages(this.props.settings.baseApiUrl, projectItem.id)
      .then((data) => {
        this.setState({
          refreshImagesCount: data,
          loading: false,
          openOverflowMenuId: -1,
        });
        if (data < 1) {
          this.setState({
            bannerObject: {
              type: "info",
              message: `No images updated and refreshed in ${projectItem.name}`,
            },
            bannerOpen: true,
          });
        } else {
          this.setState({
            bannerObject: {
              type: "success",
              message: `${data} images updated and refreshed in ${projectItem.name}`,
            },
            bannerOpen: true,
          });
        }
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  };

  handleCloseBanner(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ bannerOpen: false });
  }

  showDeleteProjectModal = (project) => {
    this.setState({
      showDeleteProjectModalProject: project,
      showDeleteProjectModal: true,
    });
  };

  cancelDeleteProjectModal = () => {
    this.setState({
      showDeleteProjectModal: false,
      showDeleteProjectModalProject: { id: -1, name: "" },
    });
  };

  deleteProject = () => {
    api
      .deleteProject(
        this.props.settings.baseApiUrl,
        this.state.showDeleteProjectModalProject.id
      )
      .then((data) => {
        var projects = this.state.projects;
        var projectsIndex = projects.findIndex(
          (project) =>
            project.id === this.state.showDeleteProjectModalProject.id
        );
        projects.splice(projectsIndex, 1);
        this.setState({
          bannerObject: {
            type: "success",
            message: `Project ${this.state.showDeleteProjectModalProject.name} has been successfully deleted`,
          },
          bannerOpen: true,
        });
        this.setState({ projects });
        this.setState({
          showDeleteProjectModal: false,
          showDeleteProjectModalProject: { id: -1, name: "" },
        });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  };

  render() {
    return (
      // need to do the side bar nav into a component for use here
      <div>
        <div className="project-content">
          <Container size="lg" soft>
            {this.state.refreshedProjectName === false &&
              this.state.loading === true && (
                <LoadingIndicator message={this.state.refreshedProjectName} />
              )}
            {this.state.refreshedProjectName && this.state.loading && (
              <ProgressIndicator
                className="ln-u-justify-content-center"
                page
                loading
                preventFocus
              >
                <ProgressSpinner className="ln-u-push-right-sm" color="light" />
                <div>
                  <p className={"projectLanding-loading-indicator-uppertext"}>
                    Updating and refreshing images in
                  </p>
                  <p className={"projectLanding-loading-indicator-lowertext"}>
                    {this.state.refreshedProjectName}
                  </p>
                </div>
              </ProgressIndicator>
            )}
            {this.state.loading === false && (
              <React.Fragment>
                <DeleteProjectModal
                  isOpen={this.state.showDeleteProjectModal}
                  onDeleteProject={this.deleteProject}
                  onModalClose={this.cancelDeleteProjectModal}
                  project={this.state.showDeleteProjectModalProject}
                />
                <h2>Projects</h2>
                {this.state.bannerOpen && (
                  <Banner
                    type={this.state.bannerObject.type}
                    message={this.state.bannerObject.message}
                    onhandleClose={this.handleCloseBanner}
                  />
                )}
                <GridWrapper matrix element="ul" equalHeight>
                  <GridItem
                    key={"CreateProject"}
                    size={{ xxl: "1/4", lg: "1/3", sm: "1/2", xs: "1/1" }}
                    element="li"
                  >
                    <a
                      href={""}
                      tabIndex="0"
                      onClick={(e) => this.handleCreateNewClick(e)}
                      className="project-container project-container-new-project"
                    >
                      <div>
                        <div className="project-plus-button-container">
                          <div className="project-plus-button">
                            <Plus size="large" />
                          </div>
                        </div>
                        <h4>Create new project</h4>
                      </div>
                    </a>
                  </GridItem>
                  {this.state.projects.map((projectItem) => (
                    <GridItem
                      key={projectItem.id}
                      size={{ xxl: "1/4", lg: "1/3", sm: "1/2", xs: "1/1" }}
                      element="li"
                    >
                      <ProjectCard
                        key={projectItem.id}
                        projectItem={projectItem}
                        onSelect={this.handleEditClick}
                        onManageProject={this.manageProject}
                        onStartClone={this.cloneProject}
                        onRefreshImages={this.refreshImages}
                        onDeleteProject={this.showDeleteProjectModal}
                        onOpenOverflow={() =>
                          this.setOpenOverflowMenuId(projectItem.id)
                        }
                        overflowMenuOpen={
                          this.state.openOverflowMenuId === projectItem.id
                        }
                      />
                    </GridItem>
                  ))}
                </GridWrapper>
              </React.Fragment>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
ProjectsLandingPage.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
  setSelectedGrade: PropTypes.func.isRequired,
};

export default withRouter(ProjectsLandingPage);
