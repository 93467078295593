import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  Card,
  FilledButton,
  Label,
  List,
  ListItem,
  Modal,
  ModalHeading,
  OutlinedButton,
  TextButton,
  TextInputField,
} from "@jsluna/react";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import Gondola from "../../../../builder/Gondola";
import ModalStylingFixForWin32 from "../../../../common/ModalStylingFixForWin32";
import { withRouter } from "../../../../utils/reactRouterDom";

const { ErrorCircle } = require("@jsluna/icons");

export class AddRangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeName: "",
      gondolas: 1,
      rangeItems: [-1],
      disableAddBtn: true,
      disableAddRangeBtn: true,
      chosenGondola: 0,
      chosenBay: 0,
      rangeNameError: false,
    };
    this.handleRangeNameChange = this.handleRangeNameChange.bind(this);
    this.addAnotherGondola = this.addAnotherGondola.bind(this);
    this.removeGondola = this.removeGondola.bind(this);
    this.disableAddButton = this.disableAddButton.bind(this);
    this.addRangeToGrade = this.addRangeToGrade.bind(this);
    this.cancelAddToGrade = this.cancelAddToGrade(this);
    this.numberOfBays = 7;
    this.maxNumOfGondolas = 4;
  }

  componentDidUpdate(newProps) {
    if (newProps.isOpen !== this.props.isOpen && this.props.isOpen === true) {
      this.setState({
        rangeItems: [-1],
        rangeName: "",
        gondolas: 1,
        disableAddBtn: true,
        disableAddRangeBtn: true,
        rangeNameError: false,
      });
    }
  }

  findEmptyGondola(gondola) {
    return gondola < 0;
  }

  disableAddButton() {
    var emptyGondola = this.state.rangeItems.find(this.findEmptyGondola);
    if (
      this.state.rangeItems.length === this.maxNumOfGondolas ||
      emptyGondola
    ) {
      if (emptyGondola === undefined) {
        this.setState({ disableAddRangeBtn: false });
      }
      this.setState({ disableAddBtn: true });

      this.getClassName(this.state.chosenGondola, this.state.chosenBay);
      return;
    } else {
      this.setState({ disableAddBtn: false, disableAddRangeBtn: false });
    }
  }

  handleRangeNameChange(rangeNameUpdate) {
    const firstChar = rangeNameUpdate.substr(0, 1);
    if (firstChar === " ") {
      return;
    }

    if (!/[^.0,50 0-9a-zA-Z()&'.-]/.test(rangeNameUpdate)) {
      if (rangeNameUpdate.length <= 50) {
        let rangeName = rangeNameUpdate;
        this.setState({ rangeName, rangeNameError: false });
      }
    }
  }

  getRangeName() {
    if (this.state.rangeName) {
      return this.state.rangeName;
    } else {
      return "";
    }
  }

  addAnotherGondola() {
    const state = this.state;
    const newValue = state.gondolas + 1;
    var range = state.rangeItems.concat(-1);
    this.setState({
      gondolas: newValue,
      rangeItems: range,
      disableAddBtn: true,
      disableAddRangeBtn: true,
    });
  }

  removeGondola(gondola) {
    const state = this.state;
    const newValue = this.state.gondolas - 1;
    state.rangeItems.splice(gondola, 1);
    this.setState({ gondolas: newValue });
    this.disableAddButton();
  }

  getClassName(gondola, bay) {
    if (bay <= this.state.rangeItems[gondola]) {
      return "fillButton";
    } else {
      return "noFillButton";
    }
  }

  gondolaSizeClick(gondola, bay) {
    const state = this.state;
    const rangeArray = state.rangeItems;
    rangeArray[gondola] = bay;
    this.setState({
      rangeItems: rangeArray,
      chosenBay: bay,
      chosenGondola: gondola,
    });
    this.disableAddButton();
  }

  addRangeToGrade() {
    const { rangeName, rangeItems } = this.state;

    if (rangeName === "") {
      return this.setState({ rangeNameError: true });
    } else {
      this.setState({ rangeNameError: false });
    }

    const rangeDetails = {
      id: 0,
      name: rangeName.trim(),
      gondolas: [],
    };

    rangeItems.forEach((item) => {
      rangeDetails.gondolas.push({
        bayCount: item + 1,
        items: this.getItemsJson(item + 1),
      });
    });

    this.props.onAddRangeToGrade(rangeDetails);
    this.props.onModalClose();
  }

  getItemsJson(bayCount) {
    let items = Gondola.generateBarItemsForNewGondola(bayCount);
    return JSON.stringify(items);
  }

  cancelAddToGrade() {
    this.props.onModalClose();
  }

  renderGondolas = () => {
    let rangeArray = Array(this.state.gondolas);
    let gondolaArray = Array(this.numberOfBays);
    if (this.state.gondolas) {
      return (
        <List type="bare" spaced>
          {rangeArray.fill(0).map((_, gondolaIndex) => (
            <ListItem key={gondolaIndex} className={"gondola-list"}>
              <List type="matrix">
                <ListItem
                  className={
                    gondolaIndex < 9
                      ? "gondola-label-lt10"
                      : "gondola-label-mt10"
                  }
                >
                  <Label htmlFor="">Gondola {gondolaIndex + 1}</Label>
                </ListItem>
                {gondolaArray.fill(0).map((_, bayIndex) => (
                  <ListItem key={bayIndex} className={"bay-buttons"}>
                    <OutlinedButton
                      color="dark"
                      data-testid={`add-gondola-button-${bayIndex + 1}`}
                      className={this.getClassName(gondolaIndex, bayIndex)}
                      onClick={(e) =>
                        this.gondolaSizeClick(gondolaIndex, bayIndex)
                      }
                    >
                      {bayIndex + 1}
                    </OutlinedButton>
                  </ListItem>
                ))}
                {gondolaIndex >= 1 && (
                  <ListItem>
                    <TextButton
                      color="dark"
                      tabIndex={"0"}
                      className={"remove-link"}
                      onClick={() => this.removeGondola(gondolaIndex)}
                    >
                      Remove
                    </TextButton>
                  </ListItem>
                )}
              </List>
            </ListItem>
          ))}
        </List>
      );
    }
  };

  render = () => {
    return (
      <Modal
        data-testid="range-modal"
        restrictClose
        open={this.props.isOpen}
        className={"add-range-modal-body"}
      >
        <Card className={"modal-range-body"}>
          <ModalHeading
            className={"modalheading"}
          >{`Add range to Grade ${this.props.gradeNumber}`}</ModalHeading>
          <div className="range-name">
            <TextInputField
              name="rangeName"
              label="Range name"
              error={this.state.rangeNameError && "You must enter a range name"}
              onChange={(e) =>
                this.handleRangeNameChange(e.currentTarget.value)
              }
              value={this.getRangeName()}
            />
          </div>
          <div>
            <h3 data-testid="configuration-heading" className={"h3-heading"}>
              Gondola configuration
            </h3>
            <p
              data-testid="gondola-description"
              className={"bayConfig-description"}
            >
              Select the number of bays for each Gondola in this range
            </p>
            <div className={"gondola-configuration"}>
              {this.renderGondolas()}
            </div>
          </div>
          <div className={"add-gondola-container"}>
            <OutlinedButton
              data-testid="add-another-gondola-button"
              color="dark"
              className={"add-gondola-button"}
              onClick={() => this.addAnotherGondola()}
              disabled={this.state.disableAddBtn}
            >
              Add another Gondola
            </OutlinedButton>
            {this.state.gondolas === this.maxNumOfGondolas && (
              <div className="error-circle-container">
                <ErrorCircle
                  data-testid="error-circle"
                  className={"error-circle"}
                />{" "}
                <p className={"error-circle-description"}>
                  {" "}
                  The maximum number of Gondolas has been reached
                </p>
              </div>
            )}
          </div>
          <ButtonGroupWrapper actionBar className={"addCancelButtonWrapper"}>
            <ButtonGroupPrimary>
              <FilledButton
                data-testid="add-button"
                className={"add-range-button"}
                onClick={() => this.addRangeToGrade()}
                disabled={this.state.disableAddRangeBtn}
              >
                Add range
              </FilledButton>
              <OutlinedButton
                data-testid="cancel-button"
                color="dark"
                className={"cancel-button"}
                onClick={this.props.onModalClose}
              >
                Cancel
              </OutlinedButton>
            </ButtonGroupPrimary>
          </ButtonGroupWrapper>
        </Card>
      </Modal>
    );
  };
}

AddRangeModal.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onAddRangeToGrade: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  gradeNumber: PropTypes.number.isRequired,
};

export default withRouter(ModalStylingFixForWin32(AddRangeModal));
