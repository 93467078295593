import { Button, Link, OutlinedButton, TextButton } from "@jsluna/react";
import debounce from "lodash.debounce";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import upIconArrow from "../../../../assets/images/up-icon-arrow.svg";
import RangeGondolaView from "../RangeGondolaView";

class ProjectRangeView extends Component {
  constructor(props) {
    super(props);

    this.rangeIdToIndexMap = {};
    this.scrollY = 0;

    this.renderProjectRanges = this.renderProjectRanges.bind(this);
    this.renderRange = this.renderRange.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onScrollDebounced = debounce(this.onScroll, 16);
  }

  static getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScrollDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollDebounced);
    this.onScrollDebounced.cancel();
  }

  onScroll(e) {
    if (e) {
      e.preventDefault();
    }

    const scrollingDown = window.scrollY > this.scrollY;
    this.scrollY = window.scrollY;

    const rangeItems = document.querySelectorAll(
      ".project-range-view-item-container"
    );
    const { activeRangeId } = this.props;
    let newRangeId = activeRangeId;

    for (let i = 0; i < rangeItems.length; i += 1) {
      const boundingRectangle = rangeItems[i].getBoundingClientRect();
      if (boundingRectangle.bottom >= window.innerHeight / 2) {
        const newRangeIndex = Number(rangeItems[i].id.slice("range".length));
        const activeRangeIndex = Number(this.rangeIdToIndexMap[activeRangeId]);
        newRangeId = Number(
          ProjectRangeView.getKeyByValue(this.rangeIdToIndexMap, newRangeIndex)
        );
        if (
          newRangeId !== activeRangeId &&
          ((scrollingDown === true && newRangeIndex > activeRangeIndex) ||
            (scrollingDown === false && newRangeIndex < activeRangeIndex))
        ) {
          this.props.onProjectRangeActiveChange(newRangeId);
        }
        return;
      }
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth", block: "start" });
  }

  selectRange(rangeId) {
    const index = this.rangeIdToIndexMap[`${rangeId}`];
    if (index != null) {
      const element = document.querySelector(`#range${index}`);
      if (element != null) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }

  renderProjectRanges(ranges) {
    const projectRanges = [];
    ranges.map((range, index) =>
      projectRanges.push(this.renderRange(range, index))
    );
    return projectRanges;
  }

  renderRange(range, index) {
    // Add this range to the ranges object so that we can scroll to it
    this.rangeIdToIndexMap[`${range.id}`] = index;
    const bayCount = range.gondolas.reduce(
      (total, gondola) => total + gondola.bayCount,
      0
    );
    const rangeNumber = index + 1;
    return (
      <div
        className="project-range-view-item-container"
        id={`range${index.toString()}`}
        key={range.id.toString()}
      >
        <div
          data-testid="project-range-view-item"
          className="project-range-view-item"
        >
          <h4>
            {range.name}
            <span className="project-range-title">
              {` (${range.gondolas.length} ${
                range.gondolas.length > 1 ? "Gondolas" : "Gondola"
              }, ${bayCount} ${bayCount > 1 ? "Bays" : "Bay"})`}
            </span>
          </h4>
          <div className="project-range-buttons">
            <Button
              variant="filled"
              data-testid="build-range-button"
              className="project-range-buttons--build"
              onClick={(e) =>
                this.props.onBuildRangeClick(e, range.id, range.name)
              }
            >
              Build range
            </Button>
            <OutlinedButton
              data-testid="export-range-button"
              color="dark"
              className="project-range-buttons--export"
              onClick={(e) =>
                this.props.onExportRangeClick(
                  e,
                  this.props.projectId,
                  range.id,
                  range.gondolas,
                  range.name,
                  rangeNumber
                )
              }
            >
              Export range
            </OutlinedButton>
            <OutlinedButton
              data-testid="edit-range-button"
              color="dark"
              className="project-range-buttons--edit"
              onClick={(e) =>
                this.props.onEditRangeClick(e, range.id, range.name)
              }
            >
              Edit range details
            </OutlinedButton>
            <TextButton
              data-testid="delete-range-button"
              color="dark"
              className="project-range-buttons--delete"
              onClick={(e) => this.props.onDeleteRangeClick(e, range)}
            >
              Delete range
            </TextButton>
          </div>
          <div className="project-thumbnail">
            <div className="stage-viewMode">
              {this.renderRangeGondolas(range.gondolas)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRangeGondolas(gondolas) {
    const rangeGondolas = [];
    let bayIndex = 1;
    for (var i = 0; i < gondolas.length; i++) {
      rangeGondolas.push(this.renderRangeGondola(gondolas[i], bayIndex));
      bayIndex += gondolas[i].bayCount;
    }
    return rangeGondolas;
  }

  renderRangeGondola(gondola, bayIndex) {
    return (
      <RangeGondolaView
        gondola={gondola}
        bayIndex={bayIndex}
        projectId={this.props.projectId}
        settings={this.props.settings}
        key={gondola.id}
      />
    );
  }

  render() {
    return (
      <div className="project-range-view">
        {this.renderProjectRanges(this.props.ranges)}
        {this.props.ranges != null && this.props.ranges.length > 0 && (
          <>
            <img
              className="project-range-view-scroll-image"
              alt=""
              src={upIconArrow}
            />
            <Link
              href="#"
              cta
              bare
              onClick={(e) => this.props.onBackToTopClick(e)}
            >
              Back to top
            </Link>
          </>
        )}
      </div>
    );
  }
}

ProjectRangeView.propTypes = {
  projectId: PropTypes.number.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      gondolas: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          bayCount: PropTypes.number.isRequired,
          hasItems: PropTypes.bool.isRequired,
          hasImage: PropTypes.bool.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  activeRangeId: PropTypes.number.isRequired,
  onProjectRangeActiveChange: PropTypes.func.isRequired,
  onBackToTopClick: PropTypes.func.isRequired,
  onBuildRangeClick: PropTypes.func.isRequired,
  onEditRangeClick: PropTypes.func.isRequired,
  onDeleteRangeClick: PropTypes.func.isRequired,
  onExportRangeClick: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    baseApiUrl: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProjectRangeView;
