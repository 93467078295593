import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "../../../utils/reactRouterDom";
import {
  Container,
  Button,
  OutlinedButton,
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  Card,
  ProgressIndicator,
  ProgressSpinner,
  TextInputField,
  TextButton,
  FilledButton,
  TableContainer,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableBody,
  TableCell,
  TableRow,
} from "@jsluna/react";
import moment from "moment";
import { api, fileDownloadFunctions } from "../../../api/api";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import Breadcrumbs from "../../Controls/Breadcrumbs";
import breadcrumbHelper from "../../../utils/breadcrumbHelper";
import Banner from "../../Banner/Banner";
import Constants from "../../../utils/constants";
import ManageProjectModal from "./ManageProjectModal";

const { ErrorCircle } = require("@jsluna/icons");

export class ManageProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project || null,
      loading: true,
      bannerObject: {
        bannerOpen: false,
        type: "",
        message: "",
      },
      searchValues: {
        style: "",
        colour: "",
      },
      result: {
        error: "",
        rangePlannerItems: new Map([]),
      },
      downloadPlanStatus: {
        exportRangePlan: false,
        rangePlanName: "",
      },
      styleNotValid: true,
      colourNotValid: true,
      disableSearchBtn: true,
      modalType: "",
      modalIsOpen: false,
    };
    this.styleRegex = /^\d{9}$/;
    this.numbersAllowedRegex = /^\d$/;
    this.exceptedFilterRegex = /^\D+\s$/;
    this.colourRegex = /^(?=[A-Za-z])([A-Za-z\s ]*)(?<=[A-Za-z ])$/;
    this.columns = [
      "RangeId",
      "Season",
      "Class",
      "Style",
      "Colour",
      "Description",
      "EditedGrade",
    ];
    this.navigate = this.props.router.navigate;
  }

  componentDidMount() {
    const projectId = this.props.router.params.projectId
      ? parseInt(this.props.router.params.projectId, 0)
      : 0;

    if (projectId <= 0) {
      this.navigate(`/projects`);
      return;
    }

    if (Object.entries(this.props.project).length === 0) {
      api
        .getProjectInformation(
          this.props.settings.baseApiUrl,
          this.props.router.params.projectId
        )
        .then((data) => {
          this.props.setProjectToManage({ project: data });
          this.setState({ loading: false, project: data });
        })
        .catch((e) => {
          api.handleError(this.props.router.location, e.message);
        });
    }

    this.setState({ loading: false });
    this.props.setMenu({ selectedMenu: Constants.MenuOptions.none });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const itemsToBeSaved = [];
    const { result, bannerObject } = this.state;
    for (var element of result.rangePlannerItems.entries()) {
      itemsToBeSaved.push({
        rangeId: element[0],
        editedGrade: parseInt(element[1].editedGrade),
      });
    }
    api
      .saveNewRangePlannerItems(
        this.props.settings.baseApiUrl,
        this.props.router.params.projectId,
        itemsToBeSaved
      )
      .then(() => {
        bannerObject.type = "success";
        bannerObject.message = `Successfully added ${itemsToBeSaved.length} items to project`;
        bannerObject.bannerOpen = true;
        this.removeAllStylesFromState(e);
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  };

  handleSearch = (e) => {
    e.preventDefault();
    const { result, bannerObject } = this.state;
    var bannerOpenBoolean = false;
    this.setState({ disableSearchBtn: true, loading: true });
    api
      .getNewRangePlannerItems(
        this.props.settings.baseApiUrl,
        this.props.router.params.projectId,
        this.state.searchValues.style,
        this.state.searchValues.colour.trim()
      )
      .then((data) => {
        if (
          data.rangePlannerItems !== null &&
          data.rangePlannerItems.length !== 0
        ) {
          var tableItems = result.rangePlannerItems;
          var resultItems = data.rangePlannerItems;
          resultItems.forEach(function (newItem) {
            if (tableItems.has(newItem.rangeId)) {
              bannerObject.type = "info";
              bannerObject.message = "Style / Colour already added to table";
              bannerOpenBoolean = true;
            }
            tableItems.set(newItem.rangeId, newItem);
            return;
          });
          bannerObject.bannerOpen = bannerOpenBoolean;
          this.setState({ result, bannerObject, loading: false });
        } else {
          result.error = data.error;
          bannerObject.type = "info";
          bannerObject.message = data.error;
          bannerObject.bannerOpen = true;
          this.setState({ result, bannerObject, loading: false });
        }
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  };

  handleStyleChange = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    const char = newValue.substr(newValue.length - 1, newValue.length);
    if (newValue.length <= 9) {
      if (newValue.length === 0) {
        this.setState({ styleNotValid: true });
      }
      if (!this.styleRegex.test(newValue)) {
        if (
          this.exceptedFilterRegex.test(char) ||
          !this.numbersAllowedRegex.test(char)
        ) {
          if (char === "") {
            this.setState((prevState) => {
              let searchValues = Object.assign({}, prevState.searchValues);
              searchValues.style = char;
              return { searchValues };
            });
          }
          return;
        } else if (this.numbersAllowedRegex.test(char)) {
          this.setState((prevState) => {
            let searchValues = Object.assign({}, prevState.searchValues);
            searchValues.style = newValue;
            return { searchValues };
          });
        }
      }
      if (this.styleRegex.test(newValue)) {
        this.setState({ styleNotValid: false });
        this.setState((prevState) => {
          let searchValues = Object.assign({}, prevState.searchValues);
          searchValues.style = newValue;
          return { searchValues };
        });
        return;
      }
      this.setState({ styleNotValid: true });
      return;
    }
    this.setState({ styleNotValid: false });
  };

  handleColourChange = (e) => {
    e.preventDefault();
    const newValue = e.target.value;
    const char = newValue.substr(newValue.length - 1, newValue.length);
    if (char === "") {
      this.setState((prevState) => {
        let searchValues = Object.assign({}, prevState.searchValues);
        searchValues.colour = char;
        return { searchValues, colourNotValid: true };
      });
    }
    if (this.colourRegex.test(newValue)) {
      this.setState((prevState) => {
        let searchValues = Object.assign({}, prevState.searchValues);
        searchValues.colour = newValue;
        return { searchValues, colourNotValid: false };
      });
    }
  };

  handleBackButtonClick(e) {
    e.preventDefault();
    this.navigate(`/projects`);
  }

  removeAllStylesFromState = (e) => {
    e.preventDefault();
    const { result } = this.state;
    result.rangePlannerItems.clear();
    this.setState({ result, modalIsOpen: false, disableSearchBtn: false });
  };

  removeStyleFromState = (e, rangeId) => {
    e.preventDefault();
    const { result } = this.state;
    result.rangePlannerItems.delete(rangeId);
    this.setState({ result });
  };

  clearSearchInput = (e, inputName) => {
    const { searchValues } = this.state;
    e.preventDefault();
    if (inputName === "style") {
      searchValues.style = "";
      this.setState({ searchValues, styleNotValid: true });
    } else {
      searchValues.colour = "";
      this.setState({ searchValues, colourNotValid: true });
    }
  };

  handleEditedGradeChange = (e, rangeId) => {
    e.preventDefault();
    const { result } = this.state;
    const newValue = e.target.value;
    const nextVal = newValue.substr(newValue.length - 1, newValue.length);
    var styleColourComb = result.rangePlannerItems.get(rangeId);
    if (
      (this.numbersAllowedRegex.test(nextVal) || nextVal === "") &&
      newValue.length <= 3
    ) {
      styleColourComb.editedGrade = newValue;
    }
    this.setState({ result });
  };

  renderSearchFields = () => {
    return (
      <div className="manage-range-form-group">
        <React.Fragment>
          <div>
            <TextInputField
              name="style"
              className="style-input"
              label="Style"
              placeholder="Style Number"
              onChange={this.handleStyleChange}
              value={this.state.searchValues.style}
            />
            <TextButton
              color="dark"
              className={"clear-search-input"}
              tabIndex={"0"}
              onClick={(e) => this.clearSearchInput(e, "style")}
            >
              Clear
            </TextButton>
          </div>
          <div>
            <TextInputField
              name="colour"
              className="colour-input"
              label="Colour"
              placeholder="Colour"
              onChange={this.handleColourChange}
              value={this.state.searchValues.colour}
            />
            <TextButton
              color="dark"
              className={"clear-search-input"}
              tabIndex={"0"}
              onClick={(e) => this.clearSearchInput(e, "colour")}
            >
              Clear
            </TextButton>
          </div>
          <FilledButton
            className="searchButton"
            disabled={this.disableSearchButton()}
            onClick={(e) => this.handleSearch(e)}
          >
            Search
          </FilledButton>
        </React.Fragment>

        {this.state.disableSearchBtn && this.state.result.message && (
          <div className="error-circle-container">
            <ErrorCircle className={"error-circle"} />{" "}
            <p className={"error-circle-description"}>
              {" "}
              The maximum number of Gondolas has been reached
            </p>
          </div>
        )}
      </div>
    );
  };

  columnformatting = (columnName) => {
    return columnName.charAt(0).toLowerCase() + columnName.slice(1);
  };

  createEmptyField = () => {
    return (
      <TextInputField
        name="grade"
        className="grade-input"
        placeholder="Grade"
      />
    );
  };

  renderTableBody = () => {
    let body = [];
    const rangePlannerItems = new Map(
      [...this.state.result.rangePlannerItems].reverse()
    );
    rangePlannerItems.forEach((x) =>
      body.push(<TableRow key={x.rangeId}>{this.renderRow(x)}</TableRow>)
    );
    return body;
  };

  renderRow = (row) => {
    let rowCells = [];
    this.columns.forEach((colName) => {
      let col = this.columnformatting(colName);
      if (col === "editedGrade") {
        rowCells.push(
          <TextInputField
            name="editedGrade"
            className={"manage-table-row"}
            placeholder="Enter Grade Number"
            hideLabel
            onChange={(e) => this.handleEditedGradeChange(e, row["rangeId"])}
            value={
              this.state.result.rangePlannerItems.get(row["rangeId"])
                .editedGrade
            }
          />
        );
      } else {
        rowCells.push(
          <TableCell
            className={"manage-table-row"}
            key={row[col] + "," + row[col]}
          >
            {row[this.columnformatting(col)]}
          </TableCell>
        );
      }
    });
    rowCells.push(
      <TableCell key={row["rangeId"] + ",removeButton"}>
        <TextButton
          color="dark"
          tabIndex={"0"}
          className={"remove-link"}
          onClick={(e) => this.removeStyleFromState(e, row["rangeId"])}
        >
          Remove
        </TextButton>
      </TableCell>
    );
    return rowCells;
  };

  renderTableResults = () => {
    return (
      <React.Fragment>
        <TableContainer responsive>
          <TableHeader className={"manage-table-header"}>
            <TableHeaderRow>
              {this.columns.map((x) => (
                <TableHeaderCell key={x}>{x}</TableHeaderCell>
              ))}
            </TableHeaderRow>
          </TableHeader>
          <TableBody>{this.renderTableBody()}</TableBody>
        </TableContainer>
        <ButtonGroupWrapper className={"table-buttonWrapper"}>
          <ButtonGroupPrimary>
            <OutlinedButton
              className={"clearAllButton"}
              onClick={(e) => this.handleModalOpenClose(e, "Clear Rows", true)}
            >
              Clear rows
            </OutlinedButton>
            <Button
              variant="filled"
              className={"submitButton"}
              onClick={(e) => this.handleModalOpenClose(e, "Add Rows", true)}
              disabled={this.checkEditedGradeFields()}
            >
              Add rows
            </Button>
          </ButtonGroupPrimary>
          <ButtonGroupSecondary>
            <OutlinedButton
              color="dark"
              className={"backButton"}
              onClick={(e) => this.handleModalOpenClose(e, "Back", true)}
            >
              Back
            </OutlinedButton>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
      </React.Fragment>
    );
  };

  renderEmptyPageMessage = () => {
    return (
      <Card className="empty-project-body">
        <div className="empty-project-body-content">
          <div className="empty-project-body-content-text">
            <h2>You have not added any styles</h2>
            <p className="empty-project-body-content-paragraph">
              Welcome to manage project for {this.state.project.name || ""}.
            </p>
            <p className="ln-u-margin-bottom*4">
              Let's start by searching for a new style/colour.
            </p>
          </div>
        </div>
      </Card>
    );
  };

  handleCloseBanner = (e) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ bannerObject: { bannerOpen: false } });
  };

  handleModalOpenClose = (e, type, isOpen) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({ modalIsOpen: isOpen, modalType: type });
  };

  checkEditedGradeFields = () => {
    const { result } = this.state;
    for (var element of result.rangePlannerItems) {
      if (element[1].editedGrade === null || element[1].editedGrade === "") {
        return true;
      }
    }
    return false;
  };

  downloadPickList = (projectItem) => {
    let element = document.createElement("a");
    const { downloadPlanStatus } = this.state;
    downloadPlanStatus.exportRangePlan = true;
    downloadPlanStatus.rangePlanName = projectItem.name;
    this.setState({ downloadPlanStatus });
    api
      .getProjectPickList(this.props.settings.baseApiUrl, projectItem.id)
      .then((response) => {
        const fileDate = moment().format("DD-MM-YYYY [at] HH-mm");
        element.setAttribute(
          "download",
          `${fileDownloadFunctions.getFileName(
            response.headers
          )} ${fileDate}.csv`
        );
        return response.blob();
      })
      .then((blob) => {
        fileDownloadFunctions.setupAndClickLink(element, blob);
      })
      .then(() => {
        downloadPlanStatus.exportRangePlan = false;
        this.setState({ openOverflowMenuId: -1, downloadPlanStatus });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  };

  setModalType = (name) => {
    this.setState({ modalType: name });
  };

  renderConfirmationModal = (modalType) => {
    switch (modalType) {
      case "Add Rows": {
        return (
          <ManageProjectModal
            isOpen={this.state.modalIsOpen}
            modalHeading={`Are you sure you want to add the following styles to the project '${this.state.project.name}'?`}
            description={"You will not be able to undo this action."}
            yesCallback={(e) => this.handleSubmit(e)}
            noCallback={(e) => this.handleModalOpenClose(e, "Add Rows", false)}
          />
        );
      }
      case "Clear Rows": {
        return (
          <ManageProjectModal
            isOpen={this.state.modalIsOpen}
            modalHeading={`Are you sure you want to clear the following styles from the table?`}
            description={"You will not be able to undo this action."}
            yesCallback={(e) => this.removeAllStylesFromState(e)}
            noCallback={(e) =>
              this.handleModalOpenClose(e, "Clear Rows", false)
            }
          />
        );
      }
      case "Back": {
        return (
          <ManageProjectModal
            isOpen={this.state.modalIsOpen}
            modalHeading={`Are you sure you want to leave this page?`}
            description={"You will lose all styles not added to project."}
            yesCallback={(e) => this.handleBackButtonClick(e)}
            noCallback={(e) => this.handleModalOpenClose(e, "Back", false)}
          />
        );
      }
      default: {
        return;
      }
    }
  };

  disableSearchButton = () => {
    const { styleNotValid, colourNotValid } = this.state;
    if (styleNotValid || colourNotValid) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { downloadPlanStatus } = this.state;
    return (
      <div className="manage-project-content-container">
        <Container size="lg" soft>
          {this.state.loading === true && <LoadingIndicator />}
          {downloadPlanStatus.exportRangePlan && (
            <ProgressIndicator
              className="ln-u-justify-content-center"
              page
              loading
              preventFocus
            >
              <ProgressSpinner className="ln-u-push-right-sm" color="light" />
              <div className="export-image-text">{`Exporting ${downloadPlanStatus.rangePlanName}`}</div>
            </ProgressIndicator>
          )}
          {this.state.loading === false && (
            <React.Fragment>
              <FilledButton
                className={"downloadRangePlanButton"}
                onClick={() => this.downloadPickList(this.state.project)}
              >
                Download picklist
              </FilledButton>
              <Breadcrumbs
                items={breadcrumbHelper.createEmptyGradeOne(
                  this.state.project.name || ""
                )}
              />
              {this.state.bannerObject.bannerOpen && (
                <div className={"manage-project-banner"}>
                  <Banner
                    type={this.state.bannerObject.type}
                    message={this.state.bannerObject.message}
                    onhandleClose={this.handleCloseBanner}
                  />
                </div>
              )}
              <h2 className="manage-project-title">
                Manage {this.state.project.name}
              </h2>
              {this.state.modalIsOpen === true &&
                this.renderConfirmationModal(this.state.modalType)}
              {this.renderSearchFields()}
              {this.state.result.rangePlannerItems.size < 1
                ? this.renderEmptyPageMessage()
                : this.renderTableResults()}
            </React.Fragment>
          )}
        </Container>
      </div>
    );
  }
}

ManageProject.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(ManageProject);
