import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { api } from "../../../api/api";
import Constants from "../../../utils/constants";
import { withRouter } from "../../../utils/reactRouterDom";
import AddGondolaModal from "../../BayBuilderComponents/AddGondolaModal";
import BayBuilder from "../../BayBuilderComponents/BayBuilder";
import ImageLibrary from "../../ImageLibraryComponents/ImageLibrary/ImageLibrary";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";

class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: -1,
      gradeNumber: -1,
      loading: true,
      rangeId: -1,
      projectName: "",
      rangeName: "",
      rangeSize: 0,
      openAddGondolaModal: false,
      gradeRanges: [],
      activeUsedImageCounts: {},
    };
    this.imageSelected = this.imageSelected.bind(this);
    this.renderContent = this.renderContent.bind(this);
    this.handleAddGondolaModalOpen = this.handleAddGondolaModalOpen.bind(this);
    this.handleAddGondolaModalClose =
      this.handleAddGondolaModalClose.bind(this);
    this.handleAddGondolaSaveButton =
      this.handleAddGondolaSaveButton.bind(this);
    this.handleUsedImageCountsUpdated =
      this.handleUsedImageCountsUpdated.bind(this);
    this.addUsedImageDataToRangePlanner =
      this.addUsedImageDataToRangePlanner.bind(this);
    this.handleItemAdded = this.handleItemAdded.bind(this);
    this.handleItemsRemoved = this.handleItemsRemoved.bind(this);
    this.handleAddBarButtonClick = this.handleAddBarButtonClick.bind(this);
  }

  componentDidMount() {
    const projectId = this.props.router.params.projectId
      ? parseInt(this.props.router.params.projectId, 0)
      : 0;
    const gradeNumber = this.props.router.params.gradeNumber
      ? parseInt(this.props.router.params.gradeNumber, 0)
      : 0;
    const rangeId = this.props.router.params.rangeId
      ? parseInt(this.props.router.params.rangeId, 0)
      : 0;

    const projectName =
      this.props.location && this.props.location.state
        ? this.props.location.state.projectName
        : null;
    const rangeName =
      this.props.location && this.props.location.state
        ? this.props.location.state.rangeName
        : null;

    this.setState({ rangeId, projectId, gradeNumber, projectName, rangeName });

    // Add a class to make the background white
    document.body.classList.add("bay-display");
    // Add a class to remove the vertical scroll bar
    document.body.classList.add("bay-display--build-mode");

    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.clothing,
      optionalMenuItems: [Constants.MenuOptions.clothing],
    });

    // Load the items for this project, and setup the image library
    api
      .getImageLibraryForProject(this.props.settings.baseApiUrl, projectId)
      .then((data) => {
        // Load the range details for this grade
        api
          .getProjectGradeRanges(
            this.props.settings.baseApiUrl,
            projectId,
            gradeNumber,
            false
          )
          .then((gradeRanges) => {
            const usedImageCountObject = EditProject.createUsedImageCountObject(
              gradeRanges.usedImageCounts
            );
            this.addUsedImageDataToRangePlanner(data, usedImageCountObject);
            this.props.setRangePlannerData(data);
            this.props.setGradeFilter(this.props.selectedGrade);
            this.setState({
              gradeRanges,
              activeUsedImageCounts: usedImageCountObject,
              loading: false,
            });
          })
          .catch((e) => {
            api.handleError(this.props.router.location, e.message);
          });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  componentWillUnmount() {
    // Remove the class that made the background white
    document.body.classList.remove("bay-display");
    // Remove the class that removed the vertical scroll bar
    document.body.classList.remove("bay-display--build-mode");
  }

  static createUsedImageCountObject(usedImageCounts) {
    const usedImageCountObject = {};
    for (let i = 0; i < usedImageCounts.length; i += 1) {
      usedImageCountObject[usedImageCounts[i].rangeId] =
        usedImageCounts[i].count;
    }
    return usedImageCountObject;
  }

  addUsedImageDataToRangePlanner(rangePlannerData, usedImageCountObject) {
    for (let i = 0; i < rangePlannerData.length; i += 1) {
      rangePlannerData[i][
        Constants.RangePlannerItemProperties.savedUsedImageCount
      ] = usedImageCountObject[rangePlannerData[i].rangeId] || 0;
      rangePlannerData[i][
        Constants.RangePlannerItemProperties.activeUsedImageCount
      ] = usedImageCountObject[rangePlannerData[i].rangeId] || 0;
    }
  }

  handleUsedImageCountsUpdated(usedImageCounts) {
    const { rangePlannerData } = this.props;
    const usedImageCountObject =
      EditProject.createUsedImageCountObject(usedImageCounts);
    this.addUsedImageDataToRangePlanner(rangePlannerData, usedImageCountObject);
    this.props.updateRangePlannerData(rangePlannerData);
    this.setState({ activeUsedImageCounts: usedImageCountObject });
  }

  handleItemAdded(rangeId) {
    if (rangeId == null) {
      return;
    }

    let { activeUsedImageCounts } = this.state;
    const newActiveUsedImageCounts = Object.assign({}, activeUsedImageCounts);

    if (newActiveUsedImageCounts[rangeId] != null) {
      newActiveUsedImageCounts[rangeId] = activeUsedImageCounts[rangeId] + 1;
    } else {
      newActiveUsedImageCounts[rangeId] = 1;
    }

    this.setState({ activeUsedImageCounts: newActiveUsedImageCounts });
  }

  handleItemsRemoved(removedItemsRangeIds) {
    if (removedItemsRangeIds == null || removedItemsRangeIds.length === 0) {
      return;
    }

    let { activeUsedImageCounts } = this.state;
    const newActiveUsedImageCounts = Object.assign({}, activeUsedImageCounts);
    let countsUpdated = false;

    for (let i = 0; i < removedItemsRangeIds.length; i += 1) {
      const rangeId = removedItemsRangeIds[i];
      if (
        newActiveUsedImageCounts[rangeId] != null &&
        newActiveUsedImageCounts[rangeId] > 0
      ) {
        newActiveUsedImageCounts[rangeId] -= 1;
        countsUpdated = true;
      }
    }

    if (countsUpdated === true) {
      this.setState({ activeUsedImageCounts: newActiveUsedImageCounts });
    }
  }

  imageSelected(e, rangeId, selectedItemPhoto, rangeItemPhotos) {
    if (e) {
      e.preventDefault();
    }
    this.bayBuilder.addItem(rangeId, selectedItemPhoto, rangeItemPhotos);
  }

  renderContent() {
    return (
      <React.Fragment>
        <AddGondolaModal
          isOpen={this.state.openAddGondolaModal}
          onModalClose={this.handleAddGondolaModalClose}
          rangeSize={this.state.rangeSize}
          onHandleAddGondolaSaveButton={this.handleAddGondolaSaveButton}
          rangeId={this.state.rangeId}
        />
      </React.Fragment>
    );
  }

  handleAddGondolaModalOpen(e, rangeSize) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openAddGondolaModal: true, rangeSize: rangeSize });
  }

  handleAddGondolaModalClose(e, rangeSize) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openAddGondolaModal: false, rangeSize: rangeSize });
  }

  handleAddGondolaSaveButton(rangeItems) {
    let success = false;
    if (this.bayBuilder != null) {
      success = this.bayBuilder.addNewGondolas(rangeItems);
    }
    return success;
  }

  handleAddBarButtonClick() {
    this.bayBuilder.addBarButtonClick();
  }

  render() {
    return (
      <div>
        {this.state.loading === true && <LoadingIndicator />}
        {this.state.loading === false && (
          <React.Fragment>
            <ImageLibrary
              imageSelected={this.imageSelected}
              activeUsedImageCounts={this.state.activeUsedImageCounts}
              onhandleAddBarButtonClick={this.handleAddBarButtonClick}
            />
            <div
              className={
                this.props.isFilterPanelOpen === true
                  ? "bay-builder-position bay-builder-position-right"
                  : "bay-builder-position bay-builder-position-default"
              }
            >
              <BayBuilder
                projectName={this.state.projectName}
                rangeName={this.state.rangeName}
                ref={(bayBuilder) => {
                  this.bayBuilder = bayBuilder;
                }}
                narrow={this.props.isFilterPanelOpen}
                projectId={this.state.projectId}
                gradeNumber={this.state.gradeNumber}
                rangeId={this.state.rangeId}
                settings={this.props.settings}
                router={this.props.router}
                gradeRanges={this.state.gradeRanges}
                onHandleAddGondolaModalOpen={this.handleAddGondolaModalOpen}
                onUsedImageCountsUpdated={this.handleUsedImageCountsUpdated}
                onItemAdded={this.handleItemAdded}
                onItemsRemoved={this.handleItemsRemoved}
                rangePlannerData={this.props.rangePlannerData}
              />
            </div>
          </React.Fragment>
        )}
        {this.renderContent()}
      </div>
    );
  }
}

EditProject.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
      rangeId: PropTypes.string,
    }).isRequired,
  }).isRequired,
  settings: PropTypes.shape({
    baseApiUrl: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      rangeName: PropTypes.string,
    }),
  }),
  setMenu: PropTypes.func.isRequired,
  setRangePlannerData: PropTypes.func.isRequired,
  isFilterPanelOpen: PropTypes.bool.isRequired,
  selectedGrade: PropTypes.number.isRequired,
};

export default withRouter(EditProject);
