import React, { Component } from 'react';
import { PropTypes } from 'prop-types'
import { Card, Button, IconButton, TextButton } from '@jsluna/react'
import moment from 'moment';
import { OverflowMenu } from '@jsluna/icons'
class ProjectCard extends Component{
    constructor(props) {
        super(props)
        this.state = {
          loadingProjects: true,
          projects: [],
        }
        this.handleRowClick = this.handleRowClick.bind(this)
      }

    handleRowClick(e) {
        e.preventDefault()
        this.props.onSelect(this.props.projectItem.id)
      }

    render(){
      let localDate;
      let localTime;
      const rawDate = this.props.projectItem.createdAt;

      if( rawDate && rawDate !== "")
      {
        let utcTimeDate =  moment.utc(this.props.projectItem.createdAt);
        localDate = utcTimeDate.local().format("DD MMM YY");
        localTime = utcTimeDate.local().format("HH:mm");
      }

      let cardClass= this.props.overflowMenuOpen ? "project-card-selected" : "project-card";

      return(                 
        <Card className="project-container">
          <div className={cardClass}>
            <div>
              <h4 className="ln-u-margin-bottom*2">{this.props.projectItem.name}</h4>                  
            </div>
            <div className="project-card-body">
              <p className="project-card-project-info">
                {this.props.projectItem.season}
                <span className="project-card-project-info--bullet">&bull;</span>
                {this.props.projectItem.gradeCount} Grade{this.props.projectItem.gradeCount !== 1 ? 's' : ''}
                <span className="project-card-project-info--bullet">&bull;</span>
                {this.props.projectItem.itemCount} item{this.props.projectItem.itemCount !== 1 ? 's' : ''}
              </p>
              <Card className="project-card-button-container">
                <Button variant="filled" className="project-card-open-button" onClick={this.handleRowClick}>
                  Open
                </Button>
                <IconButton className="project-card-overflowmenu-button" label="" color="dark" variant="outlined" onClick={this.props.onOpenOverflow} >
                  <OverflowMenu  >
                  </OverflowMenu>
                </IconButton>
              </Card>
              {this.props.overflowMenuOpen &&
                        <Card className="project-overflowmenu-container">
                          <TextButton className="project-overflowmenu-item" color="dark" onClick={() => this.props.onManageProject(this.props.projectItem)} >Manage project</TextButton>
                          <TextButton className="project-overflowmenu-item" color="dark" onClick={() => this.props.onStartClone(this.props.projectItem)} >Clone project</TextButton>
                          <TextButton className="project-overflowmenu-item" color="dark" onClick={() => this.props.onRefreshImages(this.props.projectItem)}>Update and refresh images</TextButton>
                          <TextButton className="project-overflowmenu-item" color="dark" onClick={() => this.props.onDeleteProject(this.props.projectItem)} >Delete project</TextButton>
                        </Card>}
              <div>
                <p className="project-card-user-info">
                  Created by <b>{this.props.projectItem.createdBy || 'VM User'}</b> <span className="project-card-user-info--date">on <b>{localDate}</b> at <b>{localTime}</b></span>
                </p>
              </div>
            </div>               
          </div>
        </Card>
    )}
}

ProjectCard.propTypes ={
  projectItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    season: PropTypes.string.isRequired,
    gradeCount: PropTypes.number.isRequired,
    itemCount: PropTypes.number.isRequired,
    CreatedAt: PropTypes.string,
    CreatedBy: PropTypes.string,
  }),
  overflowMenuOpen: PropTypes.bool.isRequired,
  onManageProject: PropTypes.func.isRequired,
  onStartClone: PropTypes.func.isRequired,
  onRefreshImages: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired
}

export default ProjectCard;
