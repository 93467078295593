import React, { Component } from 'react';
import {connect} from 'react-redux';
import { ButtonGroupWrapper, OutlinedButton, TextButton } from '@jsluna/react';
import CollectionFilter from '../CollectionFilter/CollectionFilter';
import ClassFilter from '../ClassFilter/ClassFilter';
import ColourFilter from '../ColourFilter/ColourFilter';
import DepartmentFilter from '../DepartmentFilter/DepartmentFilter';
import GradeFilter from '../GradeFilter/GradeFilter'
import FilteringPanel from '../FilteringPanel/FilteringPanel';
import StyleFilter from '../StyleFilter/StyleFilter';
import UsedImageFilter from '../UsedImageFilter/UsedImageFilter'
import MaxImages from '../MaxImages'
import {toggleFilterPanel} from '../../../store/imagelibraryfilters/actions'
import {
    setStyleFilter,
    setCurrentStyleInputValue,
    setDepartmentFilter, 
    setCollectionFilter, 
    setGradeFilter, 
    setClassFilter,
    setColourFilter,
    setUsedImageFilter,
    disableDropdowns} from '../../../store/imagelibraryfilters/actions'

class ImageLibraryFilters extends Component {
  constructor(props){
    super(props);
    this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
}

  toggleFilterPanel() {
    this.props.dispatch(toggleFilterPanel());
  }

  getClassName() {
    if (!this.props.isOpen) {
      return "ImageLibraryFiltersButtonClosed"
    }
    else {
      return 'ImageLibraryFiltersButtonOpen'
    }
  }

  resetFilters() {
    this.props.dispatch(disableDropdowns(false));
    this.props.dispatch(setStyleFilter(""));
    this.props.dispatch(setCurrentStyleInputValue(""));
    this.props.dispatch(setDepartmentFilter(""));
    this.props.dispatch(setCollectionFilter(""));
    this.props.dispatch(setGradeFilter(this.props.selectedGrade));
    this.props.dispatch(setClassFilter(""));
    this.props.dispatch(setColourFilter(""));
    this.props.dispatch(setUsedImageFilter(""));
  }

    render() {
      return (
        <div className="ImageLibraryFilters">
          <FilteringPanel
            isOpen={this.props.isOpen}
            title='Filter clothing by:' 
            defaultOpen="true" 
            id="ImageLibraryFilteringPanel">
              <StyleFilter />
              <CollectionFilter />
              <DepartmentFilter />
              <ClassFilter />
              <ColourFilter />
              <GradeFilter />
              <UsedImageFilter />
              <MaxImages />
              </FilteringPanel>  
              <div className={this.getClassName()} id="FilteringButtonWrapper">
                <ButtonGroupWrapper>
                    <OutlinedButton color="dark" className="FilterButtonClose" onClick={this.toggleFilterPanel}>
                            Close
                    </OutlinedButton>
                    <TextButton color="dark" className="FilterButtonReset" onClick={this.resetFilters}>
                            Reset filters
                    </TextButton>
                </ButtonGroupWrapper>
              </div>     
        </div>
      );
    }
  }
  
  export default connect((state,props) => {
    return {
        isOpen: state.imageLibraryData.isFilterPanelOpen,
        selectedGrade: state.selectedGrade
    }})(ImageLibraryFilters);