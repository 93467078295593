import { connect } from 'react-redux'
import ViewProject from './ViewProject'
import { setMenu } from '../../../store/menu/actions'
import { setSelectedGrade } from '../../../store/selectedGrade/actions'

const mapStateToProps = state => ({
  settings: state.settings,
  selectedGrade: state.selectedGrade
})

const mapDispatchToProps = dispatch => ({
  setMenu: menu => {
    dispatch(setMenu(menu))
  },
  setSelectedGrade: selectedGrade => {
    dispatch(setSelectedGrade(selectedGrade))
  }
  
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewProject)