import React, { Component } from 'react';
import notFound from '../../../assets/images/NotFound.png'
import { api } from '../../../api/api';

export class ImageCard extends Component{
  constructor(props) {
    super(props);
    let imgUri = this.props.rangePlannerItem.itemPhotos[0].resizedUri;
    let errored = false
    if (!imgUri)
    {
      imgUri = notFound
      errored = true  
    }

    this.state = {
      errored: errored,
      src: imgUri,
      rangeId: this.props.rangePlannerItem.rangeId,
      imageId: this.props.rangePlannerItem.itemPhotos[0].imageId,
      orientation: this.props.rangePlannerItem.itemPhotos[0].orientation,
      b64Image: notFound    
    }

    this.onError = this.onError.bind(this);

    if (errored === false) {
      api.getImageFromBlob(imgUri).then(
        (imgContent) => {
          this.setState({ b64Image:imgContent, errored: false }); 
        }
      ).catch(e => {
        this.setState({ src:notFound, errored: true }); 
      })
    }
  }

  onError() {
    this.setState(
      {
        src: notFound,
        imageId: -1,
        errored : true
      }
    );
  }

  renderImageTag(savedUsedImageCount, activeUsedImageCount) {
    if (savedUsedImageCount == null) {
      return null
    }

    const activeUsedImageCountValue = activeUsedImageCount || 0
    if (savedUsedImageCount === 0 && activeUsedImageCountValue > 0) {
      return (
        <div className="imglib-status-container imglib-status-container--added">
          <div className="imglib-status-value">Added</div>
        </div> 
      )
    } else if (savedUsedImageCount > 0 && activeUsedImageCountValue === 0) {
      return (
        <div className="imglib-status-container imglib-status-container--removed">
          <div className="imglib-status-value">Removed</div>
        </div> 
      )
    } else if (savedUsedImageCount > 0) {
      return (
        <div className="imglib-status-container">
          <div className="imglib-status-value">Used</div>
        </div> 
      )
    }
    
    return null
  }

  static getStatusDisplayText(statusCode) {
    let statusText = null
    if (statusCode === 'A') {
      statusText = 'Approved'
    } else if (statusCode === 'T') {
      statusText = 'Temporary'
    }
    return statusText
  }

  render() {
    const { rangePlannerItem, activeUsedImageCount } = this.props
    const { errored } = this.state
    let statusText = ImageCard.getStatusDisplayText(rangePlannerItem.itemPhotos[0].status)
    let styleOrRangeId =  rangePlannerItem.rangeId;
    let rangeId =  rangePlannerItem.rangeId;
    const style = rangePlannerItem.style;
    let styleRangeLabel = "Range ID";

    if( style && style !== "") {
        styleOrRangeId = style;
        styleRangeLabel = "Style"
    }

    return(                 
      <span className="">
        <div className={errored === true ? "imglib-img-wrapper imglib-img-wrapper--not-found" : "imglib-img-wrapper"}
          onClick={
              errored === true ? null : (e) => this.props.imageSelected(e, rangeId, rangePlannerItem.itemPhotos[0], rangePlannerItem.itemPhotos)
          }
        >
          <div className="imglib-img-container">
            <img className="image-library-item" 
                src={this.state.b64Image ? this.state.b64Image : this.state.src}
                alt={rangePlannerItem.RangePlannerItemId} 
                id={rangePlannerItem.rangePlannerItemId}
                onError={this.onError}
            />
          </div>
          {
            this.renderImageTag(rangePlannerItem.savedUsedImageCount, activeUsedImageCount) 
          }
        </div>
        <div className="imglib-description-container">
          <span >{rangePlannerItem.description} </span>
        </div>
        <div className="imglib-details-container">
          <span className="imglib-details-label">Grade</span><span className="imglib-details-value">{this.props.rangePlannerItem.grade} </span>
        </div>
        <div className="imglib-details-container">
          <span id="styleRangeLabel" className="imglib-details-label">{styleRangeLabel}</span><span className="imglib-details-value">{styleOrRangeId}</span>
        </div>
        <div className="imglib-details-container">
            <span className="imglib-details-label">Colour</span><span className="imglib-details-value">{this.props.rangePlannerItem.colour}</span>
        </div>
        { statusText !== null &&
          <div className="imglib-details-container">
            <span className="imglib-details-label">Status</span><span className="imglib-details-value">{statusText}</span>
          </div>
        }
      </span>
    )
  }
}

export default ImageCard;