function getActiveFilters(allFilters){
    let filters = [
        {filterName:"styleFilter", filterDisplayName:"Style:"},
        {filterName:"collectionFilter",filterDisplayName:"Collection:"},
        {filterName:"departmentFilter",filterDisplayName:"Department:"},
        {filterName:"gradeFilter", filterDisplayName:"Grade:"},
        {filterName:"classFilter", filterDisplayName:"Class:"},
        {filterName:"colourFilter", filterDisplayName:"Colour:"}]
  
      let appliedFilters = [];
  
      for(let i=0; i<filters.length; i++){
        if(allFilters[filters[i].filterName]!== "")
          appliedFilters.push({
            filterName: filters[i].filterName
          })
      }
  
      return appliedFilters;
}

export {getActiveFilters}