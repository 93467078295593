import React, { Component } from 'react';
import { SelectField } from '@jsluna/react';
import { connect } from 'react-redux';
import { setGradeFilter } from '../../../store/imagelibraryfilters/actions';

class GradeFilter extends Component {
  constructor(props){
    super(props);
    this.changeGradeFilter = this.changeGradeFilter.bind(this);
  }

  changeGradeFilter(e){
    e.preventDefault();
    const newValue = e.target.value;
    this.props.dispatch(setGradeFilter(newValue));
  }

    render() {
      return (
        <SelectField 
          name="select-grades" 
          label="Grade"
          placeholder={" "} 
          disabled={this.props.disableDropdowns}
          options={this.props.options}
          value={this.props.selectedValue}
          onChange={(e) => this.changeGradeFilter(e)}/>           
      );
    }
  }
  
  export default connect((state,props) => {
    return {
      options: state.imageLibraryData.dropdownValues.grades || [],
      selectedValue: state.imageLibraryData.gradeFilter.toString() || "",
      disableDropdowns: state.imageLibraryData.disableDropdowns
    }}) (GradeFilter);