import * as PIXI from 'pixi.js'
import Gondola from './Gondola'
import Utils from './Utils'

export default class DraggableSprite extends PIXI.Sprite {

  deleteButtonMargin = 10
  dragAlpha = 0.5
  dashLength = 5

  selectFrameProperties = {
    lineWidth: 1.5,
    colour: 0xF06C00,
    alpha: 1
  }
  highlightProperties = {
    lineWidth: 1,
    colour: 0x0C0C0C,
    alpha: 1
  }

  imageAnchor = {
    x: 0.5,
    y: 0.5
  }

  constructor(position) {
    super()
    this.anchor = this.imageAnchor
    this.x = position.x
    this.y = position.y
    this.pointerup = this._onDragEnd
    this.pointerupoutside = this._onDragEnd
    this.pointermove = this._onDragMove
    this.pointerdown = (e) => this._onDragStart(e)
    this.interactive = true;
    this.pointerover = this._addItemHighlight
    this.pointerout = this._removeItemHighlight
  }

  _addItemHighlight(e) {
    if (this.selected === true) {
      return
    }

    if (!this.pointerOverGraphics) {
      this.pointerOverGraphics = this._createItemHighlight()
    }

    if (!this.dragging) {
      this.addChild(this.pointerOverGraphics)
    }
  }

  _createItemHighlight() {
    const itemWidth = this.width
    const itemHeight = this.height
    const itemX = (itemWidth * this.anchor.x) * -1
    const itemY = (itemHeight * this.anchor.y) * -1
    const g = new PIXI.Graphics()
    g.clear()
    g.lineStyle(this.highlightProperties.lineWidth, this.highlightProperties.colour, this.highlightProperties.alpha)
    Utils.createDashedLine(g, itemX, itemX + itemWidth, this.dashLength, itemY, true)
    Utils.createDashedLine(g, itemY, itemY + itemHeight, this.dashLength, itemX + itemWidth, false)
    Utils.createDashedLine(g, itemX, itemX + itemWidth, this.dashLength, itemY + itemHeight, true)
    Utils.createDashedLine(g, itemY, itemY + itemHeight, this.dashLength, itemX, false)
    return g
  }

  _attachToRoot() {
    // attach the sprite to the root to allow it to moveover multiple gondola containers.
    this.oldPosition = this.position
    let parentPosition = this.parent.position //gondola origin (x should always be 0 )

    let globalPosition = { x: this.oldPosition.x + parentPosition.x, y: (this.parent.type === "Bar" ? this.parent.parent.y : 0) + this.oldPosition.y + parentPosition.y }
    this.position = globalPosition
    this.rangeBuilder.addChild(this)
  }

  _removeItemHighlight() {
    this.removeChild(this.pointerOverGraphics)
  }

  _selectItem() {
    // Deselect all selected items
    this.parent.deselectItems()

    // Hide any highlighting
    this._removeItemHighlight()

    // Add the select frame
    this.addItemSelectFrame()
  }

  drawSelectedGraphics() {
    const itemWidth = this.width
    const itemHeight = this.height
    const itemX = (itemWidth * this.anchor.x) * -1
    const itemY = (itemHeight * this.anchor.y) * -1
    const selectFrame = new PIXI.Graphics()
    selectFrame.visibleForMerge = true
    selectFrame.clear()
    selectFrame.lineStyle(this.selectFrameProperties.lineWidth, this.selectFrameProperties.colour, this.selectFrameProperties.alpha)
    selectFrame.drawRect(itemX, itemY, itemWidth, itemHeight)
    selectFrame.endFill()
    return selectFrame
  }

  redrawFrames() {
    this.removeChild(this.selectFrame)
    this.selectFrame = this.drawSelectedGraphics()
    this.removeChild(this.pointerOverGraphics)
    this.pointerOverGraphics = null

    if (this.selected === true) {
      this.addChild(this.selectFrame)
    }
  }

  addItemSelectFrame() {
    if (!this.selectFrame) {
      this.selectFrame = this.drawSelectedGraphics()
    }
    this.addChild(this.selectFrame)
    this.selected = true
  }

  removeSelectFrame() {
    this.removeChild(this.selectFrame)
    this.selected = false
  }

  _onDragStart(e) {
    if (e == null || e.target == null) {
      return
    }

    e.stopPropagation()

    if (e.target.type !== this.type || e.data.button !== 0 || this.dragging === true) {
      return
    }

    this._attachToRoot()

    // Set up the target to drag
    this.data = e.data
    this.alpha = this.dragAlpha

    // Select the item. This draws the item select frame based on the target's starting anchor position (0.5, 0.5) and
    // needs to be done before resetting the anchor below
    this._selectItem()

    // Reset the anchor position based on the place where the item has been clicked so that the image does not jump.
    // This is done by setting the anchor position to be the same as the click position. The anchor position will be
    // reset to the centre of the image later in the drag end event. This means that we don't have to keep recalculating
    // the click position offset with every drag event.Fit
    // this._removeItemHighlight()
    const position = this.data.getLocalPosition(this.parent)
    const left = this.x - (this.anchor.x * this.width)
    const newAnchorX = (position.x - left) / this.width
    const top = this.y - (this.anchor.y * this.height )
    const newAnchorY = (position.y - top) / this.height
    const newAnchor = {
      x: newAnchorX,
      y: newAnchorY
    }
    
    this.setAnchor(newAnchor)


   
    this.x = position.x
    this.y = position.y 

    this.dragging = true
    this.startDragMove = true

    // Move the select frame relative to how far the anchor position has moved, otherwise it will no longer be centred
    const moveFrameX = (this.imageAnchor.x - newAnchor.x) * this.width
    const moveFrameY = (this.imageAnchor.y - newAnchor.y) * this.height
    this._moveItemSelectFrame(moveFrameX, moveFrameY)

  }

  setAnchor(newAnchor)
  {
    this.anchor = newAnchor
  }

  _reattachToGondola() {
    let closestGondola = null
    let nearestDistanceToGondola = null

    // Work out the boundaries of the target image
    const targetLeft = this.x - (this.anchor.x * this.texture.width)
    const targetRight = targetLeft + this.texture.width
    const targetTop = this.y - (this.anchor.y * this.texture.height)
    const targetBottom = targetTop + this.texture.height
    const targetCentreX = targetLeft + ((targetRight - targetLeft) / 2)
    const targetCentreY = targetTop + ((targetBottom - targetTop) / 2)

    // Find the nearest gondola to the target
    let gondolas = this.rangeBuilder.children.filter(g => g.type === Gondola.typeIdentifier) //get all gondolas
    gondolas.sort(Gondola.sortOnY)

    // Get hold of the top of the first gondola and the bottom of the last gondola
    const topBound = gondolas[0].y
    const bottomBound = gondolas[gondolas.length - 1].y + Gondola.getGondolaHeight()
    // If we are above the top gondola or below the bottom gondola, select the nearest gondola

    if (targetBottom < topBound) {
      closestGondola = gondolas[0]
    } else if (targetTop > bottomBound) {
      closestGondola = gondolas[gondolas.length - 1]
    }

    if (closestGondola == null) {
      for (let i = 0; i < gondolas.length; i += 1) {
        const gondolaLeft = gondolas[i].x
        const gondolaRight = gondolaLeft + Gondola.getBayWidth(gondolas[i].bayCount)
        const gondolaTop = gondolas[i].y
        const gondolaBottom = gondolaTop + Gondola.getGondolaHeight()

        // If we are inside the bounds of this gondola, don't look any further
        if (targetLeft >= gondolaLeft && targetRight <= gondolaRight &&
          targetTop >= gondolaTop && targetBottom <= gondolaBottom) {
          closestGondola = gondolas[i]
          break
        }

        // If we are inside the vertical bounds of a gondola, select this gondola
        if (targetCentreY >= gondolaTop && targetCentreY <= gondolaBottom) {
          closestGondola = gondolas[i]
          break
        }

        // Select the gondola whose centre is nearest to the centre of the target
        const xDistanceToGondola = Math.abs(Math.max(gondolaLeft - targetCentreX, 0, targetCentreX - gondolaRight))
        const yDistanceToGondola = Math.abs(Math.max(gondolaTop - targetCentreY, 0, targetCentreY - gondolaBottom))

        let distanceToGondola = 0
        if (xDistanceToGondola === 0) {
          distanceToGondola = yDistanceToGondola
        } else if (yDistanceToGondola === 0) {
          distanceToGondola = xDistanceToGondola
        } else {
          // Use Pythagoras
          distanceToGondola = Math.sqrt(xDistanceToGondola * xDistanceToGondola + yDistanceToGondola * yDistanceToGondola)
        }
        if (closestGondola == null || distanceToGondola <= nearestDistanceToGondola) {
          closestGondola = gondolas[i]
          nearestDistanceToGondola = distanceToGondola
        }
      }
    }
    this._reattachToParent(closestGondola)
  }

  _reattachToParent(newParent) {
    newParent.addChild(this)
  }

  _onDragMove() {
    if (this.dragging) {

      if (this.startDragMove === true && this.onStartDragMoveCallback != null) {
        this.startDragMove = false
        this.onStartDragMoveCallback()
      }

      var newPosition = this.data.getLocalPosition(this.parent)
      this.x = newPosition.x
      this.y = newPosition.y
      this.parent.handleItemScroll(this)
    }
  }

  _onDragEnd(e) {
    if (this.dragging) {
      this.parent.setStateIsDirty(true)// parent is rangebuiolder at this point
      var newPosition = e.data.getLocalPosition(this.parent)

      this._reattachToGondola()

      let containerGlobalPosition = this.parent.getGlobalPosition()
      newPosition = { x: newPosition.x - containerGlobalPosition.x, y: newPosition.y - containerGlobalPosition.y }

      // Move the anchor position back to the centre of the image. To do this, we need to work out how
      // far the current position at the current anchor point is away from the new anchor point, then
      // move the position of the image at the same time as giving a new anchor point.
      const newX = newPosition.x + ((this.imageAnchor.x - this.anchor.x) * this.width)
      const newY = newPosition.y + ((this.imageAnchor.y - this.anchor.y) * this.height)
      this.x = newX
      this.y = newY
      this.setAnchor(this.imageAnchor)

      // Move the item inside the nearest gondola if it is outside the bounds of all the gondolas
      this.moveItemInsideGondolaBounds()
      this.alpha = 1
      this.dragging = false
      this.data = null
      this._moveItemSelectFrameTo(0, 0)

      if (this.onDragEndCallback != null) {
        this.onDragEndCallback()
      }

      if (this.onSnapToBarCallback != null) {
        this.onSnapToBarCallback(e)
      }
    }
  }

  _moveItemSelectFrame(moveX, moveY) {
    if (this.selectFrame != null) {
      this.selectFrame.x += moveX
      this.selectFrame.y += moveY
    }
  }

  _moveItemSelectFrameTo(moveX, moveY) {
    if (this.selectFrame != null) {
      this.selectFrame.x = moveX
      this.selectFrame.y = moveY
    }
  }

  moveItemInsideGondolaBounds() {
    let gondolaTop = this.parent.backGroundSprite.position.y
    let gondolabottom = gondolaTop + Gondola.getGondolaHeight()
    let gondolaLeft = this.parent.backGroundSprite.position.x
    let gondolaRight = gondolaLeft + Gondola.getBayWidth(this.parent.bayCount)

    let itemTop = this.position.y - this.texture.height / 2
    let itemBottom = this.position.y + this.texture.height / 2
    let itemLeft = this.position.x - this.texture.width / 2
    let itemRight = this.position.x + this.texture.width / 2

    if (itemBottom > gondolabottom) {
      this.position.y = gondolabottom - (this.texture.height / 2)
    }
    if (itemTop < gondolaTop) {
      this.position.y = gondolaTop + (this.texture.height / 2)
    }
    if (itemLeft < gondolaLeft) {
      this.position.x = gondolaLeft + (this.texture.width / 2)
    }
    if (itemRight > gondolaRight) {
      this.position.x = gondolaRight - (this.texture.width / 2)
    }
  }
}