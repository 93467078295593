import { PublicClientApplication } from "@azure/msal-browser";
import { apiRequests, loginRequest, msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

export const apiHost =
  process.env.NODE_ENV === "production" ? "/api" : "http://localhost:55555/api";

const baseUrl = window.location.origin
  ? `${window.location.origin}/`
  : `${window.location.protocol}/${window.location.host}/`;

export const getAccessToken = async () =>
  await getToken([msalConfig.auth.clientId]);

export const getAccessTokenBlob = async () =>
  await getToken(msalConfig.auth.scopesBlob, true);

export const getToken = async (scopes, isBlobToken) => {
  try {
    let tokenResponse = null;
    if (baseUrl === window.location.href) {
      tokenResponse = await msalInstance.handleRedirectPromise(
        window.location.hash
      );
    }
    let accountObj;
    if (tokenResponse) {
      accountObj = tokenResponse.account;
    } else {
      accountObj = msalInstance.getAllAccounts()[0];
    }
    if (accountObj && tokenResponse) {
      console.info("[AuthService.init] Got valid accountObj and tokenResponse");
    } else if (accountObj) {
      console.info("[AuthService.init] User has logged in, but no tokens.");
      try {
        tokenResponse = await msalInstance.acquireTokenSilent({
          scopes: scopes,
          account: accountObj,
          ...(!isBlobToken && apiRequests),
        });
      } catch (err) {
        await msalInstance.acquireTokenRedirect({ ...apiRequests });
        console.error("Token Retrieval Error:", err);
      }
    } else {
      console.info(
        "[AuthService.init] No accountObject or tokenResponse present. User must now login."
      );
      await msalInstance.loginRedirect(loginRequest);
    }
    return await tokenResponse.accessToken;
  } catch (error) {
    console.error(
      "[AuthService.init] Failed to handleRedirectPromise()",
      error
    );
  }
};

export const redirectToLogin = () => {
  return true;
};

export const login = async (redirectUrl) => {
  msalInstance.handleRedirectPromise(window.location.hash).then((response) => {
    if (!response) {
      const loginRequest = {
        scopes: ["User.Read"],
        redirectStartPage: redirectUrl,
      };
      msalInstance.loginRedirect(loginRequest).catch((error) => {
        console.log("Unable to Login: ", error);
      });
    }
  });
};

export const logout = async () => {
  const account = msalInstance.getAllAccounts()[0];
  await msalInstance.logoutRedirect({
    account: account,
    postLogoutRedirectUri: window.location.origin,
  });
};
