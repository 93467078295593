import React, { Component } from 'react';
import { Card } from '@jsluna/react';
import { Cancel } from '@jsluna/icons';
import {connect} from 'react-redux';
import {toggleFilterPanel} from '../../../store/imagelibraryfilters/actions';

class FilteringPanel extends Component{
    constructor(props){
        super(props);
        this.getClassName = this.getClassName.bind(this);
        this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
    }

    toggleFilterPanel(){
        this.props.dispatch(toggleFilterPanel());
    }
    
    getClassName(){
        if (!this.props.isOpen){
            return "FilteringPanelClosed"
        }
        else {
            return 'FilteringPanelOpen'
        }
    }

    render(){
        return(
            <div className={this.getClassName()} id="FilteringPanelBody">
                <Card>
                    <div id="FilteringPanelTitle">
                        {this.props.title}   
                        <div id="FilteringPanelCancelIconWrap">
                        <Cancel 
                            onClick={this.toggleFilterPanel} 
                            size="large" 
                            id="FilteringPanelCancelIcon"/> 
                        </div>
                    </div>  
                    {this.props.children}  
                </Card>
            </div>  
        )
    }
}

export default connect((state,props) => {
    return {
        isOpen: state.imageLibraryData.isFilterPanelOpen
    }})(FilteringPanel);