import React from 'react'
import { PropTypes } from 'prop-types'
import {
  ButtonGroupWrapper, OutlinedButton, FilledButton,
  ModalHeading, Modal, Card, ButtonGroupPrimary
} from '@jsluna/react';
import ModalStylingFixForWin32 from '../../../../common/ModalStylingFixForWin32'

export function DeleteRangeModal(props) {
  return (
    <Modal
     data-testid="delete-range-modal"    
      restrictClose
      open={props.isOpen}
      className={"delete-range-modal"}
    >
      <Card className={"delete-range-modal-body"}>
        <ModalHeading data-testid="modal-heading" className={"modalheading"}>Are you sure you want to delete the range '{props.selectedRange.name}'?</ModalHeading>
        <p data-testid="delete-grade-description" className={"delete-modal-description"}>Deleting this range will remove all bays and associated clothing layouts.</p>
        <p data-testid="delete-action-description" className={"delete-modal-description"}>You will not be able to undo this action.</p>
        <ButtonGroupWrapper actionBar className={"DeleteCancelButtonWrapper"}>
          <ButtonGroupPrimary>
            <FilledButton data-testid="yes-button" className={"yes-button"} onClick={props.onDeleteRangeFromGrade}>
              Yes
            </FilledButton>
            <OutlinedButton data-testid="no-button" color="dark" className={"no-button"} onClick={props.onModalClose}>
              No
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Card>
    </Modal>
  )
}

DeleteRangeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDeleteRangeFromGrade: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  selectedRange: PropTypes.object.isRequired
}

export default ModalStylingFixForWin32(DeleteRangeModal)