import * as PIXI from 'pixi.js'
import Gondola from './Gondola'
import RangeBuilder from './RangeBuilder'

export default class Scene {
  defaultHeight(){ return 300 }
  defaultWidth(){ return Gondola.getBayWidth(7) + 9 }
  
  constructor(
    ranges,
    rangePlannerData,
    onRenderGondolasComplete,
    onScrollHandler,
    setIsDirtyCallback,
    onItemsRemoved,
    options = {
      width: this.defaultWidth(),
      height: this.defaultHeight(),  
      backgroundColor: 0xFFFFFF,
      antialias: true,
      roundPixel: true,
      resolution: window.screen.height <= 1080 ? 1 : 1.2 
    })
  {
    this.options = options;
    this.app = new PIXI.Application(options);  
    this.scrollCallback = onScrollHandler
    this.setIsDirtyCallback = setIsDirtyCallback
    this.onItemsRemoved = onItemsRemoved
    this.drawRanges(ranges, rangePlannerData, onRenderGondolasComplete)
    
  }

  getView(){
    return this.app.view
  }

  drawRanges(arrRanges, rangePlannerData, onRenderGondolasComplete){
    if (arrRanges && Array.isArray(arrRanges)) {
      this.rangeBuilder = new RangeBuilder(arrRanges, rangePlannerData, this.defaultWidth(), onRenderGondolasComplete, this.scrollCallback, this.setIsDirtyCallback, this.onItemsRemoved);
      this.rangeBuilder.resizeCanvas = this.resizeCanavas
      this.app.stage.addChild(this.rangeBuilder)
    }
  } 

  resizeCanavas = () =>{
  this.app.renderer.resize(this.defaultWidth(), this.rangeBuilder.height + 65);
  }
}
