import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types'
import {
  ButtonGroupWrapper, OutlinedButton, FilledButton,
  ModalHeading, Modal, Card, ButtonGroupPrimary, ButtonGroupSecondary
} from '@jsluna/react';
import CheckboxMultiSelect from '../../../Controls/CheckboxMultiSelect'
import ModalStylingFixForWin32 from '../../../../common/ModalStylingFixForWin32'

export function SelectRangesModal(props) {
  const errorNoRangeSelected = 'Please select one or more ranges to export'
  const [selectRanges, updateSelectRanges] = useState([]);
  const [errorMessage, updateErrorMessage] = useState('');

  useEffect(() => {
    if (props.isOpen === true) {
      const selectRanges = props.ranges.map(range => ({
          value: range.id.toString(),
          label: range.name,
          checked: false
        }))
      updateSelectRanges(selectRanges)
    }
  }, [props.isOpen, props.ranges]);

  const toggleCheckboxSelectedState = (e) => {
    const updatedSelectRanges = [...selectRanges]
    const range = updatedSelectRanges.find(r => r.value === e.target.value)
    
    if (range != null) {
      range.checked = !range.checked
      updateSelectRanges(updatedSelectRanges)
      
      if (range.checked === true) {
        updateErrorMessage('')
      }
    }
  }

  const handleClearClicked = (e) => {
    if (e) {
      e.preventDefault()
    }
    setAllCheckedValues(false)
  }

  const handleSelectAllClicked = (e) => {
    if (e) {
      e.preventDefault()
    }
    setAllCheckedValues(true)
    updateErrorMessage('')
  }

  const handleCloseClicked = (e) => {
    props.onModalClose(e)
    setAllCheckedValues(false)
    updateErrorMessage('')
  }

  const handleExportClicked = (e) => {
    const selectedRangeIds = selectRanges.filter(r => r.checked === true).map(range => (
      Number(range.value)
    ))

    if (selectedRangeIds.length === 0) {
      updateErrorMessage(errorNoRangeSelected)
      return
    }

    props.onExportSelectedRanges(e, selectedRangeIds)
    setAllCheckedValues(false)
  }

  const setAllCheckedValues = (checked) => {
    const updatedSelectRanges = selectRanges.map(r => ({
      ...r, checked: checked
    }))
    updateSelectRanges(updatedSelectRanges)
  }
  
  return (
    <Modal
      open={props.isOpen}
      restrictClose
      className={"select-ranges-modal"}
    >
      <Card>
        <ModalHeading className={"modalheading"}>Export grade</ModalHeading>
        <ButtonGroupWrapper className="select-ranges-secondary-button-wrapper">
          <ButtonGroupSecondary>
            <OutlinedButton color="dark" className={'ln-u-margin-right'} onClick={handleClearClicked}>
              Clear
            </OutlinedButton>
            <FilledButton color="dark" onClick={handleSelectAllClicked}>
              Select all
            </FilledButton>
          </ButtonGroupSecondary>
        </ButtonGroupWrapper>
        <CheckboxMultiSelect
          label={'Available ranges'}
          options={selectRanges}
          onChange={toggleCheckboxSelectedState}
          errorMessage={errorMessage}
        />
        <ButtonGroupWrapper className="select-ranges-button-wrapper">
          <ButtonGroupPrimary>
            <OutlinedButton color="dark" className={'ln-u-margin-right'} onClick={handleCloseClicked}>
              Cancel
            </OutlinedButton>
            <FilledButton onClick={handleExportClicked}>
              Export
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Card>
    </Modal>
  )
}

SelectRangesModal.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onExportSelectedRanges: PropTypes.func.isRequired
}

export default ModalStylingFixForWin32(SelectRangesModal)