import React, { Component } from 'react';
import { SelectField } from '@jsluna/react';
import {connect} from 'react-redux';
import {setClassFilter} from '../../../store/imagelibraryfilters/actions';

class ClassFilter extends Component {
  constructor(props){
    super(props);
    this.changeClassFilter = this.changeClassFilter.bind(this);
  }

  changeClassFilter(e){
    e.preventDefault();
    const newValue = e.target.value;
    this.props.dispatch(setClassFilter(newValue));
  }

    render() {
      return (
        <div id="">
          <SelectField 
            name="select-classes" 
            label="Class"
            placeholder={" "} 
            disabled={this.props.disableDropdowns}
            options={this.props.options}
            value={this.props.selectedValue}
            onChange={(e) => this.changeClassFilter(e)}/>           
        </div>
      );
    }
  }
  
  export default connect((state,props) => {
    return {
      options: state.imageLibraryData.dropdownValues.classes || [],
      selectedValue: state.imageLibraryData.classFilter || "",
      disableDropdowns: state.imageLibraryData.disableDropdowns
    }})(ClassFilter);