import { connect } from 'react-redux'

import ApplicationError from './ApplicationError'

const mapStateToProps = state => ({
  
})

const mapDispatchToProps = dispatch => ({
  setSelectedMenu: (menuOption) => {
    //dispatch(setSelectedMenu(menuOption))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationError)
