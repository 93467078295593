import { BreadcrumbsItem, BreadcrumbsWrapper } from "@jsluna/react";
import { PropTypes } from "prop-types";
import React from "react";
import { withRouter } from "../../../utils/reactRouterDom";

class Breadcrumbs extends React.Component {
  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.state = { crumbs: [] };
    this.navigate = this.props.router.navigate;
  }

  handleLinkClick(e, url, onClick) {
    e.preventDefault();
    if (onClick != null) {
      onClick();
    } else {
      this.navigate(url);
    }
  }

  render() {
    return (
      <BreadcrumbsWrapper className="breadcrumbs-wrapper">
        {this.props.items.map((item, index) => (
          <BreadcrumbsItem
            key={index.toString()}
            active={item.href == null && item.onClick == null}
            className="breadcrumbs-item"
          >
            {item.href != null ? (
              <a
                href={item.href}
                onClick={(e) =>
                  this.handleLinkClick(e, item.href, item.onClick)
                }
              >
                {item.name}
              </a>
            ) : (
              item.name
            )}
          </BreadcrumbsItem>
        ))}
      </BreadcrumbsWrapper>
    );
  }
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Breadcrumbs);
