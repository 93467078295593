import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Scene from "../../../builder/Scene";
import Gondola from "../../../builder/Gondola";
import Bar from "../../../builder/Bar";
import {
  FilledButton,
  IconButton,
  ButtonGroupPrimary,
  OutlinedButton,
} from "@jsluna/react";
import Breadcrumbs from "../../Controls/Breadcrumbs";
import breadcrumbHelper from "../../../utils/breadcrumbHelper";
import { api } from "../../../api/api";
import { ProgressSpinner } from "@jsluna/react";
import NavigateAwayGuard from "../NavigateAwayGuard";
import { Plus } from "@jsluna/icons";
import ListPagination from "../ListPagination";
import BayConstants from "../utils/bayConstants";
import RangeSelector from "../RangeSelector";
import DeleteBayModal from "../../Projects/ViewProject/DeleteBayModal";

class BayBuilder extends Component {
  constructor(props) {
    super(props);
    this.pureGondolas = [];
    this.scrollStepWidth =
      window.devicePixelRatio === 2 || window.screen.height > 1080 ? 20 : 60;
    this.scrollStepHeight =
      window.devicePixelRatio === 2 || window.screen.height > 1080 ? 30 : 60;

    this.state = {
      deleteBayModelOpen: false,
      gondolas: [],
      items: [],
      canvasHeight: 0,
      rangeId: this.props.rangeId,
      projectId: this.props.projectId,
      isDirty: false,
      saveEnabled: true,
      secondaryRanges: [],
      splitScreenMode: BayConstants.bayBuilderSplitScreenMode.none,
      primaryNext: true,
      primaryPrev: true,
      secondaryNext: true,
      secondaryPrev: true,
      secondaryRangeNext: true,
      secondaryRangePrev: true,
      secondaryRangeName: "Secondary Range",
    };

    this.addItem = this.addItem.bind(this);
    this.adjustScrollbar = this.adjustScrollbar.bind(this);
    this.renderMergeRangesButton = this.renderMergeRangesButton.bind(this);
    this.mergeRangesClicked = this.mergeRangesClicked.bind(this);
    this.exitMergeRangesClicked = this.exitMergeRangesClicked.bind(this);
    this.showPreviousMainRangeGondola =
      this.showPreviousMainRangeGondola.bind(this);
    this.showNextMainRangeGondola = this.showNextMainRangeGondola.bind(this);
    this.selectSecondaryRange = this.selectSecondaryRange.bind(this);
    this.showPreviousSecondaryRangeGondola =
      this.showPreviousSecondaryRangeGondola.bind(this);
    this.showNextSecondaryRangeGondola =
      this.showNextSecondaryRangeGondola.bind(this);
    this.showPreviousRange = this.showPreviousRange.bind(this);
    this.showNextRange = this.showNextRange.bind(this);
    this.setUpRanges = this.setUpRanges.bind(this);
    this.addBarButtonClick = this.addBarButtonClick.bind(this);
    this.navigate = this.props.router.navigate;
  }

  componentDidMount() {
    const { gradeRanges } = this.props;

    if (gradeRanges != null) {
      this.setUpRanges();
      this.initPixi();
    } else {
      api.handleError(this.props.router.location, "Error loading build mode");
    }
  }

  setUpRanges() {
    const { rangeId } = this.state;
    const { gradeRanges } = this.props;

    // Find the range we are building
    const primaryRange = gradeRanges.ranges.find((range) => {
      return range.id === rangeId;
    });

    // Find any other ranges in this grade ready for split screen mode
    let secondaryRanges = gradeRanges.ranges.filter((range) => {
      return range.id !== rangeId;
    });
    // Make a copy of the secondary ranges
    secondaryRanges = JSON.parse(JSON.stringify(secondaryRanges));

    this.setState({ secondaryRanges });

    this.loadPrimaryRangeGondolaModel(primaryRange.gondolas);
  }

  addNewGondolas(newGondolas) {
    return this.scene.rangeBuilder.addGondolas(newGondolas);
  }

  addItem(rangeId, selectedItemPhoto, rangeItemPhotos) {
    const { items } = this.state;
    const { onItemAdded } = this.props;
    this.scene.rangeBuilder.addItem(
      rangeId,
      selectedItemPhoto,
      rangeItemPhotos
    );

    this.setState({ items, isDirty: true });
    onItemAdded(rangeId);
  }

  addBarButtonClick() {
    let gondola = this.scene.rangeBuilder.selectedGondola;
    let barXCoordinate =
      gondola.xStartPointOfGondola +
      Gondola.singleBayWidth() * gondola.selectedBayIndex +
      150;
    let barYCoordinate = gondola.yBottomSnapPoint;
    let bar = new Bar(
      { x: barXCoordinate, y: barYCoordinate },
      this.scene.rangeBuilder
    );
    gondola._addBar(bar);
  }

  adjustScrollbar(target) {
    const left = target.x - 0.2 * target.width;
    const right = left + target.width;
    const top = target.y - 0.2 * target.height;
    const bottom = top + 220;

    if (left <= this.scrollbar.scrollLeft) {
      this.scrollbar.scrollLeft -= this.scrollStepWidth;
    }

    if (right >= this.scrollbar.scrollLeft + this.scrollbar.clientWidth) {
      this.scrollbar.scrollLeft += this.scrollStepWidth;
    }

    if (top <= this.scrollbar.scrollTop) {
      this.scrollbar.scrollTop -= this.scrollStepHeight;
    }

    if (bottom >= this.scrollbar.scrollTop + this.scrollbar.clientHeight) {
      this.scrollbar.scrollTop += this.scrollStepHeight;
    }
  }

  saveModel = () => {
    let toSave = this.scene.rangeBuilder.getGondolasToSave();
    let toDelete = this.scene.rangeBuilder.deletedGondolas;

    this.setState({ saveEnabled: false });

    const { projectId } = this.state;
    const { gradeNumber, onUsedImageCountsUpdated } = this.props;

    //add the deleted gondolas
    for (var d = 0; d < toDelete.length; d++) {
      toSave.push({
        id: toDelete[d].gondolaId,
        projectGradeRangeId: toDelete[d].projectGradeRangeId,
        bayCount: 0,
        items: "",
      });
    }

    api
      .saveGondolasForGrade(
        this.props.settings.baseApiUrl,
        projectId,
        gradeNumber,
        toSave
      )
      .then((savedGondolas) => {
        // Update gondola ids for any new gondolas
        const savedGradeRangeGondolas = savedGondolas.savedGradeRangeGondolas;
        this.scene.rangeBuilder.reloadSavedResult(savedGradeRangeGondolas);

        // Clear the deleted gondolas array
        this.scene.rangeBuilder.deletedGondolas = [];

        if (onUsedImageCountsUpdated != null) {
          onUsedImageCountsUpdated(savedGondolas.usedImageCounts);
        }

        this.setState({ isDirty: false });
      })
      .then(() => {
        setTimeout(() => {
          this.setState({ saveEnabled: true });
        }, 500);
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  };

  backToViewMode = () => {
    this.navigate(`/projects/${this.state.projectId}/view`);
  };

  loadPrimaryRangeGondolaModel = (jsonModel) => {
    if (jsonModel) {
      jsonModel.map((i) => this.pureGondolas.push(i));
    }
  };

  mergeRangesClicked() {
    // Get the new canvas height based on the split screen display
    const splitScreenMode = BayConstants.bayBuilderSplitScreenMode.select;
    this.scene.rangeBuilder.switchToMergeMode(null);
    this.setState({ splitScreenMode });
  }

  exitMergeRangesClicked() {
    this.setState({
      splitScreenMode: BayConstants.bayBuilderSplitScreenMode.none,
    });
    this.scene.rangeBuilder.closeMergeMode();
  }

  showPreviousMainRangeGondola() {
    this.scene.rangeBuilder.prevPrimaryGondola();
  }

  showNextMainRangeGondola() {
    this.scene.rangeBuilder.nextPrimaryGondola();
  }

  selectSecondaryRange(rangeId) {
    const { secondaryRanges } = this.state;
    const rangeIndex = secondaryRanges
      .map(function (r) {
        return r.id;
      })
      .indexOf(Number(rangeId));
    this.scene.rangeBuilder.setSelectedRangeIndex(rangeIndex);
    this.scene.rangeBuilder.loadSecondaryRanges(secondaryRanges);
    const splitScreenMode = BayConstants.bayBuilderSplitScreenMode.build;
    this.setState({ splitScreenMode });
  }

  showPreviousSecondaryRangeGondola() {
    this.scene.rangeBuilder.prevSecondaryGondola();
  }

  showNextSecondaryRangeGondola() {
    this.scene.rangeBuilder.nextSecondaryGondola();
  }

  showNextRange() {
    this.scene.rangeBuilder.nextSecondaryRange();
  }

  showPreviousRange() {
    this.scene.rangeBuilder.prevSecondaryRange();
  }

  isSplitScreenMode(splitScreenMode) {
    return (
      splitScreenMode === BayConstants.bayBuilderSplitScreenMode.select ||
      splitScreenMode === BayConstants.bayBuilderSplitScreenMode.build
    );
  }

  renderSaveButton() {
    if (this.state.saveEnabled) {
      return (
        <FilledButton
          data-testid="save-button"
          className="bay-builder--export"
          onClick={this.saveModel}
        >
          Save changes
        </FilledButton>
      );
    } else {
      return (
        <FilledButton
          data-testid="saving-button"
          className="bay-builder--export"
          disabled={true}
          onClick={this.saveModel}
        >
          Saving...
          <ProgressSpinner className="ln-u-push-right-sm" color="light" />
        </FilledButton>
      );
    }
  }

  renderMergeRangesButton() {
    const { splitScreenMode, secondaryRanges } = this.state;

    if (this.isSplitScreenMode(splitScreenMode)) {
      return (
        <IconButton
          label="Exit merge ranges"
          color="dark"
          className="bay-builder--exit"
          onClick={this.exitMergeRangesClicked}
        >
          <svg
            width="19px"
            height="14px"
            viewBox="0 0 19 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>icon-arrow-left</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="icon-arrow-left" fill="#4C4C4C" fillRule="nonzero">
                <path
                  d="M3.41421359,6 L17.875,6 C18.4963203,6 19,6.4477152 19,7 C19,7.55228475 18.4963203,8 17.875,8 L3.41421356,8 L7.7071068,12.2928932 C8.0976311,12.6834175 8.0976311,13.3165825 7.7071068,13.7071068 C7.3165825,14.0976311 6.68341752,14.0976311 6.29289323,13.7071068 L0.29289323,7.70710679 C-0.09763106,7.3165825 -0.09763106,6.68341752 0.29289323,6.29289323 L6.29289323,0.2928932 C6.68341752,-0.0976311 7.3165825,-0.0976311 7.7071068,0.2928932 C8.0976311,0.6834175 8.0976311,1.31658251 7.7071068,1.70710681 L3.41421359,6 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </svg>
        </IconButton>
      );
    }

    return (
      <OutlinedButton
        color="dark"
        className="bay-builder--export"
        onClick={this.mergeRangesClicked}
        disabled={secondaryRanges.length === 0}
      >
        Merge ranges
      </OutlinedButton>
    );
  }

  renderBackbutton = () => {
    const { splitScreenMode } = this.state;

    if (this.isSplitScreenMode(splitScreenMode)) {
      return null;
    }

    return (
      <IconButton
        label="Exit range builder"
        color="dark"
        className="bay-builder--exit"
        onClick={this.backToViewMode}
      >
        <svg
          width="19px"
          height="14px"
          viewBox="0 0 19 14"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>icon-arrow-left</title>
          <desc>Created with Sketch.</desc>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="icon-arrow-left" fill="#4C4C4C" fillRule="nonzero">
              <path
                d="M3.41421359,6 L17.875,6 C18.4963203,6 19,6.4477152 19,7 C19,7.55228475 18.4963203,8 17.875,8 L3.41421356,8 L7.7071068,12.2928932 C8.0976311,12.6834175 8.0976311,13.3165825 7.7071068,13.7071068 C7.3165825,14.0976311 6.68341752,14.0976311 6.29289323,13.7071068 L0.29289323,7.70710679 C-0.09763106,7.3165825 -0.09763106,6.68341752 0.29289323,6.29289323 L6.29289323,0.2928932 C6.68341752,-0.0976311 7.3165825,-0.0976311 7.7071068,0.2928932 C8.0976311,0.6834175 8.0976311,1.31658251 7.7071068,1.70710681 L3.41421359,6 Z"
                id="Combined-Shape"
              ></path>
            </g>
          </g>
        </svg>
      </IconButton>
    );
  };

  cancelDeleteBay = () => {
    this.setState({ deleteBayModelOpen: false });
  };

  openDeleteBayModal = (bayNumber, gondolaIndex) => {
    this.setState({
      deleteBayModelOpen: true,
      deleteBayNumber: bayNumber,
      deleteBayGondolaIndex: gondolaIndex,
    });
  };

  handleRenderGondolasComplete = (gondolaCount) => {
    this.setState({ gondolaCount });
  };

  primGondolaChanged = (index, length, bayCount) => {
    let prevEnabled = index > 0;
    let nextEnabled = index < length - 1;
    this.setState({
      primGondIndex: index,
      primRangeLength: length,
      primBayCount: bayCount,
      primaryNext: nextEnabled,
      primaryPrev: prevEnabled,
    });
  };

  secGondolaChanged = (
    index,
    length,
    bayCount,
    rangeName,
    rangeIndex,
    rangesLength
  ) => {
    let prevEnabled = index > 0;
    let nextEnabled = index < length - 1;
    let prevRangeEnabled = rangeIndex > 0;
    let nextRangeEnabled = rangeIndex < rangesLength - 1;
    this.setState({
      secGondIndex: index,
      secRangeLength: length,
      secBayCount: bayCount,
      secondaryNext: nextEnabled,
      secondaryPrev: prevEnabled,
      secondaryRangeName: rangeName,
      secondaryRangeNext: nextRangeEnabled,
      secondaryRangePrev: prevRangeEnabled,
    });
  };

  isDirty = () => {
    return this.state.isDirty;
  };

  setIsDirty = (dirty) => {
    this.setState({ isDirty: dirty });
  };

  render() {
    const {
      primGondIndex,
      secGondIndex,
      secBayCount,
      primBayCount,
      primaryNext,
      primaryPrev,
      secondaryNext,
      secondaryPrev,
      secondaryRangeNext,
      secondaryRangePrev,
      secondaryRangeName,
      deleteBayNumber,
      deleteBayGondolaIndex,
      deleteBayModelOpen,
      gondolaCount,
      projectId,
      rangeId,
      splitScreenMode,
      secondaryRanges,
    } = this.state;
    const { projectName, rangeName, narrow, gradeNumber } = this.props;
    return (
      <>
        {
          <>
            <ButtonGroupPrimary className="bay-builder-button-group">
              {this.renderBackbutton()}
              {this.renderMergeRangesButton()}
              {this.renderSaveButton()}
            </ButtonGroupPrimary>
            <NavigateAwayGuard
              when={this.isDirty()}
              navigate={(path) => this.navigate(path)}
              // Use as the "message" prop for the <Prompt /> component of React-Router
              shouldBlockNavigation={() => {
                return this.isDirty() && this.state.saveEnabled === true;
              }}
            />
          </>
        }
        <DeleteBayModal
          isOpen={deleteBayModelOpen}
          onDeleteBay={() => {
            this.scene.rangeBuilder.deleteBay(
              deleteBayGondolaIndex,
              deleteBayNumber - 1
            );
            this.setState({ deleteBayModelOpen: false });
          }} // convert number to index
          onModalClose={this.cancelDeleteBay}
          bayNumber={deleteBayNumber}
          gondolaIndex={deleteBayGondolaIndex}
        />
        <div
          className={this.getStageContainerClassName()}
          ref={(scrollbar) => {
            this.scrollbar = scrollbar;
          }}
        >
          <>
            <div
              className={
                narrow
                  ? "bay-builder-build-mode-header bay-builder-build-mode-header--small"
                  : "bay-builder-build-mode-header"
              }
            >
              <Breadcrumbs
                items={breadcrumbHelper.createBayBuilderItems(
                  projectId,
                  projectName,
                  gradeNumber,
                  rangeId,
                  rangeName,
                  this.isSplitScreenMode(splitScreenMode),
                  this.exitMergeRangesClicked
                )}
              />
              <h3 className="bay-builder-build-mode-header--title">
                {rangeName}
              </h3>
            </div>
            {(splitScreenMode ===
              BayConstants.bayBuilderSplitScreenMode.select ||
              splitScreenMode ===
                BayConstants.bayBuilderSplitScreenMode.build) && (
              <ListPagination
                className="bay-builder-cycle-gondolas bay-builder-cycle-gondolas--primary-range"
                enableLeft={primaryPrev}
                enableRight={primaryNext}
                pageLeftClicked={this.showPreviousMainRangeGondola}
                pageRightClicked={this.showNextMainRangeGondola}
              >
                <strong>{`Gondola ${primGondIndex + 1} `}</strong>
                {`(${primBayCount} bay${primBayCount > 1 ? "s" : ""})`}
              </ListPagination>
            )}
            {splitScreenMode ===
              BayConstants.bayBuilderSplitScreenMode.build && (
              <>
                <div className="bay-builder-secondary-range-selector">
                  <ListPagination
                    className="bay-builder-secondary-range-selector--cycle-ranges"
                    enableLeft={secondaryRangePrev}
                    enableRight={secondaryRangeNext}
                    pageLeftClicked={this.showPreviousRange}
                    pageRightClicked={this.showNextRange}
                  />
                  <h3 className="bay-builder-secondary-range-selector--title">
                    {secondaryRangeName}
                  </h3>
                </div>
                <ListPagination
                  className="bay-builder-cycle-gondolas bay-builder-cycle-gondolas--secondary-range"
                  enableLeft={secondaryPrev}
                  enableRight={secondaryNext}
                  pageLeftClicked={this.showPreviousSecondaryRangeGondola}
                  pageRightClicked={this.showNextSecondaryRangeGondola}
                >
                  <strong>{`Gondola ${secGondIndex + 1} `}</strong>
                  {`(${secBayCount} bay${secBayCount > 1 ? "s" : ""})`}
                </ListPagination>
              </>
            )}
          </>
          <div
            ref={(pixiCont) => (this.pixiContainer = pixiCont)}
            id="pixiContainer"
          ></div>

          {splitScreenMode === BayConstants.bayBuilderSplitScreenMode.none && (
            <div className="baybuilder-add-gondola">
              <OutlinedButton
                color="dark"
                className={"add-gondola-button"}
                onClick={(e) =>
                  this.props.onHandleAddGondolaModalOpen(e, gondolaCount)
                }
                disabled={gondolaCount >= 4 ? true : false}
              >
                <Plus /> Add gondola
              </OutlinedButton>
            </div>
          )}
          {splitScreenMode ===
            BayConstants.bayBuilderSplitScreenMode.select && (
            <RangeSelector
              ranges={secondaryRanges}
              onAddRangeClick={this.selectSecondaryRange}
            />
          )}
        </div>
      </>
    );
  }

  getStageContainerClassName = () => {
    return `${this.props.narrow ? "stage stage--small" : "stage"}`;
  };

  initPixi = () => {
    this.scene = new Scene(
      this.pureGondolas,
      this.props.rangePlannerData,
      this.handleRenderGondolasComplete,
      this.adjustScrollbar,
      this.setIsDirty,
      this.props.onItemsRemoved
    );
    this.scene.resizeCanavas();
    this.scene.rangeBuilder.setupEnablingCallbacksForGondolaNavigationControl(
      this.primGondolaChanged,
      this.secGondolaChanged
    );
    this.scene.rangeBuilder.openDeleteBayModal = this.openDeleteBayModal;
    if (this.pixiContainer) {
      this.pixiContainer.appendChild(this.scene.getView());
    }
  };
}

BayBuilder.propTypes = {
  projectId: PropTypes.number.isRequired,
  projectName: PropTypes.string,
  gradeNumber: PropTypes.number,
  rangeId: PropTypes.number.isRequired,
  rangeName: PropTypes.string.isRequired,
  narrow: PropTypes.bool,
  settings: PropTypes.shape({
    baseApiUrl: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  rangeModel: PropTypes.array,
  gradeRanges: PropTypes.shape({
    grade: PropTypes.number.isRequired,
    projectName: PropTypes.string.isRequired,
    ranges: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        gondolas: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            bayCount: PropTypes.number.isRequired,
          }).isRequired
        ).isRequired,
      }).isRequired
    ).isRequired,
  }),
  openAddGondolaModal: PropTypes.bool,
  onHandleAddGondolaModalOpen: PropTypes.func,
  onUsedImageCountsUpdated: PropTypes.func.isRequired,
  onItemAdded: PropTypes.func.isRequired,
  onItemsRemoved: PropTypes.func.isRequired,
};

BayBuilder.defaultProps = {
  narrow: false,
  rangeModel: null,
  projectName: "",
  gradeNumber: 0,
  gradeRanges: null,
};

export default BayBuilder;
