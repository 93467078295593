import React, { Component } from 'react';
import { ButtonGroupWrapper, FilledButton } from '@jsluna/react';
import ImageList from '../ImageList/ImageList';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import Error from '../../Error';
import ImageLibraryFilters from '../../ImageLibraryFilterComponents/ImageLibraryFilters/ImageLibraryFilters';
import {connect} from 'react-redux';
import {toggleFilterPanel} from '../../../store/imagelibraryfilters/actions'
import RangeBuilder from '../../../builder/RangeBuilder.js'

class ImageLibrary extends Component{
    constructor(props){
        super(props);
        this.rangeBuilder = RangeBuilder
        this.toggleFilterPanel = this.toggleFilterPanel.bind(this);
    }

	toggleFilterPanel(){
		this.props.dispatch(toggleFilterPanel());
    }

    render(){        
        if(!this.props.isLoaded || false){
            return <LoadingIndicator/>
        }
        if(this.props.error){
            //TODOD: Decide how to handle error and what to display to the user
            return <Error />
        }
        
        return(
            <div className="imageLibrary">
                <ImageLibraryFilters />         
                <div className="ImageLibraryImageList">
                    <ImageList rangePlannerData={this.props.rangePlannerData} activeUsedImageCounts={this.props.activeUsedImageCounts} imageSelected={this.props.imageSelected}/>
                        <div className="ImageLibraryImageListButton" >
                            <ButtonGroupWrapper>
                                <FilledButton className={"AddBarButton"} color="dark" onClick={this.props.onhandleAddBarButtonClick}>
                                        Add Bar
                                </FilledButton>
                                <FilledButton className={"FilterImagesButton"} onClick={this.toggleFilterPanel} disabled={!this.props.isOpen ? false : true}>
                                        Filter {this.props.activeFiltersCount.length > 0 ? "applied ("+this.props.activeFiltersCount.length+")" : "images"}
                                </FilledButton>
                            </ButtonGroupWrapper>
                    </div>
                </div>
            </div>               
        )
    }
}

export default connect((state) => {
    return {
        isOpen: state.imageLibraryData.isFilterPanelOpen,
        rangePlannerData: state.imageLibraryData.filteredResultData,
        isLoaded: state.imageLibraryData.isDataLoaded || false,
        title: "Image Library (" + state.imageLibraryData.filteredResultData.length + ")",
        activeFiltersCount: state.imageLibraryData.activeFiltersCount
    }})(ImageLibrary);