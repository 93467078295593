import React from 'react'
import PropTypes from 'prop-types'
import { OutlinedButton, ButtonGroupPrimary, ButtonGroupWrapper, Card, Modal, ModalHeading, FilledButton } from '@jsluna/react'


const ManageProjectModal = (props) => {
  return (
    <div>
        <Modal
          restrictClose
          open={props.isOpen}
          className={"add-styles-modal"}
        >
          <Card className={"add-styles-modal-body"}>
            <ModalHeading className={"modalheading"}>{props.modalHeading}</ModalHeading>
            <p className={"add-modal-description"}>{props.description}</p>
            <ButtonGroupWrapper actionBar className={"add-ButtonWrapper"}>
              <ButtonGroupPrimary>
                <FilledButton className={"yes-button"} onClick={props.yesCallback}>
                  Yes
            </FilledButton>
                <OutlinedButton color="dark" className={"no-button"} onClick={props.noCallback}>
                  No
            </OutlinedButton>
              </ButtonGroupPrimary>
            </ButtonGroupWrapper>
          </Card>
        </Modal>
      </div>
  )
}

ManageProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  modalHeading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  yesCallback: PropTypes.func.isRequired,
  noCallback: PropTypes.func.isRequired
}

export default ManageProjectModal