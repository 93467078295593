import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import imageLibraryData from './imagelibraryfilters/reducer'
import createProjectPeriod from './createProjectPeriod/reducer'
import settings from './settings/reducer'
import menu from './menu/reducer'
import user from './user/reducer'
import selectedGrade from './selectedGrade/reducer'
import projectToClone from './setCloneProject/reducer'
import maxLibraryImages from './maxLibraryImages/reducer'

import projectToManage from './setManageProject/reducer'

const  rootReducer =  (history) => combineReducers({
  router: connectRouter(history),
  imageLibraryData,
  settings,
  createProjectPeriod,
  menu,
  user,
  selectedGrade,
  projectToClone,
  maxLibraryImages,
  projectToManage
})

export default rootReducer
