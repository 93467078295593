export default class Utils {

  static createDashedLine(g, start, end, dashLength, otherCoordinate, isHorizontal){
		let drawLine = true
		for (var i = start; i < end; i += dashLength) {
			if (isHorizontal === true) {
				g.moveTo(i, otherCoordinate)
				if (drawLine === true && i + dashLength < end) {
					g.lineTo(i + dashLength, otherCoordinate)
				}
			} else {
				g.moveTo(otherCoordinate, i)
				if (drawLine === true && i + dashLength < end) {
					g.lineTo(otherCoordinate, i + dashLength)
				}
			}
			drawLine = !drawLine
		}
  }

}