import React from 'react'
import { Card, CloseButton } from '@jsluna/react'
import { ErrorCircle, InfoCircle } from '@jsluna/icons';

function Banner(props) {
  return (
      <Card soft={"true"} className={`banner-${props.type}`}>
        {props.type === "info" && 
          <div className="info-circle-container">
            <InfoCircle className={"info-circle"} /> <p className={"description"}>{props.message}</p>
          </div> 
        }
        {props.type === "error" && 
          <div className="error-circle-container">
            <ErrorCircle className={"error-circle"} /> <p className={"description"}>{props.message}</p>
          </div>
        }
        {props.type === "success" && 
          <div className="success-circle-container">
            <p className={"description"}>{props.message}</p>
          </div>
        }
        <CloseButton className={"banner-closebutton"} onClick={(e) => props.onhandleClose(e)} />
      </Card>
  )
}

export default Banner
