import { PropTypes } from "prop-types";
import React, { Component } from "react";
import ProjectRangeList from "../ProjectRangeList";
import ProjectRangeView from "../ProjectRangeView";

class ProjectRanges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeRangeId: this.getFirstRangeId(),
    };

    this.projectRangeViewRef = React.createRef();

    this.handleProjectRangeItemClick =
      this.handleProjectRangeItemClick.bind(this);
    this.handleProjectRangeActiveChange =
      this.handleProjectRangeActiveChange.bind(this);
    this.handleBackToTopClick = this.handleBackToTopClick.bind(this);
    this.getFirstRangeId = this.getFirstRangeId.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  componentDidMount() {
    // Add a class to make the background white
    document.body.classList.add("bay-display");
  }

  componentWillUnmount() {
    // Remove the class that made the background white
    document.body.classList.remove("bay-display");
  }

  getFirstRangeId() {
    const { ranges } = this.props;
    return ranges !== null && ranges.length > 0 ? ranges[0].id : -1;
  }

  handleProjectRangeItemClick(e, rangeId) {
    if (e) {
      e.preventDefault();
    }

    this.setState({ activeRangeId: rangeId });

    if (this.projectRangeViewRef) {
      this.projectRangeViewRef.current.selectRange(rangeId);
    }
  }

  handleProjectRangeActiveChange(rangeId) {
    this.setState({ activeRangeId: rangeId });
  }

  handleBackToTopClick(e) {
    if (e) {
      e.preventDefault();
    }
    this.scrollToTop();
  }

  scrollToTop() {
    this.setState({ activeRangeId: this.getFirstRangeId() });

    if (this.projectRangeViewRef) {
      this.projectRangeViewRef.current.scrollToTop();
    }
  }

  render() {
    return (
      <div className="project-range">
        <ProjectRangeList
          ranges={this.props.ranges}
          activeRangeId={this.state.activeRangeId}
          onProjectRangeItemClick={this.handleProjectRangeItemClick}
          onAddRangeClick={this.props.onAddRangeClick}
        />
        <ProjectRangeView
          ref={this.projectRangeViewRef}
          projectId={this.props.projectId}
          ranges={this.props.ranges}
          activeRangeId={this.state.activeRangeId}
          onProjectRangeActiveChange={this.handleProjectRangeActiveChange}
          onBackToTopClick={this.handleBackToTopClick}
          onBuildRangeClick={this.props.onBuildRangeClick}
          onExportRangeClick={this.props.onExportRangeClick}
          onExportGradeClick={this.props.onExportGradeClick}
          onEditRangeClick={this.props.onEditRangeClick}
          onDeleteRangeClick={this.props.onDeleteRangeClick}
          settings={this.props.settings}
          router={this.props.router}
        />
      </div>
    );
  }
}

ProjectRanges.propTypes = {
  projectId: PropTypes.number.isRequired,
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      gondolas: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          bayCount: PropTypes.number.isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ).isRequired,
  onAddRangeClick: PropTypes.func.isRequired,
  onBuildRangeClick: PropTypes.func.isRequired,
  onEditRangeClick: PropTypes.func.isRequired,
  onDeleteRangeClick: PropTypes.func.isRequired,
  onExportRangeClick: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    baseApiUrl: PropTypes.string.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default ProjectRanges;
