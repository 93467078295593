import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Modal , Card} from '@jsluna/react'
import GradeList from '../GradeList'
import GradeAdd from '../GradeAdd'
import GradeDelete from '../GradeDelete'
import DeleteGradeModal from '../DeleteGradeModal'

export class GradeMenu extends Component {
  constructor(props) {
    super(props)

    this.gradeMenuMode = {
      view: 0,
      add: 1
    }

    this.existingGradeToLowError = 'Cannot copy from a lower grade'

    this.state = {
      mode: this.gradeMenuMode.view,
      selectedNewGradeNumber: '',
      selectedExistingGradeId: '',
      existingGradeError: '',
      gradeToDelete: 0,
      saving: false,
      openDeleteGradeModal: false
    }

    this.handleAddGradeClick = this.handleAddGradeClick.bind(this)
    this.handleChangeNewGrade = this.handleChangeNewGrade.bind(this)
    this.handleChangeExistingGrade = this.handleChangeExistingGrade.bind(this)
    this.handleCreateGradeClick = this.handleCreateGradeClick.bind(this)
    this.handleCreateGradeCancelClick = this.handleCreateGradeCancelClick.bind(this)
    this.handleDeleteGradeClick = this.handleDeleteGradeClick.bind(this)
    this.getExistingGrade = this.getExistingGrade.bind(this)
    
  }

  getExistingGrade(grades, selectedExistingGradeId) {
    let existingGrade = null
    if (selectedExistingGradeId != null && selectedExistingGradeId !== '') {
      existingGrade = grades.find(function(grade) {
        return grade.gradeId === Number(selectedExistingGradeId)
      })
    }
    return existingGrade
  }

  handleAddGradeClick(e) {
    if (e) {
      e.preventDefault()
    }
    const { grades, availableGrades } = this.props
    this.props.resetStatusMessage();
    const selectedNewGradeNumber = availableGrades[0]
    const existingGrades = [...grades] 
    const selectedExistingGrade = existingGrades.reverse().find(function(grade) {
      return grade.gradeNumber < selectedNewGradeNumber
    })

    this.setState({ mode: this.gradeMenuMode.add, selectedNewGradeNumber: selectedNewGradeNumber.toString(),
      selectedExistingGradeId: selectedExistingGrade.gradeId.toString(), existingGradeError: '' })
  }

  handleDeleteGradeClick(e) {
    if (e) {
      e.preventDefault()
    }
    const { grades } = this.props
    this.props.resetStatusMessage();
    let withoutGrade1 = grades.filter((g) => {
      return g.gradeNumber !== 1
    });

    if(withoutGrade1.length >= 1)
    {
      this.setState({ mode: this.gradeMenuMode.delete,gradeToDelete : withoutGrade1[0].gradeId, gradeNumberToDelete: 'Grade ' + withoutGrade1[0].gradeNumber });
    }
  }



  handleChangeNewGrade(e) {
    const selectedNewGradeNumber = e.target.value
    const { selectedExistingGradeId, existingGradeError } = this.state
    const { grades } = this.props

    if (existingGradeError != null && existingGradeError !== '') {
      const existingGrade = this.getExistingGrade(grades, selectedExistingGradeId)
      
      if (existingGrade == null || existingGrade.gradeNumber < selectedNewGradeNumber) {
        this.setState({ existingGradeError: '' })
      }
    } 

    this.setState({ selectedNewGradeNumber })
  }

  handleChangeExistingGrade(e) {
    const selectedExistingGradeId = e.target.value
    const { selectedNewGradeNumber, existingGradeError } = this.state
    const { grades } = this.props

    if (existingGradeError != null && existingGradeError !== '') {
      const existingGrade = this.getExistingGrade(grades, selectedExistingGradeId)
      
      if (existingGrade == null || existingGrade.gradeNumber < selectedNewGradeNumber) {
        this.setState({ existingGradeError: '' })
      }
    } 
    this.setState({ selectedExistingGradeId })
  }

  handleCreateGradeCancelClick(e) {
    if (e) {
      e.preventDefault()
    }
    this.setState({ mode: this.gradeMenuMode.view })
  }

  handleDeleteGradeCancelClick = (e) => 
  {
    if (e) {
      e.preventDefault()
    }
    this.setState({ mode: this.gradeMenuMode.view })
  }

  handleDeleteGrade = (e) => {
    if (e) {
      e.preventDefault()
    }
    const { gradeToDelete, gradeNumberToDelete } = this.state;
    this.setState({ saving: true}, () =>
    {
      this.props.onDeleteGrade(gradeToDelete, gradeNumberToDelete)
      this.props.openGradeMenu()
    }
    )
  }
  
  handleChangeGradeToDelete = (e) => 
  {
    let {gradeToDelete } = this.props;
    gradeToDelete = e.target.value;

    let selectedItem  = e.target.selectedOptions[0];
    if(selectedItem)
    {
      this.setState({gradeToDelete,gradeNumberToDelete: selectedItem.innerHTML   });
    }
  }

  handleCreateGradeClick(e) {
    if (e) {
      e.preventDefault()
    }
    const { selectedNewGradeNumber, selectedExistingGradeId } = this.state
    const { grades, onCreateGradeClick } = this.props

    // Check that the grade being created is a higher number than the one being copied
    const existingGrade = this.getExistingGrade(grades, selectedExistingGradeId)

    if (existingGrade != null && existingGrade.gradeNumber >= selectedNewGradeNumber) {
      this.setState({ existingGradeError: this.existingGradeToLowError })
    } else {
      this.setState({ saving: true }, () => {
        onCreateGradeClick(selectedExistingGradeId, selectedNewGradeNumber);
    
      }
      )
    }
  }

  handleModalClose = () => 
  {
    this.props.openGradeMenu();
    this.setState({openDeleteGradeModal: false})
  }

  handleModalOpen = () => 
  {
    this.props.closeGradeMenu();
    this.setState({openDeleteGradeModal: true})
  }

  

  render = () => {
    const { mode, gradeToDelete, gradeNumberToDelete,selectedNewGradeNumber, selectedExistingGradeId, existingGradeError, saving , openDeleteGradeModal} = this.state
    const { menuOpen, grades, availableGrades, activeGradeNumber, onCloseGradeMenuClick, onGradeItemClick, statusMessage } = this.props
    return (
      <div>
      <Modal
        open={menuOpen}
        restrictClose
        className={"grade-menu"}
      >
        <div className="grade-menu-scoll-area">
          {
            statusMessage !== "" &&
            <Card className="grade-menu-status">
              <p className="grade-menu-status-message" >{statusMessage}</p>
            </Card>

          }
          { mode === this.gradeMenuMode.view &&
            <GradeList
              grades={grades}
              activeGradeNumber={activeGradeNumber}
              onCloseGradeMenuClick={onCloseGradeMenuClick}
              onGradeItemClick={onGradeItemClick}
              onAddGradeClick={this.handleAddGradeClick}
              disableAddGrade={availableGrades.length === 0}
              onDeleteGradeClick={this.handleDeleteGradeClick}              
            />
          }
          { mode === this.gradeMenuMode.add &&
            <GradeAdd
              newGrades={availableGrades}
              selectedNewGradeNumber={selectedNewGradeNumber}
              onChangeNewGrade={this.handleChangeNewGrade}
              existingGrades={grades}
              selectedExistingGradeId={selectedExistingGradeId}
              onChangeExistingGrade={this.handleChangeExistingGrade}
              onCreateGradeClick={this.handleCreateGradeClick}
              onCreateGradeCancelClick={this.handleCreateGradeCancelClick}
              existingGradeError={existingGradeError}
              saving={saving}
            />
          }
          { mode === this.gradeMenuMode.delete && 
            <div>
            <GradeDelete
              onDeleteGradeCancelClick={this.handleDeleteGradeCancelClick}
              onDeleteGradeClick={this.handleModalOpen}
              grades={grades}
              saving={saving}
              onChangeGradeToDelete={this.handleChangeGradeToDelete}
              gradeToDelete={gradeToDelete}
            />
            
            </div>
          }

        </div>
      </Modal>
      {

        mode === this.gradeMenuMode.delete && 
            <div>
            <DeleteGradeModal
            isOpen={openDeleteGradeModal}
            onDeleteGrade={this.handleDeleteGrade}
            onModalClose={this.handleModalClose}
            gradeToDelete={gradeNumberToDelete}
            />
            
            </div>
      }
      </div>
      
    )
  }
}



GradeMenu.propTypes = {
  grades: PropTypes.arrayOf(
    PropTypes.shape({
      gradeId: PropTypes.number.isRequired,
      gradeNumber: PropTypes.number.isRequired
    })).isRequired,
  availableGrades: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  activeGradeNumber: PropTypes.number.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  onCloseGradeMenuClick: PropTypes.func.isRequired,
  onGradeItemClick: PropTypes.func.isRequired,
  onCreateGradeClick: PropTypes.func.isRequired,
  onDeleteGrade: PropTypes.func.isRequired
}

export default GradeMenu