import {
  Button,
  OutlinedButton,
  ProgressSpinner,
  SelectField,
} from "@jsluna/react";
import { PropTypes } from "prop-types";
import React from "react";

function GradeDelete(props) {
  return (
    <div className="grade-add">
      <div className="grade-add-content">
        <h4>Delete a grade</h4>
        <p>Select a grade to delete. You cannot delete Grade 1.</p>

        <SelectField
          name="newGrade"
          label="Choose grade"
          className="grade-add-new-grade-select"
          placeholder={false}
          options={createGradesList(props.grades)}
          onChange={(e) => props.onChangeGradeToDelete(e)}
        />
        {props.saving === true && (
          <Button
            variant="filled"
            className="grade-delete-save-button"
            disabled={true}
          >
            Deleting ...
            <ProgressSpinner
              className="ln-u-push-right-sm grade-add-save-button--progress-spinner"
              color="light"
            />
          </Button>
        )}
        {props.saving === false && (
          <Button
            variant="filled"
            className="grade-delete-save-button"
            disabled={createGradesList(props.grades).length < 1}
            onClick={props.onDeleteGradeClick}
          >
            Delete grade
          </Button>
        )}
        <OutlinedButton
          color="dark"
          onClick={props.onDeleteGradeCancelClick}
          disabled={props.saving === true}
        >
          Cancel
        </OutlinedButton>
      </div>
    </div>
  );
}

const createGradesList = (grades) => {
  const gradeList = [];
  grades.map((grade) => {
    if (grade.gradeNumber !== 1) {
      gradeList.push({
        label: `Grade ${grade.gradeNumber}`,
        value: Number(grade.gradeId),
      });
    }
    return null;
  });
  return gradeList;
};

GradeDelete.propTypes = {
  grades: PropTypes.arrayOf(
    PropTypes.shape({
      gradeId: PropTypes.number.isRequired,
      gradeNumber: PropTypes.number.isRequired,
    })
  ).isRequired,
  onDeleteGradeClick: PropTypes.func.isRequired,
  onChangeGradeToDelete: PropTypes.func.isRequired,
  onDeleteGradeCancelClick: PropTypes.func.isRequired,
  gradeToDelete: PropTypes.number,
};

GradeDelete.defaultProps = {
  saving: false,
};
export default GradeDelete;
