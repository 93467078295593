import { getAccessToken, getAccessTokenBlob } from './auth'

const logging = {
    log (error) { console.log(error)}
}

const responseNoContent = 'No Content'

const authorisedFileFetch = (dataUrl, errorMessage) =>
  getAccessToken()
    .then(accessToken => fetch(dataUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }))
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      if (response.ok && response.statusText !== responseNoContent) {
        return response
      }
      return null
    })
    .catch((error) => {
      logging.log(errorMessage)
      logging.log(error)
      throw error
    })

const authorisedImageFetch = (dataUrl, errorMessage) =>
  getAccessTokenBlob()
  .then(accessToken => 
    fetch(dataUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'x-ms-version': '2019-02-02',
      'x-ms-date': (new Date() ).toGMTString()
    }
  }))
  .then(async  (response) =>
   {
    if (!response.ok) {
      //we know this is coming from blob storage so take the response.stautstext
      throw Error(`Error: ${response.statusText}`);
    }
    if (response.ok && response.statusText !== responseNoContent) {
       return response.arrayBuffer();
    }
    return null
  })
  .catch((error) => {
    logging.log(dataUrl)
    logging.log(errorMessage)
    logging.log(error)
    throw error
  })

const authorisedFetch = (dataUrl, errorMessage) =>
  getAccessToken()
    .then(accessToken => fetch(dataUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }))
    .then(async (response) => {
      if (!response.ok) {
        let err = await response.json();
        throw Error(`Error: ${err.message} Trace Id ${err.traceId}`);
      }
      if (response.ok && response.statusText !== responseNoContent) {
        return response.json()
      }
      return null
    })
    .catch((error) => {
      logging.log(dataUrl)
      logging.log(errorMessage)
      logging.log(error)
      throw error
    })

const authorisedGetViaPost = (dataUrl, bodyData, errorMessage) =>
  // Used when a number of parameters need to be passed so using post to pass parameters in the body rather than on the query string.
  getAccessToken()
    .then(accessToken => fetch(dataUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: bodyData
    }))
    .then(async (response) => {
      if (!response.ok) {
        let err = await response.json();
        throw Error(`Error: ${err.message} Trace Id ${err.traceId}`);
      }
      if (response.statusText !== responseNoContent) {
        return response.json()
      }
      return null
    })
    .catch((error) => {
      logging.log(dataUrl)
      logging.log(errorMessage)
      logging.log(error)
      throw error
    })

const authorisedPost = (dataUrl, data, errorMessage) =>
  getAccessToken()
    .then(accessToken => fetch(dataUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }))
    .then(async (response) => {
      if (!response.ok) {
        let err = await response.json();
        throw Error(`Error: ${err.message} Trace Id ${err.traceId}`);
      }
      if (response.statusText !== responseNoContent) {
        return response.json()
      }
      return null
    })
    .then((jsonResponse) => {
      if (!jsonResponse.successful) {
        throw Error(`Error: ${jsonResponse.statusMessage.message} Trace Id ${jsonResponse.statusMessage.traceId}`);
      }
      if (jsonResponse) {
        return jsonResponse.result
      }
      return null
    })    
    .catch((error) => {
      logging.log(dataUrl)
      logging.log(errorMessage)
      logging.log(error)
      throw error
    })

const authorisedDelete = (dataUrl, errorMessage) =>
  getAccessToken()
    .then(accessToken => fetch(dataUrl, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }))
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText)
      }
      return true
    })
    .catch((error) => {
      logging.log(errorMessage)
      logging.log(error)
      throw error
    })

const fetchBaseApiUrl = () => 
  fetch(process.env.PUBLIC_URL + '/data.json', {
     method: 'GET'
   })
  .then((response) => {
     if (!response.ok) {
         throw Error(response.statusText)
     }
     if (response.ok && response.statusText !== responseNoContent) {
         return response.json()
     }
     return null
  })

const arrayBufferToBase64= (buffer) =>
  {
    var binary='';
    var bytes= [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) =>binary+=String.fromCharCode(b));

    return window.btoa(binary);
  }

const fileDownloadFunctions = {
  getFileName (responseHeaders) {
    let contentDispositionHeader = responseHeaders.get('Content-Disposition');

    let headerSections = contentDispositionHeader.split(';');
    let filenameHeader = headerSections.find(function(element) { return element.includes("filename=")});

    let fileName = filenameHeader.trim().split('=')[1];
    fileName = fileName.replace("\"", "").replace("\"", "");

    return fileName;
  },
  setupAndClickLink (element, blob) {
    element.setAttribute('href', window.URL.createObjectURL(blob))
    element.style.display = ''
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }
}

const api = {

    authorisedImageFetchSync(dataUrl) {
      const errorMessage = 'Error retrieving image'
      return authorisedImageFetch(dataUrl, errorMessage)
    }, 

    getTheBaseApiUrl() {
        return fetchBaseApiUrl()
    },

    getAuth(baseApiUrl) {
      const dataUrl = baseApiUrl + 'Auth'
      const errorMessage = 'Error checking authorisation'
      return authorisedFetch(dataUrl, errorMessage)
    },

    getConfig(baseApiUrl) {
      const dataUrl = baseApiUrl + 'Config'
      const errorMessage = 'Error checking authorisation'  
      return authorisedFetch(dataUrl, errorMessage);
    },

    getSeasons(baseApiUrl) {
        const dataUrl = baseApiUrl + 'RangePlannerItems/seasons'
        const errorMessage = 'Error checking authorisation'     

        return authorisedFetch(dataUrl, errorMessage)
    },

    getGroups(baseApiUrl) {
      const dataUrl = baseApiUrl + 'RangePlannerItems/groups'
      const errorMessage = 'Error checking authorisation'     

      return authorisedFetch(dataUrl, errorMessage)
  },

    getSeasonsDepartments(baseApiUrl, seasons, groups) {
      const dataUrl = baseApiUrl + 'RangePlannerItems/seasonsdepartments'
      const errorMessage = 'Error checking authorisation'        
      const payload = {}
      payload.seasons = seasons
      payload.groups = groups

      return authorisedGetViaPost(dataUrl, JSON.stringify(payload), errorMessage)
    },

    getRangePlannerItemsForNewProjectAndSeasons(baseApiUrl, seasons, departments) {
      const dataUrl = baseApiUrl + 'RangePlannerItems/getnewseasonprojectitems'
      const errorMessage = 'Error checking authorisation'        
      const payload = {}
      payload.seasons = seasons
      payload.departments = departments

      return authorisedGetViaPost(dataUrl, JSON.stringify(payload), errorMessage)
    },

    deleteProject(baseApiUrl, projectId) {
      const dataUrl = baseApiUrl + `Projects/${projectId}`
      const errorMessage = 'Error deleting project'        

      return authorisedDelete(dataUrl, errorMessage)
    },

    postNewProject (baseApiUrl, project, cloneId) {
      const dataUrl = baseApiUrl + `Projects/SaveNew/${cloneId}`
      const errorMessage = 'Error checking authorisation'        

      return authorisedPost(dataUrl, project, errorMessage)
    },

    getProjectItemsAll(baseApiUrl) {
      const dataUrl = baseApiUrl + 'Projects'
      const errorMessage = 'Error checking authorisation'  

      return authorisedFetch(dataUrl, errorMessage)
    },

    getImageLibraryForProject(baseApiUrl, projectId) {
      const dataUrl = baseApiUrl + `Projects/${projectId}/ProjectImageLibrary`
      const errorMessage = 'Error checking authorisation'  

      return authorisedFetch(dataUrl, errorMessage)
    },

    getProjectGrades(baseApiUrl, projectId) {
      const dataUrl = baseApiUrl + `Projects/${projectId}/grades`
      const errorMessage = 'Error retrieving grades'  

      return authorisedFetch(dataUrl, errorMessage)
    },

    createProjectGrade(baseApiUrl, projectId, gradeId, newGradeNumber) {
      const dataUrl = baseApiUrl + `projects/${projectId}/grades/${gradeId}/copytonew/${newGradeNumber}`
      const errorMessage = 'Error creating grade'
      
      return authorisedPost(dataUrl, null, errorMessage)
    },

    createEmptyProjectGrade(baseApiUrl, projectId, newGradeNumber) {
      const dataUrl = baseApiUrl + `projects/${projectId}/grades/createnew/${newGradeNumber}`
      const errorMessage = 'Error creating grade'
      
      return authorisedPost(dataUrl, null, errorMessage)
    },

    getProjectGradeRanges(baseApiUrl, projectId, gradeNumber, getImages = true) {
      const dataUrl = baseApiUrl + `projects/${projectId}/ranges/${gradeNumber}/${getImages}`
      const errorMessage = 'Error getting grade ranges for project'  

      return authorisedFetch(dataUrl, errorMessage)
    },

    postProjectGradeRange(baseApiUrl, projectId, gradeNumber, rangeDetails) {
      const dataUrl = baseApiUrl + `projects/${projectId}/ranges/${gradeNumber}`
      const errorMessage = 'Error saving grade range for project'
      
      return authorisedPost(dataUrl, rangeDetails, errorMessage)
    },

    postProjectGradeRangeRename(baseApiUrl, projectId, gradeNumber, rangeId, gradeRangeRenameVm) {
      const dataUrl = baseApiUrl + `projects/${projectId}/ranges/${gradeNumber}/${rangeId}/rename`
      const errorMessage = 'Error renaming grade range name'
      
      return authorisedPost(dataUrl, gradeRangeRenameVm, errorMessage)
    },

    deleteProjectGrade(baseApiUrl, projectId, gradeId) {
      const dataUrl = baseApiUrl + `projects/${projectId}/grades/delete/${gradeId}`
      const errorMessage = 'Error deleting grade from project'  
      return authorisedDelete(dataUrl, errorMessage)
    },

    deleteProjectGradeRange(baseApiUrl, projectId, gradeNumber, rangeId) {
      const dataUrl = baseApiUrl + `projects/${projectId}/ranges/${gradeNumber}/${rangeId}`
      const errorMessage = 'Error deleting grade range from project'  

      return authorisedDelete(dataUrl, rangeId, errorMessage)
    },

    getGondolasForRangeGrade(baseApiUrl, projectId, rangeId)
    {
      const dataUrl = baseApiUrl + `projects/${projectId}/graderanges/${rangeId}/gondolas`
      const errorMessage = 'Error getting gondolas for project'  
      return authorisedFetch(dataUrl, errorMessage)
    },

    getGondola(baseApiUrl, gondolaId)
    {
      const dataUrl = baseApiUrl + `projects/0/graderanges/0/Gondolas/getgondola/${gondolaId}`
      const errorMessage = 'Error getting single gondola'  
      return authorisedFetch(dataUrl, errorMessage)    
    },
    
    saveGondolasForGrade(baseApiUrl, projectId, gradeNumber, gradeRangeGondolaModels)
    {
      const dataUrl = baseApiUrl + `projects/${projectId}/ranges/${gradeNumber}/gondolas`
      const errorMessage = 'Error saving gondolas for grade'  
      return authorisedPost(dataUrl, gradeRangeGondolaModels, errorMessage)
    },

    getImageFromBlob(imgUrl)
    {
      return api.authorisedImageFetchSync(imgUrl)
      .then((buffer) => {
        var base64Flag = 'data:image/jpeg;base64,';
        var imageStr = arrayBufferToBase64(buffer);
        return base64Flag + imageStr;
      });
    },

    async getImageFromBlobAsync(imgUrl)
    {
      let p = await api.authorisedImageFetchSync(imgUrl)
      var base64Flag = 'data:image/jpeg;base64,';
      var imageStr = arrayBufferToBase64(p.result);
      return base64Flag + imageStr 
    },

    getProjectPickList(baseApiUrl, projectId)
    {
      const dataUrl = baseApiUrl + `projects/${projectId}/ProjectPickList`
      const errorMessage = 'Error getting pick list'
      return authorisedFileFetch(dataUrl, errorMessage)
    },

    getExportRangeGondolaImage(baseApiUrl, projectId, gradeNumber, rangeId, gondolaId, rangeNumber, gondolaNumber) {
      const dataUrl = baseApiUrl + `projects/${projectId}/ranges/${gradeNumber}/${rangeId}/gondola/${gondolaId}/exportimage/${rangeNumber}/${gondolaNumber}`
      const errorMessage = 'Error getting gondola image'
      return authorisedFileFetch(dataUrl, errorMessage)
    },

    updateProjectImages(baseApiUrl, projectId) {
      const dataUrl = baseApiUrl + `projects/${projectId}/updateprojectimages`
      console.log(dataUrl)
      const errorMessage = 'Error refreshing images for project'
      return authorisedPost(dataUrl, errorMessage)
    },

    getNewRangePlannerItems(baseApiUrl, projectId, style, colour) {
      const dataUrl = baseApiUrl + `projects/${projectId}/getnewitem/${style}/${colour}`
      const errorMessage = 'Error getting new range planner items - Manage Project'
      return authorisedFetch(dataUrl, errorMessage)
    },

    saveNewRangePlannerItems(baseApiUrl, projectId, rangePlannerItemsModels) {
      const dataUrl= baseApiUrl + `projects/${projectId}/items`
      const errorMessage = `Error saving new range planner items to project (${projectId}) - Manage Project`
      return authorisedPost(dataUrl, rangePlannerItemsModels, errorMessage)
    },

    getProjectInformation(baseApiUrl, projectId) {
      const dataUrl = baseApiUrl + `projects/${projectId}/getprojectinformation`
      console.log("hi" + dataUrl)
      const errorMessage = `Error getting information for project (${projectId})`
      return authorisedFetch(dataUrl, errorMessage)
    },

    handleError(myHistory,  errMsg) {
      if (myHistory != null) 
      {
        myHistory.push({ pathname: '/error', state: { errorMessage: errMsg} });
      }
    }

}

export { api, fileDownloadFunctions }