function getFilteredData(rangePlannerData, filters){
	return rangePlannerData.filter(item => 
		(filters.styleFilter === "" || filters.styleFilter === item.style) &&
		(filters.departmentFilter === "" || filters.departmentFilter === item.department) &&
		(filters.collectionFilter === "" || filters.collectionFilter === item.collection) &&
		(filters.classFilter === "" || filters.classFilter === item.class) &&
		(filters.colourFilter === "" || filters.colourFilter === item.colour) &&
		(filters.gradeFilter === "" || parseInt(filters.gradeFilter) <= item.grade))
}

function getUsedImageFilteredData(filteredData, filters){
  let usedImageFilteredData = filteredData.filter(item =>
		filters.usedImageFilter === '' ||
		filters.usedImageFilter === 'all' || 
		(filters.usedImageFilter === 'used' && item.savedUsedImageCount > 0) || 
		(filters.usedImageFilter === 'unused' && item.savedUsedImageCount === 0)
  )
            
  return usedImageFilteredData.sort(
    function(item1, item2) {
      return (item1.grade - item2.grade) || (item1.rangeId - item2.rangeId)
		}
	)
}

export { getFilteredData, getUsedImageFilteredData }