import React from 'react'
import { PropTypes } from 'prop-types'
import { CheckboxField } from '@jsluna/react'

function CheckboxMultiSelect(props) {
  if (props.options == null || props.options.length === 0) {
    return null
  }

  return (
    <CheckboxField
      label={props.label}
      name="checkbox-multi-select"
      className="ln-o-generic--break-word c-checkbox-multi-select-error"
      error={props.errorMessage}
      options={props.options}
      onChange={e => props.onChange(e)}
    />
  )
}

CheckboxMultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired
  })),
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}


export default CheckboxMultiSelect
