import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { SelectField, IconButton } from "@jsluna/react";
import { Plus } from '@jsluna/icons'

class RangeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeId: 0
    };
  }

  rangesList = newRanges => {
    const rangeList = []
    newRanges.map((range) => (
      rangeList.push({ label: range.name, value: range.id.toString() })
    ))
    return rangeList
  }

  onChangeSelector = e => {
    e.preventDefault();
    const newValue = e.target.value;
    if(newValue) {
      this.setState({ rangeId: newValue });
    } else {
      this.setState({ rangeId: 0 });
    }
  }

  handleAddRangeClick = e => {
    e.preventDefault();
    this.props.onAddRangeClick(this.state.rangeId)
  }

  render() {
    return (
      <div className="choose-range-selector">
        <div className="range-selector-add-content">
          <label
            data-testid="choose-range-label"
            htmlFor={'chooseRangeSelector'}
            className="ln-c-label"
          >
            Choose range
          </label>
          <p data-testid="select-another-range-description">
            Select another range for a split screen view to move items from one range to another
          </p>
          <div className="range-selector-flexbox">
            <SelectField
              className="ln-c-select--select-field"
              name="chooseRangeSelector"
              hideLabel
              placeholder={"Choose a range"}
              options={this.rangesList(this.props.ranges)}
              onChange={(e) => this.onChangeSelector(e)}
            />
            <IconButton variant="filled" label="Add range" disabled={!this.state.rangeId} onClick={this.handleAddRangeClick} className="ln-c-button--button-field">
              <Plus />
            </IconButton>
          </div>
        </div>
      </div>
    );
  }
}

RangeSelector.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired
}

export default RangeSelector