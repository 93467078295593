import * as PIXI from 'pixi.js'

export default class HoverButton extends PIXI.Sprite
{

  constructor(onClick, normalImg, hoverImg, disabledImg) {
    super(PIXI.Texture.from(normalImg))
    this.type = HoverButton.typeIdentifier
    this.handleOnClick = onClick 
    this.disabledTexture = disabledImg ? PIXI.Texture.from(disabledImg) : null
    this.normalTexture = normalImg ? PIXI.Texture.from(normalImg) : null
    this.hoverTexture  = hoverImg ? PIXI.Texture.from(hoverImg) : null
    this.pointerdown = this.onClick
    this.pointerover = this.onHover
    this.pointerout = this.onUnhoveredHover
    this.interactive = true
    this.cursor = 'pointer'
    this.enabled = true
  }

  onClick(e) {
    // Only pass on click events if the button is enabled and the left mouse button has bee clicked
    if (this.enabled === true && e.data.button === 0) {
      this.handleOnClick(e)    
    }
  }

  onHover(e) {
    if (this.enabled === true) {
      this.texture = this.hoverTexture
    } else {
      this.texture = this.disabledTexture
    }
  }

  onUnhoveredHover(e) {
    if (this.enabled === true) {
      this.texture = this.normalTexture
    } else {
      this.texture = this.disabledTexture
    }
  }

  disable(){
    this.cursor = 'default'
    this.enabled = false
    this.texture = this.disabledTexture
  }

  enable(){
    this.cursor = 'pointer'
    this.enabled = true
    this.texture = this.normalTexture
  }

  static typeIdentifier = "HoverButton"
}
