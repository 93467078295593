import React from "react";
import { PropTypes } from "prop-types";
import {
  ButtonGroupWrapper,
  FilledButton,
  ButtonGroupPrimary,
  OutlinedButton,
  ModalHeading,
  Modal,
  Card
} from "@jsluna/react";

function NavigateAwayModal(props) {
  return (
    <Modal data-testid="navigate-away-modal" restrictClose open={props.visible} className={"navigate-away-modal"}>
      <Card className={"navigate-away-modal-body"}>
        <ModalHeading data-testid="modal-heading" className={"modalheading"}>Save changes?</ModalHeading>
        <p data-testid="navigate-away-modal-description" className={"navigate-away-modal-description"}>
          Your unsaved changes will be lost if you exit the range builder now. Are you sure you want to leave?
        </p>
        <ButtonGroupWrapper actionBar className={"navigate-away-modal-button-wrapper"}>
          <ButtonGroupPrimary>
            <FilledButton data-testid="stay-button" className={"navigate-away-modal-stay-button"} onClick={e => props.onCancel(e.preventDefault())}>
              Stay and save
            </FilledButton>
            <OutlinedButton data-testid="leave-button" color="dark" className={"navigate-away-modal-leave-button"} onClick={props.onExit}>
              Leave anyway
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Card>
    </Modal>
  );
}

NavigateAwayModal.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default NavigateAwayModal;