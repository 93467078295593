import { actions } from './actions'

const createProjectPeriodIntialState = {
  selectedPeriod: null
}

const createProjectPeriod = (state = createProjectPeriodIntialState, action) => {
  switch (action.type) {
    case actions.set:
      return Object.assign({}, action.createProjectPeriod)
    default:
      return state
  }
}

export default createProjectPeriod
