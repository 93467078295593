import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const buildStore = (history) =>
  createStore(
    rootReducer(history),
    composeEnhancers(
      applyMiddleware()
      // routerMiddleware(history), // for dispatching history actions
      // ... other middlewares ...
    )
  );

export default buildStore;
