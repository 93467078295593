import {getDropdownValues} from '../../utils/ImageLibrary/DropdownHelper/ImageLibraryDropdownHelper';
import { getFilteredData, getUsedImageFilteredData } from '../../utils/ImageLibrary/FilteringHelper/ImageLibraryFilteringHelper'
import { actions } from './actions'
import { getActiveFilters } from '../../utils/ImageLibrary/ActiveFilterParser.js/ActiveFilterParser';

var defaultState = {
    isDataLoaded: false,
    styleFilter: "",
    currentStyleInputValue: "",
    rangePlannerData: [],
    filteredResultData: [],
    departmentFilter: "",
    collectionFilter: "",
    gradeFilter: "",
    colourFilter: "",
    classFilter: "",
    usedImageFilter: "",
    isFilterPanelOpen: false,
    dataLoadingError: null,
    activeFiltersCount: 0,
    disableDropdowns: false
};

 export function imageLibraryData(state=defaultState, action){

    var currentFilters = {
        styleFilter: state.styleFilter,
        departmentFilter: state.departmentFilter,
        collectionFilter: state.collectionFilter,
        gradeFilter: state.gradeFilter,
        classFilter: state.classFilter,
        colourFilter: state.colourFilter,
        usedImageFilter: state.usedImageFilter
    };

    switch (action.type){
        case actions.TOGGLE_FILTER_PANEL: {
            return {
                ...state,
                isFilterPanelOpen: !state.isFilterPanelOpen
            }
        }

        case actions.DISABLE_DROPDOWNS:{
            return {
                ...state,
                disableDropdowns: action.data
            }
        }

        case actions.SET_STYLE_FILTER: {
            let newFilters = {...currentFilters, styleFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state, 
                styleFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData), 
                activeFiltersCount: getActiveFilters(newFilters)              
            }
        }
        case actions.SET_CURRENT_STYLE_INPUT_VALUE: {
            return {
                ...state, 
                currentStyleInputValue: action.data,
            }
        }
        case actions.SET_RANGEPLANNER_DATA: {
            return {
                ...defaultState, 
                rangePlannerData: action.data, 
                filteredResultData: action.data,
                isDataLoaded: true,
                dropdownValues: getDropdownValues(action.data, currentFilters, action.data)
            }
        }
        case actions.UPDATE_RANGE_PLANNER_DATA: {
            const filteredData = getFilteredData(action.data, currentFilters)
            return {
                ...state,
                rangePlannerData: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, currentFilters),
                dropdownValues: getDropdownValues(action.data, currentFilters, filteredData)
            }
        }
        case actions.SET_DEPARTMENT_FILTER: {
            let newFilters = {...currentFilters, departmentFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state,
                departmentFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData), 
                activeFiltersCount: getActiveFilters(newFilters)     
            }
        }
        case actions.SET_COLLECTION_FILTER:{
            let newFilters = {...currentFilters, collectionFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state,
                collectionFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData), 
                activeFiltersCount: getActiveFilters(newFilters)     
            }
        }
        case actions.SET_GRADE_FILTER: {
            let newFilters = {...currentFilters, gradeFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state,
                gradeFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData), 
                activeFiltersCount: getActiveFilters(newFilters)     
            }
        }
        case actions.SET_CLASS_FILTER: {
            let newFilters = {...currentFilters, classFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state,
                classFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData),  
                activeFiltersCount: getActiveFilters(newFilters)     
            }
        }
        case actions.SET_COLOUR_FILTER: {
            let newFilters = {...currentFilters, colourFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state,
                colourFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData), 
                activeFiltersCount: getActiveFilters(newFilters)     
            }
        }
        case actions.SET_USED_IMAGE_FILTER: {
            let newFilters = {...currentFilters, usedImageFilter: action.data};
            const filteredData = getFilteredData(state.rangePlannerData, newFilters)
            return {
                ...state,
                usedImageFilter: action.data,
                filteredResultData: getUsedImageFilteredData(filteredData, newFilters),
                dropdownValues: getDropdownValues(state.rangePlannerData, newFilters, filteredData)
            }
        }
        default:
            return state;
    }
}

export default imageLibraryData
