const breadcrumbHelper = {
    createItem(name, href, onClick) {
      return {
        name,
        href,
        onClick
      }
    },
  
    createBayBuilderItems(projectId, projectName, gradeId, rangeId, rangeName, splitScreen, onClick) {
      const breadcrumbItems = [
        this.createItem('Projects', '/projects'),
        this.createItem(projectName, projectId ? `/projects/${projectId}/view` : null),
        this.createItem(rangeName, splitScreen === true ? `/projects/${projectId}/grades/${gradeId}/ranges/${rangeId}/edit` : null, splitScreen === true ? onClick : null)
      ]
      if (splitScreen === true) {
        breadcrumbItems.push(this.createItem('Split screen', null))
      }
      return breadcrumbItems
    },

    createEmptyGradeOne(projectName) {
      const breadcrumbItems = [
        this.createItem('Projects', '/projects'),
        this.createItem(projectName, null)
      ]
      return breadcrumbItems
    }
  }
  
  export { breadcrumbHelper as default }
  
  