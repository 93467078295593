import React from "react";
import ReactRouterPrompt from "react-router-prompt";
import NavigateAwayModal from "../NavigateAwayModal";

class NavigateAwayGuard extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  };

  showModal = (location) =>
    this.setState({
      modalVisible: true,
      lastLocation: location,
    });

  closeModal = (callback) =>
    this.setState(
      {
        modalVisible: false,
      },
      callback
    );

  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state;
    const { shouldBlockNavigation } = this.props;
    if (!confirmedNavigation && shouldBlockNavigation()) {
      this.showModal(nextLocation);
      return false;
    }

    return true;
  };

  handleConfirmNavigationClick = () =>
    this.closeModal(() => {
      const { navigate } = this.props;
      const { lastLocation } = this.state;
      if (lastLocation) {
        this.setState(
          {
            confirmedNavigation: true,
          },
          () => {
            // Navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
          }
        );
      }
    });

  render() {
    const { when } = this.props;
    const { modalVisible } = this.state;
    return (
      <>
        {/* <ReactRouterPrompt when={when} message={this.handleBlockedNavigation} /> */}
        <NavigateAwayModal
          visible={modalVisible}
          onCancel={this.closeModal}
          onExit={this.handleConfirmNavigationClick}
        />
      </>
    );
  }
}

export default NavigateAwayGuard;
