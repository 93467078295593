import * as PIXI from 'pixi.js'
import HoverButton from './HoverButton'

export default class ItemHoverButton extends HoverButton
{
  constructor(onClick, normalImg, normalHoverImg, disabledImg, selectedImg, selectedHoverImg) {
    super(onClick, normalImg, normalHoverImg, disabledImg)
    this.type = ItemHoverButton.typeIdentifier
    this.selectedTexture = selectedImg ? PIXI.Texture.from(selectedImg) : null
    this.selectedHoverTexture = selectedHoverImg ? PIXI.Texture.from(selectedHoverImg) : null
    this.selected = false
  }

  onHover(e) {
    if (this.enabled === true) {
      if (this.selected) {
        this.texture = this.selectedHoverTexture
      } else {
        super.onHover()
      }
    }
  }

  onUnhoveredHover(e) {
    if (this.enabled === true) {
      if (this.selected) {
        this.texture = this.selectedTexture
      } else {
        super.onUnhoveredHover()
      }
    }
  }

  setSelected(selected) {
    this.selected = selected
    if (this.selected) {
      this.texture = this.selectedTexture
    } else {
      this.texture = this.normalTexture
    }
  }

  static typeIdentifier = "ItemHoverButton"
}
