import React, { Component } from 'react';
import { SelectField } from '@jsluna/react'
import {connect} from 'react-redux';
import {setCollectionFilter} from '../../../store/imagelibraryfilters/actions';

class CollectionFilter extends Component {  
  constructor(props){
    super(props);
    this.changeCollectionFilter = this.changeCollectionFilter.bind(this);
  }

  changeCollectionFilter(e){
    e.preventDefault();
    const newValue = e.target.value;
    this.props.dispatch(setCollectionFilter(newValue))
  }


    render() {
      return (
          <SelectField 
            name="select-collections" 
            label="Collection (story)" 
            placeholder={" "} 
            disabled={this.props.disableDropdowns}
            options={this.props.options}
            value={this.props.selectedValue}
            id="CollectionFilter"
            onChange={(e) => this.changeCollectionFilter(e)}
            /> 
      );
    }
  }


  
  export default connect((state,props) =>{
    return {
      options: state.imageLibraryData.dropdownValues.collections || [],
      selectedValue: state.imageLibraryData.collectionFilter || "",
      disableDropdowns: state.imageLibraryData.disableDropdowns
    }})(CollectionFilter);