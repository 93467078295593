import { actions } from './actions'

const settingsIntialState = {
  baseApiUrl: null
}

const settings = (state = settingsIntialState, action) => {
  switch (action.type) {
    case actions.set:
      return Object.assign({}, action.baseApiUrl)
    default:
      return state
  }
}

export default settings
