import { PropTypes } from "prop-types";
import React from "react";
import { withRouter } from "../../../utils/reactRouterDom";
import ApplicationError from "../ApplicationError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
    this.handleGoBackButtonClick = this.handleGoBackButtonClick.bind(this);
    this.navigate = this.props.router.navigate;
  }

  componentDidCatch(error) {
    this.setState({
      error,
    });
  }

  handleGoBackButtonClick() {
    this.setState(
      {
        error: null,
      },
      this.navigate("/")
    );
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <ApplicationError
          fullscreen
          onGoBackButtonClick={this.handleGoBackButtonClick}
        >
          <p>It looks like something has gone wrong</p>
        </ApplicationError>
      );
    }
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ErrorBoundary);
