import { ProgressSpinner } from "@jsluna/react";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { api } from "../../../../api/api";
import bayImage1view from "../../../../assets/images/viewmode/gondola-01.png";
import bayImage2view from "../../../../assets/images/viewmode/gondola-02.png";
import bayImage3view from "../../../../assets/images/viewmode/gondola-03.png";
import bayImage4view from "../../../../assets/images/viewmode/gondola-04.png";
import bayImage5view from "../../../../assets/images/viewmode/gondola-05.png";
import bayImage6view from "../../../../assets/images/viewmode/gondola-06.png";
import bayImage7view from "../../../../assets/images/viewmode/gondola-07.png";

class RangeGondolaView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: null,
      errorCount: 0,
    };
  }

  componentDidMount() {
    if (this.props.gondola.hasImage) {
      // Pre-rendered image from the gondola itself
      this.setState({
        imageUrl: "data:image/png;base64," + this.props.gondola.image,
      });
    }
    if (!this.props.gondola.hasItems) {
      // No items, so just a blank gondola image
      this.setState({
        imageUrl: this.getGondolaImage(this.props.gondola.bayCount),
      });
    }
    if (this.props.gondola.hasItems && !this.props.gondola.hasImage) {
      // Items but no image yet, need to start pooling to find it
      this.timer = setTimeout(() => this.getItems(), 1000);
    }

    const canvas = this.canvasTarget;
    canvas.width = 2090;
    canvas.height = 503;
    const ctx = canvas.getContext("2d");
    const img = this.imageSource;
    canvas.style.width = "100%";
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  getItems() {
    api
      .getGondola(this.props.settings.baseApiUrl, this.props.gondola.id)
      .then((data) => {
        if (data.hasImage) {
          this.setState({ imageUrl: "data:image/png;base64," + data.image });
        } else {
          this.timer = setTimeout(() => this.getItems(), 1000);
        }
      })
      .catch((error) => {
        // Back off , the API might not be running?
        let errorCount = this.state.errorCount;
        errorCount++;
        this.setState({ errorCount: errorCount });

        if (errorCount <= 5) {
          this.timer = setTimeout(() => this.getItems(), 5000 * errorCount);
        }
      });
  }

  getGondolaImage = (bayCount) => {
    switch (bayCount) {
      case 1:
        return bayImage1view;
      case 2:
        return bayImage2view;
      case 3:
        return bayImage3view;
      case 4:
        return bayImage4view;
      case 5:
        return bayImage5view;
      case 6:
        return bayImage6view;
      case 7:
        return bayImage7view;
      default:
        return null;
    }
  };

  renderBayNumbers(start, end) {
    const bayNumbers = [];
    for (var b = start; b <= end; b++) {
      bayNumbers.push(
        <div className="project-range-bay-label" key={b}>
          Bay {b}
        </div>
      );
    }
    return bayNumbers;
  }

  renderSpinner() {
    if (this.state.imageUrl == null) {
      return <ProgressSpinner className="project-range-bay-image-spinner" />;
    } else {
      return;
    }
  }

  render() {
    return (
      <div className="project-range-gondola" key={this.props.gondola.id}>
        <div className="project-range-gondola-graphics">
          <canvas
            ref={(canvasTarget) => (this.canvasTarget = canvasTarget)}
            className="project-range-bay-image"
          ></canvas>
          <div className="project-range-bay-image-spinnerdiv">
            {this.renderSpinner()}
            <img
              ref={(imageSource) => (this.imageSource = imageSource)}
              src={this.state.imageUrl}
              alt="gondola"
              className="project-range-bay-image-hide"
            ></img>
          </div>
        </div>
        {this.renderBayNumbers(
          this.props.bayIndex,
          this.props.bayIndex + this.props.gondola.bayCount - 1
        )}
      </div>
    );
  }
}

RangeGondolaView.propTypes = {
  settings: PropTypes.shape({
    baseApiUrl: PropTypes.string.isRequired,
  }).isRequired,
  projectId: PropTypes.number.isRequired,
  gondola: PropTypes.shape({
    id: PropTypes.number.isRequired,
    bayCount: PropTypes.number.isRequired,
    hasItems: PropTypes.bool.isRequired,
    hasImage: PropTypes.bool.isRequired,
  }).isRequired,
  bayIndex: PropTypes.number.isRequired,
};

export default RangeGondolaView;
