import { actions } from './actions'
import Constants from '../../utils/constants'

const menuIntialState = {
  selectedMenu: Constants.MenuOptions.projects,
  optionalMenuItems: [],
  hideMenu: false
}

const menu = (state = menuIntialState, action) => {
  switch (action.type) {
    case actions.set:
      return Object.assign(
        {}, 
        { selectedMenu: action.menu.selectedMenu ? action.menu.selectedMenu: '' }, 
        { optionalMenuItems: action.menu.optionalMenuItems ? [...action.menu.optionalMenuItems] : [] }, 
        { hideMenu: action.menu.hideMenu != null ? action.menu.hideMenu : false })
    default:
      return state
  }
}

export default menu
