import React, { Component } from "react";
import { withRouter } from "../../utils/reactRouterDom";
import ApplicationError from "../Controls/ApplicationError";
import Constants from "../../utils/constants";

class Error extends Component {
  constructor(props) {
    super(props);
    const errMsg =
      this.props && this.props.location.state
        ? this.props.location.state.errorMessage
        : null;
    this.state = { errorMessage: errMsg, fullscreen: props.fullscreen };
  }

  componentDidMount() {
    this.props.setMenu({ selectedMenu: Constants.MenuOptions.none });
  }

  render() {
    const msg = this.state.errorMessage ? `${this.state.errorMessage}` : "";
    const full = this.state.fullscreen;
    return (
      <ApplicationError fullscreen={full}>
        <p>
          <span className="ln-application-error-date-time-display">
            It looks like something has gone wrong.
          </span>
        </p>
        <p>
          <span className="ln-application-error-date-time-display">{msg}</span>
        </p>
      </ApplicationError>
    );
  }
}

export default withRouter(Error);
