import { connect } from 'react-redux'
import Error from './Error'
import { setMenu } from '../../store/menu/actions'

const mapDispatchToProps = dispatch => ({
  setMenu: menu => {
    dispatch(setMenu(menu))
  }
})

export default connect(null, mapDispatchToProps)(Error)