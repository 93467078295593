import React, {Component} from 'react';
import { OutlinedButton } from '@jsluna/react';

class PeriodHeaderGroupComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            periodId: props.periodId,
            expandState: 'collapsed',
            selected: false,
            expanded: null
        };

        props.columnGroup.getProvidedColumnGroup().addEventListener('expandedChanged', this.onExpandChanged.bind(this));

        props.api.addEventListener('selectedChanged', this.syncSelectedButtons.bind(this));
    }

    componentDidMount() {
        this.onExpandChanged();
    }

    render() {
        let openHidden = this.state.expanded ? " ag-hidden" : "";
        let closedHidden = this.state.expanded ? "" : " ag-hidden";

        return (
            <div className="ag-header-group-cell-label">
                <span ref="agLabel" className="ag-header-group-text">{this.props.displayName}</span>

                <span ref="agOpened" className={"ag-header-icon ag-header-expand-icon ag-header-expand-icon-expanded" + openHidden}>
                    <span className="ag-icon ag-icon-expanded" unselectable="on" onClick={this.expandOrCollapse.bind(this)}></span></span>
                <span ref="agClosed" className={"ag-header-icon ag-header-expand-icon ag-header-expand-icon-collapsed" + closedHidden}>
                    <span className="ag-icon ag-icon-contracted" unselectable="on" onClick={this.expandOrCollapse.bind(this)}></span></span>

                <div>
                    {!this.state.selected &&
                        <OutlinedButton className="ln-c-grid-header-button ln-c-unselected" onClick={e => this.props.handleSelectClick()}>
                            Choose
                        </OutlinedButton>
                    }
                    {this.state.selected &&
                        <div className="ln-c-button ln-c-button--outlined ln-c-grid-header-button" >✓</div>
                    }
                </div>
            </div>

        );
    }

    expandOrCollapse() {
        let currentState = this.props.columnGroup.getOriginalColumnGroup().isExpanded();
        this.props.setExpanded(!currentState);

        this.props.setExpanded(!this.state.expanded);
    };

    onExpandChanged() {
        this.setState({
            expanded: this.props.columnGroup.getProvidedColumnGroup().isExpanded()
        })
    }

    syncSelectedButtons(parameters) {
        this.setState({
            selected: this.state.periodId === parameters.periodId
        });
    }    
}

export default PeriodHeaderGroupComponent