import React from 'react'
import { PropTypes } from 'prop-types'
import { Button, OutlinedButton, SelectField, ProgressSpinner } from '@jsluna/react'

function GradeAdd(props) {
  const createExistingGradesList = existingGrades => {
    const gradeList = []
    existingGrades.map((grade) => (
      gradeList.push({ label: `Grade ${grade.gradeNumber}`, value: grade.gradeId.toString() })
    ))
    return gradeList
  }

  const createNewGradesList = newGrades => {
    const gradeList = []
    newGrades.map((grade) => (
      gradeList.push({ label: `Grade ${grade}`, value: grade.toString() })
    ))
    return gradeList
  }
  
  return (
    <div className="grade-add"> 
      <div className="grade-add-content">
        <h4>Add grade</h4>
        <SelectField 
          name="newGrade"
          label="Choose new grade"
          className="grade-add-new-grade-select"
          placeholder={false} 
          options={createNewGradesList(props.newGrades)}
          value={props.selectedNewGradeNumber}
          onChange={(e) => props.onChangeNewGrade(e)}
        />
        <label
          data-testid="duplicate-grade-label"
          htmlFor={'duplicateGrade'}
          className="ln-c-label"
        >
          Duplicate from
        </label>
        <p data-testid="duplicate-description">
          Only clothing relevant to the new grade will be duplicated
        </p>
        <SelectField 
          name="duplicateGrade" 
          hideLabel
          placeholder={"None"} 
          options={createExistingGradesList(props.existingGrades)}
          value={props.selectedExistingGradeId}
          error={props.existingGradeError}
          onChange={(e) => props.onChangeExistingGrade(e)}
        />
        { props.saving === true &&
          <Button variant="filled" className="grade-add-save-button" disabled={true} >
            Adding ...
            <ProgressSpinner className="ln-u-push-right-sm grade-add-save-button--progress-spinner" color="light" />
          </Button>
        }
        { props.saving === false &&
          <Button variant="filled" className="grade-add-save-button" onClick={props.onCreateGradeClick} >
            Add
          </Button>
        }
        <OutlinedButton color="dark" onClick={props.onCreateGradeCancelClick} disabled={props.saving === true} >
          Cancel
        </OutlinedButton>
      </div>
    </div>
  )
}

GradeAdd.propTypes = {
  newGrades: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  selectedNewGradeNumber: PropTypes.string.isRequired,
  onChangeNewGrade: PropTypes.func.isRequired,
  existingGrades: PropTypes.arrayOf(
    PropTypes.shape({
      gradeId: PropTypes.number.isRequired,
      gradeNumber: PropTypes.number.isRequired
    })).isRequired,
  selectedExistingGradeId: PropTypes.string.isRequired,
  onChangeExistingGrade: PropTypes.func.isRequired,
  onCreateGradeClick: PropTypes.func.isRequired,
  onCreateGradeCancelClick: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  existingGradeError: PropTypes.string
}

GradeAdd.defaultProps = {
  saving: false,
  existingGradeError: ''
}

export default GradeAdd