import { connect } from 'react-redux'
import { setCreateProjectPeriod } from '../../../store/createProjectPeriod/actions'
import { setMenu } from '../../../store/menu/actions'
import CreateProject from './CreateProject'

const mapStateToProps = state => ({
  selected: state.createProjectPeriod,
  settings: state.settings,
  projectToClone: state.projectToClone
})

const mapDispatchToProps = dispatch => ({
  setCreateProjectPeriod: (periodIdForbutton) => {
    dispatch(setCreateProjectPeriod(periodIdForbutton))
  },
  setMenu: menu => {
    dispatch(setMenu(menu))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateProject)
