import { actions } from './actions'

const projectToClone = (state = {}, action) => {
  switch (action.type) {
    case actions.set:
      return action.projectToClone
    default:
      return state
  }
}

export default projectToClone
