import { connect } from 'react-redux'
import MainMenuButton from './MainMenuButton'
import { setMenu } from '../../../store/menu/actions'

const mapStateToProps = state => ({
  selectedMenu: state.menu.selectedMenu || ""
})

const mapDispatchToProps = dispatch => ({
  setMenu: (menu) => {
    dispatch(setMenu(menu))    
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMenuButton)
