import React from 'react'
import { PropTypes } from 'prop-types'
import { Button, ListGroup, ListGroupItem } from '@jsluna/react'

function ProjectRangeList(props) {
  const renderRangeList = ranges => {
    const rangeList = []
    ranges.map((range) => (
      rangeList.push(renderRangeListItem(range))
    ))
    return rangeList
  }

  const renderRangeListItem = projectRange => {
    return (
      <ListGroupItem key={projectRange.id.toString()} active={projectRange.id === props.activeRangeId} onClick={(e) => props.onProjectRangeItemClick(e, projectRange.id) }>
         {projectRange.name}
      </ListGroupItem>
    )
  }

  return (
    <div className="project-range-list">
      <h4 className="project-range-list-title">Ranges</h4>
      <ListGroup data-testid="ranges-list" actionable type="full">
        {renderRangeList(props.ranges)}
      </ListGroup>
      <Button variant="filled" onClick={props.onAddRangeClick}>
        Add range
      </Button>
    </div>
  ) 
}

ProjectRangeList.propTypes = {
  ranges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      gondolas: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          bayCount: PropTypes.number.isRequired
        }).isRequired
      ).isRequired
    }).isRequired
  ).isRequired,
  activeRangeId: PropTypes.number.isRequired,
  onProjectRangeItemClick: PropTypes.func.isRequired,
  onAddRangeClick: PropTypes.func.isRequired
}

export default ProjectRangeList
