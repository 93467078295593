import React from 'react'
import PropTypes from 'prop-types'
import { IdleTimer } from '../Controls/IdleTimer'
import TimeoutModal from './TimeoutModal'
import { logout } from '../../api/auth';

class InactivityTimeout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isTimeoutModalOpen: false
    }

    this.existingOpenModal = null
    this.idleTimer = null
    this.onIdle = this.onIdle.bind(this)
    this.resetTimeout = this.resetTimeout.bind(this)
  }
  onIdle() {
    // This next line is only necessary when a timeout is less than 60 seconds as
    // idleTimer continues to run, re-triggering onIdle() after the countdown modal is open.
    // This should be handled by idleTimer.pause() but this does appear to do anything
    if (this.state.isTimeoutModalOpen) return

    this.existingOpenModal = document.getElementsByClassName('ln-c-modal is-open')[0]
    if (this.existingOpenModal)
    {
      this.existingOpenModal.classList.remove('is-open')
    }
    this.setState({ isTimeoutModalOpen: true })
  }
  
  resetTimeout() {
    if (this.idleTimer) {
      this.idleTimer.reset()
    }
    if (this.existingOpenModal) {
      this.existingOpenModal.classList.add('is-open')
    }

    this.setState({ isTimeoutModalOpen: false })
  }

  render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          timeout={1000 * this.props.timeoutInSeconds}
        >
         <TimeoutModal
          isOpen={this.state.isTimeoutModalOpen}
          confirmFunction={this.resetTimeout}
          cancelFunction={(e) => { if (e) { e.preventDefault() } logout() }}
        />
        </IdleTimer>        
      </React.Fragment>
    )
  }
}

InactivityTimeout.propTypes = {
  timeoutInSeconds: PropTypes.number.isRequired
}

export default InactivityTimeout
