import { actions } from './actions'
import Constants from '../../utils/constants'

const selectedGrade = (state = Constants.DefaultGradeNumber, action) => {
  switch (action.type) {
    case actions.set:
      return action.selectedGrade
    default:
      return state
  }
}

export default selectedGrade
