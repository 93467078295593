import React, { Component } from 'react';
import {ProgressIndicator, ProgressSpinner} from '@jsluna/react';



class LoadingIndicator extends Component{
	render(){
		return(
			<ProgressIndicator className="ln-u-justify-content-center" page loading>
				<ProgressSpinner className="ln-u-push-right-sm" color="light" />
			</ProgressIndicator>
		)
	}
}



export default LoadingIndicator;
