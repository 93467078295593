import React, { Component } from 'react';
import { SearchField, SearchButton } from '@jsluna/react';
import {connect} from 'react-redux';
import { setStyleFilter, setCurrentStyleInputValue, disableDropdowns } from '../../../store/imagelibraryfilters/actions';

class StyleFilter extends Component {
    constructor(props){
        super(props);
        this.searchStyleData = this.searchStyleData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.state = {
          disableSearchBtn: true
        };
        this.styleFilterRegex = /^\d{9}$/
        this.numbersAllowedRegex = /^\d$/
        this.exceptedFilterRegex = /^\D+\s$/
      }
  
    searchStyleData(e) {
      e.preventDefault();
      this.props.dispatch(setStyleFilter(this.props.currentStyleInputValue))
      this.props.dispatch(disableDropdowns(true))
    }

    handleInputChange(e) {
      e.preventDefault();
      const newValue = e.target.value
      const char = newValue.substr(newValue.length-1,newValue.length)
      if(newValue.length > 9){
        this.props.dispatch(setCurrentStyleInputValue(this.props.currentStyleInputValue))
        return
      }
      if(!this.styleFilterRegex.test(newValue)){
        this.setState({ disableSearchBtn: true })
        if (this.exceptedFilterRegex.test(char) || !this.numbersAllowedRegex.test(char)) {
          if (char === '') {
            this.props.dispatch(setCurrentStyleInputValue(char))
          }
          return;
        }
        else if(this.numbersAllowedRegex.test(char)){
          this.props.dispatch(setCurrentStyleInputValue(newValue)) 
        }
      }
      if(this.styleFilterRegex.test(newValue)){
        this.setState({disableSearchBtn: false})
        this.props.dispatch(setCurrentStyleInputValue(newValue)) 
      }
    }

    render() {
      return (
          <SearchField 
            name="stylenumber-searchfield"
            label="Style number"
            hasButton
            value={this.props.currentStyleInputValue}
            onChange={this.handleInputChange}
            button={
              <SearchButton 
                onClick={(e) => this.searchStyleData(e) }
                disabled={this.state.disableSearchBtn}
              /> }
            />           
      );
    }
  }
  
  export default connect((state,props) =>{
    return {
      options: state.imageLibraryData.dropdownValues.styles || [],
      currentStyleInputValue: state.imageLibraryData.currentStyleInputValue || "",
      styleFilter: state.imageLibraryData.styleFilter || "",
    }})(StyleFilter);