import React from 'react'
import { PropTypes } from 'prop-types'
import { Card, Button } from '@jsluna/react'

function EmptyProject(props) {
  return (
      <Card className="empty-project-body">
        <div className="empty-project-body-content">
          <div className="empty-project-body-content-text">
            <h2>You have no ranges in this project</h2>
            <p className="empty-project-body-content-paragraph">Welcome to your new project for {props.projectName}.</p>
            <p className="ln-u-margin-bottom*4">Let's start by adding a new range to Grade 1.</p>
          </div>
          <div>
            <Button variant="filled" className="ln-u-margin-top" onClick={props.onAddRangeClick}>
              Add range
          </Button>
          </div>
        </div>
      </Card>
  )
}

EmptyProject.propTypes = {
  projectName: PropTypes.string.isRequired,
  onAddRangeClick: PropTypes.func.isRequired
}

export default EmptyProject
