import { actions } from './actions'
import Constants from '../../utils/constants'

const maxLibraryImages = (state = Constants.maxLibraryImagesDefault, action) => {
  switch (action.type) {
    case actions.set:
      return action.maxLibraryImages
    default:
      return state
  }
}

export default maxLibraryImages
