import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  Card,
  FilledButton,
  Modal,
  ModalHeading,
  OutlinedButton,
  TextInputField,
} from "@jsluna/react";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import ModalStylingFixForWin32 from "../../../../common/ModalStylingFixForWin32";
import { withRouter } from "../../../../utils/reactRouterDom";

export class EditRangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeName: this.props.range.name,
      disableSaveBtn: false,
    };
    this.handleRangeNameChange = this.handleRangeNameChange.bind(this);
  }

  handleRangeNameChange(rangeNameUpdate) {
    if (rangeNameUpdate.length >= 0 && rangeNameUpdate.length <= 50) {
      let rangeName = rangeNameUpdate;

      const firstChar = rangeNameUpdate.substr(0, 1);
      if (firstChar === " ") {
        return;
      }

      if (!/[^.0,50 0-9a-zA-Z()&'.-]/.test(rangeNameUpdate)) {
        if (rangeNameUpdate.length > 0 && rangeNameUpdate.length <= 50) {
          this.setState({ rangeName: rangeName, disableSaveBtn: false });
        } else {
          this.setState({ disableSaveBtn: true });
          this.setState({ rangeName });
        }
      }
    }
  }

  getRangeName() {
    if (this.state.rangeName) {
      return this.state.rangeName;
    } else {
      return "";
    }
  }

  render = () => {
    return (
      <Modal
        data-testid="edit-range-modal"
        open={true}
        restrictClose
        className={"edit-range-modal"}
      >
        <Card>
          <ModalHeading data-testid="modal-heading" className={"modalheading"}>
            Edit range details
          </ModalHeading>
          <div className="edit-range-name">
            <TextInputField
              name="rangeName"
              label="Range name"
              onChange={(e) =>
                this.handleRangeNameChange(e.currentTarget.value)
              }
              value={this.getRangeName()}
            />
          </div>
          <ButtonGroupWrapper className="edit-range-button-wrapper">
            <ButtonGroupPrimary>
              <OutlinedButton
                data-testid="cancel-button"
                color="dark"
                className={"edit-range-cancel-button"}
                onClick={(e) => this.props.onCancelEditRangeModalClick(e)}
              >
                Cancel
              </OutlinedButton>
              <FilledButton
                data-testid="save-button"
                className={"edit-range-save-button"}
                onClick={(e) =>
                  this.props.onSaveEditRangeModalClick(
                    e,
                    this.state.rangeName,
                    this.props.range
                  )
                }
                disabled={this.state.disableSaveBtn}
              >
                Save
              </FilledButton>
            </ButtonGroupPrimary>
          </ButtonGroupWrapper>
        </Card>
      </Modal>
    );
  };
}

EditRangeModal.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ModalStylingFixForWin32(EditRangeModal));
