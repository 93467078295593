import { connect } from 'react-redux'
import ProjectsLandingPage from './ProjectsLandingPage'
import { setMenu } from '../../../store/menu/actions'
import { setSelectedGrade } from '../../../store/selectedGrade/actions'
import { setProjectToClone } from '../../../store/setCloneProject/actions'
import { setProjectToManage } from '../../../store/setManageProject/actions'

const mapStateToProps = state => ({
  settings: state.settings,
})

const mapDispatchToProps = dispatch => ({
  setMenu: menu => {
    dispatch(setMenu(menu))
  },
  setSelectedGrade: selectedGrade => {
    dispatch(setSelectedGrade(selectedGrade))
  },
  setProjectToClone : clone => {
    dispatch(setProjectToClone(clone))
  },
  setProjectToManage : project => {
    dispatch(setProjectToManage(project))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsLandingPage)
