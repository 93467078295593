import React from 'react'
import { PropTypes } from 'prop-types'
import {
  ButtonGroupWrapper, OutlinedButton, FilledButton,
  ModalHeading, Modal, Card, ButtonGroupPrimary
} from '@jsluna/react';
import ModalStylingFixForWin32 from '../../../../common/ModalStylingFixForWin32'

export function DeleteGradeModal(props) {
  return (
    <Modal
      data-testid="delete-range-modal"
      restrictClose
      open={props.isOpen}
      className={"delete-range-modal"}
    >
      <Card className={"delete-range-modal-body"}>
        <ModalHeading data-testid="modal-heading" className={"modalheading"}>Are you sure you want to delete {props.gradeToDelete}?</ModalHeading>
        <p  data-testid="delete-grade-description" className={"delete-modal-description"}>Deleting this grade will remove all ranges and associated clothing layouts.</p>
        <p  data-testid="delete-action-description" className={"delete-modal-description"}>You will not be able to undo this action.</p>
        <ButtonGroupWrapper actionBar className={"DeleteCancelButtonWrapper"}>
          <ButtonGroupPrimary>
            <FilledButton  data-testid="delete-button" className={"yes-button"} onClick={props.onDeleteGrade}>
              Delete {props.gradeToDelete}
            </FilledButton>
            <OutlinedButton  data-testid="cancel-button" color="dark" className={"no-button"} onClick={props.onModalClose}>
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Card>
    </Modal>
  )
}

DeleteGradeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDeleteGrade: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  gradeToDelete: PropTypes.string.isRequired
}

export default ModalStylingFixForWin32(DeleteGradeModal)
