import {
  Button,
  Container,
  ProgressIndicator,
  ProgressSpinner,
} from "@jsluna/react";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { api, fileDownloadFunctions } from "../../../api/api";
import breadcrumbHelper from "../../../utils/breadcrumbHelper";
import Constants from "../../../utils/constants";
import { withRouter } from "../../../utils/reactRouterDom";
import Breadcrumbs from "../../Controls/Breadcrumbs";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import AddRangeModal from "./AddRangeModal";
import DeleteRangeModal from "./DeleteRangeModal";
import EditRangeModal from "./EditRangeModal";
import EmptyProject from "./EmptyProject";
import GradeMenu from "./GradeMenu";
import ProjectRanges from "./ProjectRanges";
import SelectRangesModal from "./SelectRangesModal";

export class ViewProject extends Component {
  constructor(props) {
    super(props);

    this.projectViewMode = {
      empty: 0,
      viewRanges: 1,
    };
    this.updatedRangeName = "";
    this.gradeOne = 1;

    this.state = {
      gradeMenuStatusMessage: "",
      projectId: -1,
      projectName: "",
      ranges: [],
      loading: true,
      mode: this.projectViewMode.empty,
      openAddRangeModal: false,

      deleteRangeModal: {
        openDeleteRangeModal: false,
        selectedRange: {
          id: -1,
          name: "",
        },
      },

      editRangeModal: {
        open: false,
        id: -1,
        name: "",
      },
      grades: [],
      availableGrades: [],
      activeGradeNumber: this.props.selectedGrade,
      imageExportStatus: {
        exportingImages: false,
        imageExportCountForRange: 0,
        numberOfImagesToExportForRange: 0,
        exportRangeName: "",
        rangeExportCount: 0,
        numberOfRangesToExport: 0,
        cancelling: false,
      },
      selectRangesModal: {
        isOpen: false,
      },
    };

    this.cancelExport = false;

    this.loadProjectGradeRanges = this.loadProjectGradeRanges.bind(this);
    this.handleAddRangeClick = this.handleAddRangeClick.bind(this);
    this.handleBuildRangeClick = this.handleBuildRangeClick.bind(this);
    this.handleExportRangeClick = this.handleExportRangeClick.bind(this);
    this.handleExportGradeClick = this.handleExportGradeClick.bind(this);
    this.downloadExportImagesForRange =
      this.downloadExportImagesForRange.bind(this);
    this.handleEditRangeClick = this.handleEditRangeClick.bind(this);
    this.handleDeleteRangeClick = this.handleDeleteRangeClick.bind(this);
    this.handleAddRangeToGrade = this.handleAddRangeToGrade.bind(this);
    this.handleAddModalClose = this.handleAddModalClose.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleEditModalClose = this.handleEditModalClose.bind(this);
    this.handleSaveEditRangeModalClick =
      this.handleSaveEditRangeModalClick.bind(this);
    this.handleCancelEditRangeModalClick =
      this.handleCancelEditRangeModalClick.bind(this);
    this.handleDeleteRangeFromGrade =
      this.handleDeleteRangeFromGrade.bind(this);
    this.updateMode = this.updateMode.bind(this);
    this.handleCloseGradeMenuClick = this.handleCloseGradeMenuClick.bind(this);
    this.handleGradeItemClick = this.handleGradeItemClick.bind(this);
    this.handleCreateProjectGrade = this.handleCreateProjectGrade.bind(this);
    this.loadProjectGrades = this.loadProjectGrades.bind(this);
    this.closeGradeMenu = this.closeGradeMenu.bind(this);
    this.selectGrade = this.selectGrade.bind(this);
    this.handleDeleteGrade = this.handleDeleteGrade.bind(this);
    this.openGradeMenu = this.openGradeMenu.bind(this);
    this.resetStatusMessage = this.resetStatusMessage.bind(this);
    this.handleCancelSelectRangesModalClick =
      this.handleCancelSelectRangesModalClick.bind(this);
    this.handleExportSelectRangesModalClick =
      this.handleExportSelectRangesModalClick.bind(this);
    this.handleCancelExport = this.handleCancelExport.bind(this);
    this.navigate = this.props.router.navigate;
  }

  async componentDidMount() {
    console.log(this.props);
    const projectId = this.props.router.params.projectId
      ? parseInt(this.props.router.params.projectId, 0)
      : 0;

    if (projectId <= 0) {
      this.navigate(`/projects`);
      return;
    }

    this.props.setMenu({ selectedMenu: Constants.MenuOptions.none });

    await this.loadProjectGradeRanges(
      projectId,
      this.props.selectedGrade,
      true
    );
  }

  updateMode(newMode) {
    const { mode } = this.state;

    if (mode === newMode) {
      return;
    }

    this.setState({ mode: newMode });

    if (newMode === this.projectViewMode.viewRanges) {
      this.props.setMenu({
        selectedMenu: Constants.MenuOptions.none,
        optionalMenuItems: [Constants.MenuOptions.grades],
      });
    } else {
      this.props.setMenu({ selectedMenu: Constants.MenuOptions.none });
    }
  }

  handleCloseGradeMenuClick(e) {
    if (e) {
      e.preventDefault();
    }
    this.resetStatusMessage();
    this.closeGradeMenu();
  }

  closeGradeMenu() {
    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.none,
      optionalMenuItems: [Constants.MenuOptions.grades],
    });
  }

  openGradeMenu() {
    this.props.setMenu({
      selectedMenu: Constants.MenuOptions.grades,
      optionalMenuItems: [Constants.MenuOptions.grades],
    });
  }

  resetStatusMessage() {
    this.setState({ gradeMenuStatusMessage: "" });
  }

  async handleGradeItemClick(e, grade) {
    if (e) {
      e.preventDefault();
    }

    const { projectId, activeGradeNumber } = this.state;

    if (grade.gradeNumber === activeGradeNumber) {
      return;
    }
    this.resetStatusMessage();
    this.selectGrade(projectId, grade.gradeNumber);
  }

  async selectGrade(projectId, gradeNumber, closeGradeMenu = true) {
    // Close the grade menu, set the grade to be the active grade and load the new grade
    if (closeGradeMenu) {
      this.closeGradeMenu();
    }

    this.setState({ activeGradeNumber: gradeNumber });
    this.props.setSelectedGrade(gradeNumber);
    await this.loadProjectGradeRanges(projectId, gradeNumber);
  }

  async loadProjectGradeRanges(
    projectId,
    gradeNumber,
    loadProjectGrades = false
  ) {
    try {
      this.setState({ loading: true });
      const gradeRanges = await api.getProjectGradeRanges(
        this.props.settings.baseApiUrl,
        projectId,
        gradeNumber,
        true
      );
      // Empty mode is only available for Grade 1
      let mode =
        gradeNumber === this.gradeOne
          ? this.projectViewMode.empty
          : this.projectViewMode.viewRanges;
      let projectName = "";
      let ranges = [];
      if (gradeRanges) {
        projectName = gradeRanges.projectName;
        ranges = gradeRanges.ranges;
        if (gradeRanges.ranges.length > 0) {
          mode = this.projectViewMode.viewRanges;
        }
      }
      // Set the display mode
      this.updateMode(mode);

      // Load project grades if this has been requested
      if (loadProjectGrades) {
        await this.loadProjectGrades(projectId);
      }

      // Set the ranges
      this.setState({ projectId, projectName, ranges, loading: false });
    } catch (e) {
      api.handleError(this.props.router.location, e.message);
    }
  }

  async loadProjectGrades(projectId) {
    try {
      const projectGrades = await api.getProjectGrades(
        this.props.settings.baseApiUrl,
        projectId
      );
      this.setState({
        grades: projectGrades.assignedGrades,
        availableGrades: projectGrades.availableGrades,
      });
    } catch (e) {
      api.handleError(this.props.router.location, e.message);
    }
  }

  async handleDeleteGrade(gradeId, gradeNumber) {
    try {
      const { projectId } = this.state;

      if (gradeId) {
        await api.deleteProjectGrade(
          this.props.settings.baseApiUrl,
          projectId,
          gradeId
        );
        const projectGrades = await api.getProjectGrades(
          this.props.settings.baseApiUrl,
          projectId
        );
        this.setState({
          grades: projectGrades.assignedGrades,
          availableGrades: projectGrades.availableGrades,
          gradeMenuStatusMessage:
            gradeNumber + " has been successfully deleted from your project!",
        });
        this.selectGrade(projectId, 1, false);
      }
    } catch (e) {
      api.handleError(this.props.router.location, e.message);
    }
  }

  async handleCreateProjectGrade(gradeId, newGradeNumber) {
    try {
      const { projectId, grades, availableGrades } = this.state;
      const { settings } = this.props;

      let newGrade = null;

      if (gradeId === "") {
        newGrade = await api.createEmptyProjectGrade(
          settings.baseApiUrl,
          projectId,
          Number(newGradeNumber)
        );
      } else {
        newGrade = await api.createProjectGrade(
          settings.baseApiUrl,
          projectId,
          Number(gradeId),
          Number(newGradeNumber)
        );
      }

      if (newGrade != null) {
        // Add the new grade to the existing grades and make sure they are in ascending grade number
        grades.push(newGrade);
        grades.sort(function (a, b) {
          return a.gradeNumber - b.gradeNumber;
        });

        // Remove the new grade from the available grades
        var newGradeIndex = availableGrades.findIndex(
          (gradeNumber) => gradeNumber === newGrade.gradeNumber
        );
        availableGrades.splice(newGradeIndex, 1);

        // Close the grade menu and select the new grade
        this.selectGrade(projectId, newGrade.gradeNumber);
      } else {
        api.handleError(this.props.router.location, "Error saving new grade");
      }
    } catch (e) {
      api.handleError(this.props.router.location, e.message);
    }
  }

  handleAddRangeToGrade(rangeDetails) {
    const { ranges, activeGradeNumber } = this.state;
    api
      .postProjectGradeRange(
        this.props.settings.baseApiUrl,
        this.state.projectId,
        activeGradeNumber,
        rangeDetails
      )
      .then((range) => {
        ranges.push(range);
        this.handleAddModalClose();
        this.updateMode(this.projectViewMode.viewRanges);
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  handleDeleteRangeFromGrade() {
    const { ranges, deleteRangeModal, activeGradeNumber } = this.state;
    api
      .deleteProjectGradeRange(
        this.props.settings.baseApiUrl,
        this.state.projectId,
        activeGradeNumber,
        deleteRangeModal.selectedRange.id
      )
      .then(() => {
        var selectedRangeIndex = ranges.findIndex(
          (range) => range.id === deleteRangeModal.selectedRange.id
        );
        ranges.splice(selectedRangeIndex, 1);
        this.handleDeleteModalClose();
        let mode =
          activeGradeNumber === this.gradeOne
            ? this.projectViewMode.empty
            : this.projectViewMode.viewRanges;
        if (ranges.length > 0) {
          // Empty mode is only available for Grade 1
          mode = this.projectViewMode.viewRanges;
        }
        this.updateMode(mode);
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  handleAddModalClose(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openAddRangeModal: false });
  }

  handleDeleteModalClose(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      deleteRangeModal: {
        openDeleteRangeModal: false,
        selectedRange: { id: -1, name: "" },
      },
    });
  }

  handleEditModalClose(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ editRangeModal: { open: false, id: -1, name: "" } });
  }

  handleCancelSelectRangesModalClick(e) {
    if (e) {
      e.preventDefault();
    }
    const { selectRangesModal } = this.state;
    selectRangesModal.isOpen = false;
    this.setState({ selectRangesModal });
  }

  handleExportSelectRangesModalClick(e, selectedRangeIds) {
    if (e) {
      e.preventDefault();
    }

    const { ranges, selectRangesModal } = this.state;

    // Create a list of ranges to export
    const rangesToExport = [];
    for (let i = 0; i < ranges.length; i += 1) {
      if (selectedRangeIds.includes(ranges[i].id)) {
        rangesToExport.push({
          rangeId: ranges[i].id,
          gondolas: ranges[i].gondolas,
          rangeName: ranges[i].name,
          rangeNumber: i + 1,
        });
      }
    }

    this.downloadExportImagesForRange(rangesToExport);

    selectRangesModal.isOpen = false;
    this.setState({ selectRangesModal });
  }

  handleAddRangeClick(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({ openAddRangeModal: true });
  }

  handleBuildRangeClick(e, rangeId, rangeName) {
    if (e) {
      e.preventDefault();
    }
    // Pass the project id to the edit page. Will need to pass the range id as well in the future
    const { projectId, projectName, activeGradeNumber } = this.state;
    this.navigate(
      `/projects/${projectId}/grades/${activeGradeNumber}/ranges/${rangeId}/edit`,
      { state: { projectName: projectName, rangeName: rangeName } }
    );
  }

  handleExportRangeClick(
    e,
    projectId,
    rangeId,
    gondolas,
    rangeName,
    rangeNumber
  ) {
    if (e) {
      e.preventDefault();
    }

    const rangesToExport = [];
    rangesToExport.push({
      rangeId: rangeId,
      gondolas: gondolas,
      rangeName: rangeName,
      rangeNumber: rangeNumber,
    });

    this.downloadExportImagesForRange(rangesToExport);
  }

  handleExportGradeClick(e) {
    if (e) {
      e.preventDefault();
    }

    const { selectRangesModal } = this.state;
    selectRangesModal.isOpen = true;
    this.setState({ selectRangesModal });
  }

  handleCancelExport(e) {
    if (e) {
      e.preventDefault();
    }

    const { imageExportStatus } = this.state;

    if (
      this.cancelExport === false &&
      imageExportStatus.exportingImages === true
    ) {
      this.cancelExport = true;
      imageExportStatus.cancelling = true;
      this.setState({ imageExportStatus });
    }
  }

  downloadExportImagesForRange(rangesToExport, rangeIndex = 0) {
    const { projectId, activeGradeNumber, imageExportStatus } = this.state;

    const range = rangesToExport[rangeIndex];

    let imageExportCountForRange = 1;
    const numberOfImagesToExportForRange = range.gondolas.length;
    let rangeExportCount = rangeIndex + 1;
    const numberOfRangesToExport = rangesToExport.length;

    // Set the image export status for this range
    imageExportStatus.exportingImages = true;
    imageExportStatus.cancelling = false;
    imageExportStatus.imageExportCountForRange = imageExportCountForRange;
    imageExportStatus.numberOfImagesToExportForRange =
      numberOfImagesToExportForRange;
    imageExportStatus.rangeExportCount = rangeExportCount;
    imageExportStatus.numberOfRangesToExport = numberOfRangesToExport;
    imageExportStatus.exportRangeName = range.rangeName;
    this.setState({ imageExportStatus: imageExportStatus });

    var processNextImage = function (viewPage) {
      const { imageExportStatus } = viewPage.state;

      let startNextRange = false;
      if (imageExportCountForRange < numberOfImagesToExportForRange) {
        imageExportCountForRange += 1;
        imageExportStatus.imageExportCountForRange = imageExportCountForRange;
      } else if (
        rangeExportCount < numberOfRangesToExport &&
        viewPage.cancelExport === false
      ) {
        startNextRange = true;
      } else {
        imageExportStatus.exportingImages = false;
        imageExportStatus.cancelling = false;
        viewPage.cancelExport = false;
      }

      viewPage.setState({ imageExportStatus: imageExportStatus });

      if (startNextRange === true) {
        viewPage.downloadExportImagesForRange(rangesToExport, rangeIndex + 1);
      }
    };

    for (let i = 0; i < range.gondolas.length; i++) {
      let gondolaNumber = i + 1;

      let element = document.createElement("a");
      api
        .getExportRangeGondolaImage(
          this.props.settings.baseApiUrl,
          projectId,
          activeGradeNumber,
          range.rangeId,
          range.gondolas[i].id,
          range.rangeNumber,
          gondolaNumber
        )
        .then((response) => {
          element.setAttribute(
            "download",
            `${fileDownloadFunctions.getFileName(response.headers)}`
          );
          return response.blob();
        })
        .then((blob) => {
          fileDownloadFunctions.setupAndClickLink(element, blob);
        })
        .then(() => {
          processNextImage(this);
        })
        .catch((e) => {
          api.handleError(this.props.router.location, e.message);
        });
    }
  }

  handleEditRangeClick(e, rangeId, rangeName) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      editRangeModal: { open: true, id: rangeId, name: rangeName },
    });
  }

  handleDeleteRangeClick(e, range) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      deleteRangeModal: { openDeleteRangeModal: true, selectedRange: range },
    });
  }

  handleCancelEditRangeModalClick(e) {
    this.handleEditModalClose(e);
  }

  handleSaveEditRangeModalClick(e, updatedRangeName, range) {
    if (e) {
      e.preventDefault();
    }
    this.updatedRangeName = updatedRangeName.trim();

    // Passing the VM to post api
    const gradeRangeRenameVm = {
      rangeId: range.id,
      newName: this.updatedRangeName,
    };

    const { activeGradeNumber } = this.state;

    api
      .postProjectGradeRangeRename(
        this.props.settings.baseApiUrl,
        this.state.projectId,
        activeGradeNumber,
        range.id,
        gradeRangeRenameVm
      )
      .then((data) => {
        if (data != null) {
          const { ranges } = this.state;
          const thisRange = ranges.find(function (r) {
            return r.id === data.rangeId;
          });
          if (thisRange) {
            thisRange.name = data.newName;
          }
          this.setState({ ranges });
          this.handleEditModalClose();
        }
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  renderContent() {
    const {
      mode,
      projectName,
      ranges,
      projectId,
      openAddRangeModal,
      deleteRangeModal,
      grades,
      availableGrades,
      activeGradeNumber,
      gradeMenuStatusMessage,
      selectRangesModal,
    } = this.state;

    if (mode === this.projectViewMode.empty) {
      return (
        <React.Fragment>
          <EmptyProject
            projectName={projectName}
            onAddRangeClick={this.handleAddRangeClick}
          />
          <AddRangeModal
            isOpen={openAddRangeModal}
            gradeNumber={activeGradeNumber}
            projectId={projectId}
            router={this.props.router}
            onAddRangeToGrade={this.handleAddRangeToGrade}
            onModalClose={this.handleAddModalClose}
          />
        </React.Fragment>
      );
    }

    if (mode === this.projectViewMode.viewRanges) {
      return (
        <React.Fragment>
          <Button
            variant="filled"
            className="export-grade-button"
            onClick={this.handleExportGradeClick}
          >
            Export grade
          </Button>
          <GradeMenu
            grades={grades}
            availableGrades={availableGrades}
            activeGradeNumber={activeGradeNumber}
            onCloseGradeMenuClick={this.handleCloseGradeMenuClick}
            onGradeItemClick={this.handleGradeItemClick}
            onCreateGradeClick={this.handleCreateProjectGrade}
            onDeleteGrade={this.handleDeleteGrade}
            statusMessage={gradeMenuStatusMessage}
            closeGradeMenu={this.closeGradeMenu}
            openGradeMenu={this.openGradeMenu}
            resetStatusMessage={this.resetStatusMessage}
          />
          <ProjectRanges
            projectId={projectId}
            ranges={ranges}
            onAddRangeClick={this.handleAddRangeClick}
            onBuildRangeClick={this.handleBuildRangeClick}
            onExportRangeClick={this.handleExportRangeClick}
            onEditRangeClick={this.handleEditRangeClick}
            onDeleteRangeClick={this.handleDeleteRangeClick}
            settings={this.props.settings}
            router={this.props.router}
          />
          <AddRangeModal
            isOpen={openAddRangeModal}
            gradeNumber={activeGradeNumber}
            projectId={projectId}
            router={this.props.router}
            onAddRangeToGrade={this.handleAddRangeToGrade}
            onModalClose={this.handleAddModalClose}
          />
          <DeleteRangeModal
            isOpen={deleteRangeModal.openDeleteRangeModal}
            onDeleteRangeFromGrade={this.handleDeleteRangeFromGrade}
            onModalClose={this.handleDeleteModalClose}
            selectedRange={deleteRangeModal.selectedRange}
          />
          {this.state.editRangeModal.open === true && (
            <EditRangeModal
              onCancelEditRangeModalClick={this.handleCancelEditRangeModalClick}
              onSaveEditRangeModalClick={this.handleSaveEditRangeModalClick}
              range={this.state.editRangeModal}
            />
          )}
          <SelectRangesModal
            isOpen={selectRangesModal.isOpen}
            onExportSelectedRanges={this.handleExportSelectRangesModalClick}
            onModalClose={this.handleCancelSelectRangesModalClick}
            ranges={[...ranges]}
          />
        </React.Fragment>
      );
    }

    return null;
  }

  render() {
    const { projectName, activeGradeNumber, imageExportStatus } = this.state;
    const projectTitle = projectName + ` - Grade ${activeGradeNumber}`;

    return (
      <React.Fragment>
        <div className="view-project-content-container">
          <Container size="lg" soft>
            {this.state.loading === true && <LoadingIndicator />}
            {imageExportStatus.exportingImages && (
              <ProgressIndicator
                className="ln-u-justify-content-center"
                page
                loading
                preventFocus
              >
                <ProgressSpinner className="ln-u-push-right-sm" color="light" />
                <div className="export-image-text">
                  {`Exporting ${imageExportStatus.exportRangeName}`}{" "}
                  {imageExportStatus.numberOfRangesToExport > 1
                    ? `(${imageExportStatus.rangeExportCount} of ${imageExportStatus.numberOfRangesToExport})`
                    : null}
                </div>
                <div className="export-image-text export-image-text--counter">{`Gondola ${imageExportStatus.imageExportCountForRange} of ${imageExportStatus.numberOfImagesToExportForRange}`}</div>
                {imageExportStatus.numberOfRangesToExport > 1 && (
                  <div className="export-image-button--cancel">
                    <Button
                      variant="filled"
                      color={"light"}
                      onClick={this.handleCancelExport}
                      disabled={imageExportStatus.cancelling}
                    >
                      {imageExportStatus.cancelling === true
                        ? "Cancelling ..."
                        : "Cancel"}
                    </Button>
                  </div>
                )}
              </ProgressIndicator>
            )}
            {(this.state.loading === false ||
              imageExportStatus.exportingImages) && (
              <React.Fragment>
                <Breadcrumbs
                  items={breadcrumbHelper.createEmptyGradeOne(projectTitle)}
                />
                <h2 className="view-project-title">{projectTitle}</h2>
                {this.renderContent()}
              </React.Fragment>
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ViewProject.propTypes = {
  settings: PropTypes.shape({
    baseApiUrl: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  selectedGrade: PropTypes.number.isRequired,
  setSelectedGrade: PropTypes.func.isRequired,
};

export default withRouter(ViewProject);
