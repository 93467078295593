import React from 'react';
import { SelectField } from '@jsluna/react';
import { connect } from 'react-redux';
import Constants from '../../../utils/constants'
import { setMaxLibraryImages } from '../../../store/maxLibraryImages/actions';

function MaxImages(props) {
  const changeMaxImages = (e) => {
    e.preventDefault();
    props.dispatch(setMaxLibraryImages(Number(e.target.value)));
  }

  
  return (
    <SelectField
      name="select-max-images"
      label="Maximum images to display"
      hideLabel={false}
      placeholder={false} 
      options={Constants.maxLibraryImagesOptions}
      value={props.maxLibraryImages.toString()}
      onChange={(e) => changeMaxImages(e)}/>           
  );
}
  
  
export default connect((state) => {
  return {
    maxLibraryImages: state.maxLibraryImages
  }}) (MaxImages);