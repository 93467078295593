import React, { Component } from 'react';

// This is a "HIGHER-ORDER FUNCTION" which is just a function that returns a component
const ModalStylingFixForWin32 = WrappedComponent => {
	return class ModalStylingClassForWin32 extends Component {
      constructor(props) {
        super(props);
        this.state = {}
			}
			
			// Reusing the following lifecycle methods across modal components so that items underneath them don't shift to the right on Windows machines
			componentDidMount() {
				applyStylingWhenModalIsDisplayedInWin32();
      }
      
      componentDidUpdate() {
				applyStylingWhenModalIsDisplayedInWin32();
			}

			componentWillUnmount() {
				revertToDefaultStylingWhenModalIsNotDisplayedInWin32();
			}
			
			render() {
				return (
					<WrappedComponent 
            {...this.props}
          />
				);
			}
		}
	}

const applyStylingWhenModalIsDisplayedInWin32 = () => {
	let OSName="Unknown OS"

	if(navigator.platform.indexOf("Win") !== -1) OSName="Windows";
	
	if(OSName ==="Windows") {
		document.body.classList.add('has-overlay--windows')
	}
}

const revertToDefaultStylingWhenModalIsNotDisplayedInWin32 = () => {
	let OSName="Unknown OS"

	if(navigator.platform.indexOf("Win") !== -1) OSName="Windows";

	if(OSName ==="Windows") {
		document.body.classList.remove('has-overlay--windows')
	}
}

export default ModalStylingFixForWin32