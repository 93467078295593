import * as PIXI from 'pixi.js'
import barImage from '../assets/images/buildmode/buildmode-backbar.png'
import DraggableSprite from './DraggableSprite'
import Gondola from './Gondola'
import HoverButton from './HoverButton'
import deleteButtonDefault from '../assets/images/delete-button-default.png'
import deleteButtonDisabled from '../assets/images/delete-button-disabled.png'
import deleteButtonHovered from  '../assets/images/delete-button-hovered.png'
import Item from './Item'
export default class Bar extends DraggableSprite {

  selectFrameProperties = {
    lineWidth: 1.5,
    colour: 0xF06C00,
    alpha: 1
  }
  constructor(position, rangeBuilder){
    super(position)

    this.xOffset = 3
    this.yOffset = 0

    this.buttonProperties = {
    scale: 1,
    width: 49,
    height: 49,
    verticalMargin: 1
  }

  this.separatorProperties = {
    horizontalMargin: 8,
    verticalMargin: 8,
    width: 20,
    lineWidth: 2,
    colour: 0x8E8E8E,
    alpha: 1
  }

  this.deleteFrameProperties = {
    lineWidth: 1,
    colour: 0x0C0C0C,
    alpha: 1,
    margin: 4,
    width: this.buttonProperties.width + 8,
    height: this.buttonProperties.height + 8
  }

    this._loadTexture()
    this.position = position
    this.anchor.set(0.5,0.5)
    this.rangeBuilder = rangeBuilder
    this.type = Bar.typeIdentifier
    this.visibleForMerge = true
    this.deleteFrame = null
  }

  toJson(){
    //(imageId, imageUrl, rangeId, orientation, position,
    const itemsChildren = []
    var result = {
      type: Bar.typeIdentifier,
      orientation: this.orientation,
      x: 1/(Gondola.getBayWidth(this.parent.bayCount) / this.x),
      y: 1/(Gondola.getGondolaHeight() / this.y),
    }

    if( this.children.length > 0) {
      this.children.filter(x => x.type )
        .forEach(item => itemsChildren.push(item.toJson()))
      result.children = itemsChildren
    }
    return result
  }

  _loadTexture(){
    this.texture = PIXI.Texture.from(barImage)
  }

  drawSelectedGraphics(){
    const itemWidth = this.width 
    const itemHeight = this.height
    const itemX = (itemWidth * this.anchor.x) * -1
    const itemY = (itemHeight * this.anchor.y) * -1
    const selectFrame = new PIXI.Graphics()
    selectFrame.visibleForMerge = true
    selectFrame.clear()
    selectFrame.lineStyle(this.selectFrameProperties.lineWidth, this.selectFrameProperties.colour, this.selectFrameProperties.alpha)
    selectFrame.drawRect(itemX, itemY, itemWidth, itemHeight)
    selectFrame.endFill()

    this.deleteFrame = this._createSelectFrame(itemX, itemY)
    selectFrame.addChild(this.deleteFrame)
    this._moveDeleteButton()

    return selectFrame
  }

  _createSelectFrame(x, y) {
    const deleteFrame = new PIXI.Graphics()
		deleteFrame.clear()
		deleteFrame.lineStyle(this.deleteFrameProperties.lineWidth, this.deleteFrameProperties.colour, this.deleteFrameProperties.alpha)
		deleteFrame.drawRect(x + this.width, y, this.deleteFrameProperties.width, this.deleteFrameProperties.height)
    deleteFrame.endFill()
    deleteFrame.visibleForMerge = true
    const deleteButton = new HoverButton(
          (e) => {
         e.stopPropagation(); 
         this.parent.removeChild(this);
       },
      deleteButtonDefault,
      deleteButtonHovered,
      deleteButtonDisabled
    )
    deleteButton.anchor.set(0, 0)
    deleteButton.x = x + this.width + 4
    deleteButton.y = y + 4
    deleteButton.visibleForMerge = true

    deleteFrame.addChild(deleteButton)
    return deleteFrame
  }

  _reattachToParent(newParent){
    newParent.addChildAt(this,2)
  }
  
  _moveDeleteButton(){
    const itemWidth = this.width 
    const itemX = (itemWidth * this.anchor.x) * -1
    if(this.x > (this.rangeBuilder.maxCanvasWidth - Gondola.singleBayWidth())){
      this.deleteFrame.x = itemX - this.deleteFrameProperties.width - itemWidth / 2
    }
    else{
      this.deleteFrame.x = itemX + (itemWidth / 2)
    }
    this.deleteFrame.visible = true
  }

  _hideDeleteButton() {
    if (this.deleteFrame == null) {
      return
    }
    this.deleteFrame.visible = false
  }

  removeSelectFrame(){
    if (this.children) {
    let items = this.children.filter(i => (i.type))
    items.map(i => i.removeSelectFrame())    
    }

    this.removeChild(this.selectFrame)
    this.selected = false
  }
  
  onStartDragMoveCallback() {
    this._hideDeleteButton()
    this.children.forEach(element => { if (element.onStartDragMoveCallback != null) { element.onStartDragMoveCallback() } })
  }

  setAnchor(newAnchor)
  {
    //move children to retain position with new anchor
    if(this.children.length> 0)
    {
      //find the difference inbetween the old and new anchor
      var diffAnchor = {
        x: this.anchor.x - newAnchor.x,
        y: this.anchor.y - newAnchor.y
      }
      this.children.filter(x => x.type === Item.typeIdentifier).forEach(child => {
        child.originalPos = {x:child.x, y:child.y}
        child.x = child.x + (diffAnchor.x * this.width)
        child.y = child.y + (diffAnchor.y * this.height)
      })
    }
    super.setAnchor(newAnchor)

  }

  onDragEndCallback() { 
    const targetLeft = this.x - (this.anchor.x * this.width)
    const targetRight = targetLeft + this.width
    const targetCentreX = targetLeft + ((targetRight - targetLeft) / 2)

    const parentGondolaSnappingPoints = this.parent.ySnappingPoints
    let bottomSnapPoint = this.parent.yBottomSnapPoint
    
    for (var p = 0; p < parentGondolaSnappingPoints.length; p++) {
      if(this.position.y < bottomSnapPoint) {
        if (this.position.y < parentGondolaSnappingPoints[p]) {
          if (Math.abs(parentGondolaSnappingPoints[p] - this.position.y) <= 6) {
            this.position.y = parentGondolaSnappingPoints[p]
            break
          }
        }

        if (Math.abs(this.position.y - parentGondolaSnappingPoints[p]) <= 6) {
          this.position.y = parentGondolaSnappingPoints[p]
          break
        }
      }
      else {
        this.position.y = parentGondolaSnappingPoints[parentGondolaSnappingPoints.length-1]
      }
    }

    // Bring bar to front by changing its child index in the gondola
    this.parent.setChildIndex(this, this.parent.children.length - 1)
  
    let baySnappingPoints = this.parent.xSnappingPoints
    let newX = baySnappingPoints.find(x => (Math.abs(targetCentreX - x) <= 149 || Math.abs(x - targetCentreX) <= 149))
    if (targetCentreX > newX) {
      newX = baySnappingPoints[baySnappingPoints.indexOf(newX) + 1]
    }
    if (newX <= 4) {
      this.position.x = newX + 147
    }
    else {
      this.position.x = newX - 147
    }
    this._moveDeleteButton()
  }

  moveItemInsideGondolaBounds() {
    let gondolaTop = this.parent.backGroundSprite.position.y
    let gondolabottom = gondolaTop + Gondola.getGondolaHeight()
    let gondolaLeft = this.parent.backGroundSprite.position.x
    let gondolaRight = gondolaLeft + Gondola.getBayWidth(this.parent.bayCount)

    let itemTop = this.position.y - this.texture.height / 2
    let itemBottom = this.position.y + this.texture.height / 2
    let itemLeft = this.position.x - this.texture.width / 2
    let itemRight = this.position.x + this.texture.width / 2

    // if a bar has a child, need to calculate full height
    let tallestChild
    if (this.children) {
      tallestChild = Math.max.apply(Math, this.children.map(
        function (o) {
          if (o.type === "Item") {
            return o.position.y + o.texture.height / 2
          }
          return 0
        }
      ))
    }
    // move the item inside its parents container.
    if (itemBottom + tallestChild > gondolabottom) {
      this.position.y = gondolabottom - (tallestChild + this.texture.height / 2)
    }
    if (itemTop < gondolaTop) {
      this.position.y = gondolaTop + (this.texture.height / 2)
    }
    if (itemLeft < gondolaLeft) {
      this.position.x = gondolaLeft + (this.texture.width / 2)
    }
    if (itemRight > gondolaRight) {
      this.position.x = gondolaRight - (this.texture.width / 2)
    }
  }

  static getBarWidth() {
    return 297
  }

  static getBarHeight() {
    return 18
  }

  static typeIdentifier = "Bar"

}