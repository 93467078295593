/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@jsluna/react";
import React from "react";
import CloseIcon from "../../assets/images/ln-icon-close.svg";
import Modal from "../Modal";

/**
 * A modal confirmation box based on the general modal dialogue component. This dialog has a confirm
 * and a cancel button which fire functions that can be injected as props. The button text can also be set.
 * The children of this component are the modal dialog message body.
 */
const ModalConfirm = (
  props,
  {
    isOpen = false,
    confirmFunction = () => {},
    cancelFunction = () => {},
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel",
  }
) => {
  return (
    <Modal isOpen={Boolean(isOpen)}>
      <div className="c-modal-confirm-container">
        <div className="ln-o-clearfix">
          <button
            variant="filled"
            className="c-modal-confirm-close-icon"
            onClick={cancelFunction}
          >
            <svg role="presentation" viewBox="0 0 25 25">
              <use xlinkHref={`#${CloseIcon.id}`} />
            </svg>
          </button>
        </div>
        {props.children}
        <div className="ln-o-generic--margin-top-medium">
          <Button
            className="ln-o-generic--margin-right c-modal-confirm-button"
            onClick={confirmFunction}
            disabled={Boolean(false)}
            autoFocus
          >
            {confirmButtonText}
          </Button>
          <span className="c-modal-confirm-link-container">
            <a
              href={""}
              className="ln-c-standalone-link c-modal-confirm-link"
              onClick={cancelFunction}
            >
              {cancelButtonText}
            </a>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
