import React, { Component } from 'react'
import { RadioButtonField } from '@jsluna/react'
import { connect } from 'react-redux'
import { setUsedImageFilter } from '../../../store/imagelibraryfilters/actions'

export class UsedImageFilter extends Component {  
  constructor(props) {
    super(props)
    this.changeUsedImageFilter = this.changeUsedImageFilter.bind(this)
  }

  changeUsedImageFilter(e) {
    this.props.dispatch(setUsedImageFilter(e.target.value))
  }

  render() {
    return (
      <RadioButtonField
        label="Only show"
        name="used-image-filter"
        className="used-image-filter"
        options={this.props.options}
        onChange={e => this.changeUsedImageFilter(e)}
      />
    )}
  }
  
export default connect((state) => {
  return {
    options: state.imageLibraryData.dropdownValues.usedImageOptions || []
  }})(UsedImageFilter)
