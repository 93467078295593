const Constants =
{
  InactivityTimeoutInSeconds: 30,
  MenuOptions: {
    none: '',
    projects: 'Projects',
    clothing: 'Clothing',
    grades: 'Grades',
    logOut: 'Log out'
  },
  DefaultGradeNumber: 1,
  NotSetFilterValue: 'Not set',
  NotSetFilterLabel: 'Not set (unknown)',
  RangePlannerItemProperties: {
    savedUsedImageCount: 'savedUsedImageCount'
  },
  allLibraryImagesValue: -1,
  maxLibraryImagesOptions: [
    { value: '50', label: '50'},
    { value: '100', label: '100'},
    { value: '150', label: '150'},
    { value: '200', label: '200'},
    { value: '500', label: '500'},
    { value: '-1', label: 'All'}
  ],
  maxLibraryImagesDefault: 100
}

export default Constants
