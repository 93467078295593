import React, { Component } from 'react';
import { SelectField } from '@jsluna/react';
import {connect} from 'react-redux';
import {setColourFilter} from '../../../store/imagelibraryfilters/actions';

class ColourFilter extends Component {  
  constructor(props){
    super(props);
    this.changeColourFilter = this.changeColourFilter.bind(this);
  }

  changeColourFilter(e){
    e.preventDefault();
    const newValue = e.target.value;
    this.props.dispatch(setColourFilter(newValue));
  }

  render() {
    return (
      <SelectField 
        name="select-colours" 
        label="Colour"
        placeholder={" "} 
        disabled={this.props.disableDropdowns}
        options={this.props.options}
        value={this.props.selectedValue}
        onChange={(e) => this.changeColourFilter(e)}/>           
      );
    }
  }
  
  export default connect((state,props) => {
    return {
      options: state.imageLibraryData.dropdownValues.colours || [],
      selectedValue: state.imageLibraryData.colourFilter || "",
      disableDropdowns: state.imageLibraryData.disableDropdowns
    }})(ColourFilter);