import { connect } from 'react-redux'
import { setMenu } from '../../../store/menu/actions'
import MainSideMenu from './MainSideMenu'

const mapStateToProps = state => ({
	optionalMenuItems: state.menu.optionalMenuItems,
	hideMenu: state.menu.hideMenu
})

const mapDispatchToProps = dispatch => ({
  setMenu: menu => {
    dispatch(setMenu(menu))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(MainSideMenu)
