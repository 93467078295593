export default class MergeManager  {

  _secondaryRanges = []
  _primaryRange = {gondolas:[]}


  getPrimaryRangePrevEnabled(currentIndex){
    return currentIndex >= 0
  }

  getPrimaryRangeNextEnabled(currentIndex){
    return currentIndex < this.getPrimaryRangeLength()
  }

  getSecondaryRangePrevEnabled(currentIndex){
    return currentIndex >= 0
  }

  getSecondaryRangeNextEnabled(currentIndex){
    return currentIndex < this.getSecondaryRangesLength()
  }

  getSecondaryGondolaPrevEnabled(currentIndex){
    return currentIndex >= 0 
  }

  getSecondaryGondolaNextEnabled(currentIndex){
    return currentIndex < this.getSecondaryRangeLength()
  }

  getSecondaryGondola(rangeIndex, gondolaIndex){
    if(this._secondaryRanges){
      let range = this._secondaryRanges[rangeIndex]
      if(range)
      {
        return range.gondolas[gondolaIndex]
      }
    }
  }

  getPrimaryGondola(gondolaIndex){
    if(this._primaryRange.gondolas){
      return this._primaryRange.gondolas[gondolaIndex]
    }
    return null;
  }

  removePrimaryGondola(gondola){
    this._primaryRange.gondolas.splice(this._primaryRange.gondolas.indexOf(gondola), 1)
  }

  setPrimaryGondola(gondolaSceneGraph, gondolaIndex, doNotHide = false){
    if(!doNotHide){
      this.hideAllControls(gondolaSceneGraph)
    } 
    this._primaryRange.gondolas[gondolaIndex] = gondolaSceneGraph
  }

  setSecondaryGondola(gondolaSceneGraph, rangeIndex, gondolaIndex){
    this.hideAllControls(gondolaSceneGraph)
    if(!this._secondaryRanges[rangeIndex]){
      this._secondaryRanges[rangeIndex] = {}
      this._secondaryRanges[rangeIndex].gondolas = []
    }
    this._secondaryRanges[rangeIndex].gondolas[gondolaIndex] = gondolaSceneGraph
  }

  getSecondaryRangeLength(rangeIndex){
    if(this._secondaryRanges[rangeIndex]){
      return this._secondaryRanges[rangeIndex].gondolas.length
    }
    return -1 
  }

  getPrimaryRangeLength(){
    return this._primaryRange.gondolas.length
  }

  getSecondaryRangesLength(){
    if(this._secondaryRanges){
      return this._secondaryRanges.length
    } 
    return -1
  }

  showAllControls(container){
    MergeManager._recurseChlidren(container, (child) => {
        child.visible = true
      })
  } 
  
  hideAllControls(container){
    MergeManager._recurseChlidren(container, (child) => {
      if(!child.visibleForMerge){
        child.visible = false
      }
      })
   }
  
  static _recurseChlidren(container, toExecute ){
    if(container.children && container.children.length > 0){
      container.children.map(c => {
      toExecute(c)
      if(c.children && c.children.length > 0)
      {
        MergeManager._recurseChlidren(c, toExecute)
      }
      return null
      })
    }
    return null
  }
}