import React, { Component } from 'react';
import { SelectField } from '@jsluna/react';
import {connect} from 'react-redux';
import {setDepartmentFilter} from '../../../store/imagelibraryfilters/actions';

class DepartmentFilter extends Component {
   constructor(props){
     super(props);
     this.changeDepartmentFilter = this.changeDepartmentFilter.bind(this);
   }

   changeDepartmentFilter(e){
     e.preventDefault();
     const newValue = e.target.value;
     this.props.dispatch(setDepartmentFilter(newValue))
   }

    render() {
      return (
        <div id="departmentFilterWrapper">
          <SelectField 
            name="select-departments" 
            label="Department"
            placeholder={" "} 
            disabled={this.props.disableDropdowns}
            options={this.props.options}
            value={this.props.selectedValue}
            onChange={(e) => this.changeDepartmentFilter(e)}
            />           
        </div>
      );
    }
  }
  
  export default connect((state,props) =>{
    return {
      options: state.imageLibraryData.dropdownValues.departments || [],
      selectedValue: state.imageLibraryData.departmentFilter || "",
      disableDropdowns: state.imageLibraryData.disableDropdowns
    }})(DepartmentFilter)