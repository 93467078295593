import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
/* eslint-disable jsx-a11y/anchor-is-valid */

class MainMenuButton extends Component {
	constructor(props){
		super(props);
		this.changeActiveMenuDetail = this.changeActiveMenuDetail.bind(this);
		this.getWrapperClassName = this.getWrapperClassName.bind(this);
		this.getButtonTextClassName = this.getButtonTextClassName.bind(this);
	}

	changeActiveMenuDetail(e, title){
		if (e) {
			e.preventDefault()
		}

		if (this.props.selectedMenu !== title) {
			this.props.handleClick();
		}
	}

	getWrapperClassName(){
		if (this.props.title === this.props.selectedMenu) {
			return "mainMenuEntryWrapper activeMenuEntry";
		}	
		return "mainMenuEntryWrapper";
	}

	getButtonTextClassName(){
		if(this.props.title === this.props.selectedMenu){
			return "mainMenuButtonText activeMenuEntry";
		}
		return "mainMenuButtonText"
	}

	render(){
		return(
			<div className={this.getWrapperClassName()} >
				<a tabIndex="0" onClick={e => this.changeActiveMenuDetail(e, this.props.title)}>
					<div className="mainMenuEntry">
						<div className="mainMenuButtonIcon">
							<div className="mainMenuButtonIconPosition">
								{this.props.children}
							</div>
						</div>
						<div className={this.getButtonTextClassName()}>
							{this.props.title}
						</div>
					</div>
				</a>
			</div>
		)
	}
}

MainMenuButton.propTypes = {
	title: PropTypes.string.isRequired,
	selectedMenu: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default MainMenuButton