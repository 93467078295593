import { MultiAutocompleteField } from "@jsluna/autocomplete";
import { Tick } from "@jsluna/icons";
import {
  Button,
  Card,
  CheckboxField,
  Container,
  FilledButton,
  OutlinedButton,
  TextInputField,
} from "@jsluna/react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { PropTypes } from "prop-types";
import React, { Component } from "react";
import { api, fileDownloadFunctions } from "../../../api/api";
import Constants from "../../../utils/constants";
import { withRouter } from "../../../utils/reactRouterDom";
import LoadingIndicator from "../../LoadingIndicator/LoadingIndicator";
import PeriodHeaderGroupComponent from "../PeriodHeaderGroupComponent/PeriodHeaderGroupComponent";
import GradeEditor from "./GradeEditor.jsx";

// https://www.ag-grid.com/javascript-grid-filter-set/

const newProjectState = {
  Setup: 1,
  Filter: 2,
  Export: 3,
};

class CreateProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: null,
      projectState: newProjectState.Setup,
      loadingSeasonsData: true,
      loadingGroupsData: true,
      useMainDepartmentControl: true,
      seasonsData: [],
      groupsData: [],
      // seasonMonthsData:[],
      departmentsData: [],
      // first page - Setup
      projectSeason: null,
      projectSeasons: [],
      projectGroups: [],
      // projectSeasonMonth: null,
      projectDepartments: [],
      projectName: null,
      continueToFiltersValid: false,
      // second page - filtering
      loadingPlannerData: false,
      unfilteredRangeData: [],
      selectedPeriod: null,
      gridForFiltersApi: null,
      filterModel: null,
      // third page - setting grades
      filteredRangeData: [],
      savingProject: false,
      isDownloadingPickList: false,
      // filter grid definitions
      defaultColDefForFilters: {
        width: 150,
        editable: false,
        filter: "agSetColumnFilter",
        filterParams: { debounceMs: 500 },
        sortable: true,
        resizable: false,
        suppressMovable: true,
        lockPinned: true,
        menuTabs: ["filterMenuTab"],
      },
      gridOptionsForFilters: {
        suppressMenuHide: true,
      },
      columnDefsForFilters: [
        {
          headerName: "Season",
          field: "season",
          width: 130,
          pinned: "left",
          lockPinned: true,
        },
        {
          headerName: "Group Description",
          field: "group",
          width: 210,
          pinned: "left",
          lockPinned: true,
        },
        {
          headerName: "Department",
          field: "department",
          width: 180,
          pinned: "left",
          lockPinned: true,
        },
        {
          headerName: "Class",
          field: "class",
          width: 180,
          pinned: "left",
          lockPinned: true,
        },
        {
          headerName: "Range Id",
          field: "rangeId",
          width: 130,
          pinned: "left",
          lockPinned: true,
        },
        {
          headerName: "Style Number",
          field: "style",
          width: 170,
        },
        {
          headerName: "Style Colour",
          field: "colour",
        },
        {
          headerName: "Description",
          field: "description",
          width: 200,
        },
        {
          headerName: "Story",
          field: "story",
        },
        {
          headerName: "Channel",
          field: "online",
        },
        {
          headerName: "On Sale Date",
          field: "onSaleDate",
          width: 160,
        },
        {
          headerName: "Off Sale Date",
          field: "offSaleDate",
          width: 160,
        },
        {
          headerName: "Period 1",
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 1,
            handleSelectClick: () => this.handlePeriodSelectClick(1),
          },
          resizable: true,
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade1",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer1",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores1",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Period 2",
          width: 200,
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 2,
            handleSelectClick: () => this.handlePeriodSelectClick(2),
          },
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade2",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer2",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores2",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Period 3",
          width: 200,
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 3,
            handleSelectClick: () => this.handlePeriodSelectClick(3),
          },
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade3",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer3",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores3",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Period 4",
          width: 200,
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 4,
            handleSelectClick: () => this.handlePeriodSelectClick(4),
          },
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade4",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer4",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores4",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Period 5",
          width: 200,
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 5,
            handleSelectClick: () => this.handlePeriodSelectClick(5),
          },
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade5",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer5",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores5",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Period 6",
          width: 200,
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 6,
            handleSelectClick: () => this.handlePeriodSelectClick(6),
          },
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade6",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer6",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores6",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Period 7",
          width: 200,
          marryChildren: true,
          headerGroupComponent: "periodHeaderGroupComponent",
          headerGroupComponentParams: {
            periodId: 7,
            handleSelectClick: () => this.handlePeriodSelectClick(7),
          },
          children: [
            {
              headerName: "Grade Number",
              field: "storeGrade7",
              width: 210,
            },
            {
              headerName: "Weeks on sale",
              field: "weeksOnOffer7",
              width: 140,
              columnGroupShow: "open",
            },
            {
              headerName: "Number Of Stores",
              field: "numberOfStores7",
              width: 150,
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Design Ref",
          field: "designRef",
        },
        {
          headerName: "Sizing",
          field: "sizing",
        },
        {
          headerName: "Sell Price A",
          field: "sellPriceA",
          width: 150,
          columnGroupShow: "open",
        },
        {
          headerName: "Sell Price B",
          field: "sellPriceB",
          width: 150,
          columnGroupShow: "open",
        },
        {
          headerName: "Sell Price C",
          field: "sellPriceC",
          width: 150,
          columnGroupShow: "open",
        },
        {
          headerName: "Sell Price Average",
          field: "sellPriceAverage",
          width: 120,
        },
        {
          headerName: "Sales Units",
          marryChildren: true,
          children: [
            {
              headerName: "Total planned sales Units",
              field: "netSalesUnits",
            },
            {
              headerName: "Total Planned sales £(k)",
              field: "netSalesValue",
              columnGroupShow: "open",
            },
            {
              headerName: "Average Rate Of Sale",
              field: "rateOfSaleAverage",
              columnGroupShow: "open",
            },
            {
              headerName: "AV Period planned sales unit",
              field: "salesUnitsAverage",
              columnGroupShow: "open",
            },
            {
              headerName: "Weekly planned sales units",
              field: "weeklySalesUnit",
              columnGroupShow: "open",
            },
            {
              headerName: "Weekly planned sales £(k)",
              field: "weeklySalesValue",
              columnGroupShow: "open",
            },
          ],
        },
        {
          headerName: "Edit Grade",
          field: "EditedGrade",
          width: 140,
          cellEditor: "GradeEditor",
          editable: true,
          pinned: "right",
          lockPinned: true,
          cellStyle: {
            backgroundColor: "#4C4C4C",
            color: "#FFFFFF",
            type: "number",
          },
          hide: true,
        },
      ],
      frameworkComponents: {
        periodHeaderGroupComponent: PeriodHeaderGroupComponent,
        GradeEditor: GradeEditor,
      },
    };
    this.getSeasonsDepartments = this.getSeasonsDepartments.bind(this);
    this.handlePeriodSelectClick = this.handlePeriodSelectClick.bind(this);
    this.handleSeasonChange = this.handleSeasonChange.bind(this);
    this.handleSeasonsChange = this.handleSeasonsChange.bind(this);
    this.handleDepartmentChange = this.handleDepartmentChange.bind(this);
    this.navigate = this.props.router.navigate;
  }

  handlePeriodSelectClick(periodIdForbutton) {
    //Set the new selected period into redux
    // this.props.setCreateProjectPeriod(periodIdForbutton)
    this.setState({ selectedPeriod: periodIdForbutton });

    //Notify the column headers that the selected period has changed
    var event = {
      type: "selectedChanged",
      periodId: periodIdForbutton,
    };
    this.state.gridForFiltersApi.dispatchEvent(event);

    //Set the last column visible, and copy in the selected column values
    this.state.gridForFiltersApi
      .getModel()
      .rootNode.allLeafChildren.forEach((element) => {
        element.data.EditedGrade = this.GetGrade(element, periodIdForbutton);
      });

    var params = { force: true };
    this.state.gridForFiltersApi.refreshCells(params);

    this.state.gridForFiltersApi.setColumnsVisible(["EditedGrade"], true);
  }

  componentDidMount() {
    this.props.setMenu({ selectedMenu: Constants.MenuOptions.none });
    this.getSeasons();
    this.getGroups();
  }

  getSeasons() {
    api
      .getSeasons(this.props.settings.baseApiUrl)
      .then((data) => {
        const seasonsData = data.map((projectItem) => ({
          label: projectItem,
          value: projectItem,
        }));
        const projectSeason =
          seasonsData != null && seasonsData.length > 0
            ? seasonsData[0].value
            : null;
        this.setState({
          seasonsData: seasonsData,
          projectSeason,
          loadingSeasonsData: false,
        });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  getGroups() {
    api
      .getGroups(this.props.settings.baseApiUrl)
      .then((data) => {
        const groups = data.map((projectItem) => ({
          value: projectItem,
          label: projectItem,
          checked: false,
        }));
        this.setState({ groupsData: groups, loadingGroupsData: false });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  getSeasonsDepartments(seasons, groups) {
    const seasonNames = seasons.map((projectItem) => projectItem.value);
    api
      .getSeasonsDepartments(
        this.props.settings.baseApiUrl,
        seasonNames,
        groups
      )
      .then((data) => {
        const departmentsData = data.map((projectItem) => ({
          label: projectItem,
          value: projectItem,
        }));
        const { projectDepartments, useMainDepartmentControl } = this.state;
        let stillSelected = [];
        for (let i = 0; i < projectDepartments.length; i++) {
          for (let j = 0; j < departmentsData.length; j++) {
            if (projectDepartments[i].value === departmentsData[j].value) {
              stillSelected.push(departmentsData[j]);
            }
          }
        }

        this.setState({
          useMainDepartmentControl: !useMainDepartmentControl,
          departmentsData,
          projectDepartments: stillSelected,
        });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  getPlannerData() {
    const departmentNames = this.state.projectDepartments.map(
      (projectItem) => projectItem.value
    );
    const seasonNames = this.state.projectSeasons.map(
      (projectItem) => projectItem.value
    );

    api
      .getRangePlannerItemsForNewProjectAndSeasons(
        this.props.settings.baseApiUrl,
        seasonNames,
        departmentNames
      )
      .then((data) => {
        data.forEach((element) => {
          element.EditedGrade = null;
        });

        this.setState({ unfilteredRangeData: data, loadingPlannerData: false });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  nextDisabled() {
    const { projectSeason, projectGroups, projectDepartments, projectName } =
      this.state;
    if (
      projectSeason &&
      projectGroups &&
      projectGroups.length > 0 &&
      projectDepartments &&
      projectDepartments.length > 0 &&
      projectName &&
      projectName.length > 0
    ) {
      return false;
    }
    return true;
  }

  handleSeasonChange(seasonUpdate) {
    const { projectSeason, useMainDepartmentControl } = this.state;
    const newProjectSeason = seasonUpdate.target.value;

    if (projectSeason === newProjectSeason) {
      return;
    }

    this.setState({
      useMainDepartmentControl: !useMainDepartmentControl,
      projectSeason: newProjectSeason,
      projectGroups: [],
      departmentsData: [],
      projectDepartments: [],
    });
  }

  handleSeasonsChange(seasonUpdate) {
    const { useMainDepartmentControl } = this.state;

    let newProjectSeasons = seasonUpdate;

    this.setState({
      useMainDepartmentControl: !useMainDepartmentControl,
      projectSeasons: newProjectSeasons,
      projectGroups: [],
      departmentsData: [],
      projectDepartments: [],
    });
  }

  handleCancelClick(e) {
    e.preventDefault();

    if (true === window.confirm("Are you sure you want to cancel?")) {
      this.navigate(`/projects`);
    }
  }

  handleBackToProjectsClick(e) {
    e.preventDefault();
    this.navigate(`/projects`);
  }

  handleGroupsChange(groupsUpdate) {
    let { projectGroups } = this.state;

    if (groupsUpdate.currentTarget.checked) {
      projectGroups.push(groupsUpdate.currentTarget.value);
    } else {
      projectGroups = projectGroups.filter(function (value, index, arr) {
        return value !== groupsUpdate.currentTarget.value;
      });
    }

    this.setState({ projectGroups });
    this.getSeasonsDepartments(this.state.projectSeasons, projectGroups);
  }

  handleDepartmentChange(departmentsUpdate) {
    let projectDepartments = departmentsUpdate;
    this.setState({ projectDepartments });
  }

  HandleProjectNameChange(projectNameUpdate) {
    const firstChar = projectNameUpdate.substr(0, 1);
    if (firstChar === " ") {
      return;
    }
    if (!/[^0-9a-zA-Z& ,.)(/-]/.test(projectNameUpdate)) {
      if (projectNameUpdate.length <= 50) {
        let projectName = projectNameUpdate;
        this.setState({ projectName });
      }
    }
  }

  ContinueToFilter(e) {
    let loadingPlannerData = true;
    this.setState({ loadingPlannerData });
    this.setState({ projectName: this.state.projectName.trim() });
    this.getPlannerData();
    this.setState({ projectState: newProjectState.Filter });
  }

  handleBackToSetup() {
    this.setState({
      projectState: newProjectState.Setup,
      continueToFiltersValid: false,
      selectedPeriod: null,
    });
  }

  async ContinueToExport(e) {
    if (e !== null) {
      e.preventDefault();
    }

    if (this.state.savingProject === true) {
      return;
    }

    this.setState({ savingProject: true });

    // Need to save the project settings, filtered data, and the filters that created it
    let saveNewProject = {};
    saveNewProject.Project = {};
    saveNewProject.Project.Name = this.state.projectName;
    saveNewProject.Project.Season = this.state.projectSeason;
    saveNewProject.Project.Groups = this.state.projectGroups;
    saveNewProject.Project.Departments = this.state.projectDepartments.map(
      (projectItem) => projectItem.label
    );
    saveNewProject.Project.Filters = JSON.stringify(
      this.state.gridForFiltersApi.getFilterModel()
    );
    saveNewProject.Project.SelectedPeriod = this.state.selectedPeriod;
    saveNewProject.RangeItems = this.state.gridForFiltersApi
      .getModel()
      .rootNode.childrenAfterFilter.map((projectItem) => projectItem.data);
    let clone = this.props.projectToClone;

    api
      .postNewProject(
        this.props.settings.baseApiUrl,
        saveNewProject,
        clone ? clone.id : -1
      )
      .then((data) => {
        this.setState({
          projectId: data,
          projectState: newProjectState.Export,
          savingProject: false,
        });
      })
      .catch((e) => {
        api.handleError(this.props.router.location, e.message);
      });
  }

  handleExportsClick(e) {
    if (e !== null) {
      e.preventDefault();
    }

    if (this.state.isDownloadingPickList === true) {
      return;
    }

    this.setState({ isDownloadingPickList: true });

    let element = document.createElement("a");
    api
      .getProjectPickList(this.props.settings.baseApiUrl, this.state.projectId)
      .then((response) => {
        const fileDate = moment().format("DD-MM-YYYY [at] HH-mm");
        element.setAttribute(
          "download",
          `${fileDownloadFunctions.getFileName(
            response.headers
          )} ${fileDate}.csv`
        );
        return response.blob();
      })
      .then((blob) => {
        fileDownloadFunctions.setupAndClickLink(element, blob);
      })
      .then(() => {
        this.setState({ isDownloadingPickList: false });
      })
      .catch((e) => {
        this.setState({ isDownloadingPickList: false });
        api.handleError(this.props.router.location, e.message);
      });
  }

  GetGrade(projectItem, selectedPeriod) {
    switch (selectedPeriod) {
      case 1:
        return projectItem.data.storeGrade1;
      case 2:
        return projectItem.data.storeGrade2;
      case 3:
        return projectItem.data.storeGrade3;
      case 4:
        return projectItem.data.storeGrade4;
      case 5:
        return projectItem.data.storeGrade5;
      case 6:
        return projectItem.data.storeGrade6;
      case 7:
        return projectItem.data.storeGrade7;
      default:
        return null;
    }
  }

  onFilterGridReady = (params) => {
    this.setState({
      gridForFiltersApi: params.api,
    });
    params.api.autoSizeAllColumns();
    params.api.setFilterModel(this.state.filterModel);
  };

  generateGroupOptions() {
    let groupOptions = this.state.groupsData;
    groupOptions.forEach((go) => {
      let pg = this.state.projectGroups;
      if (pg.includes(go.value)) {
        go.checked = true;
      } else {
        go.checked = false;
      }
    });
    return groupOptions;
  }

  getSeasonData() {
    if (this.state.projectSeason) {
      return this.state.projectSeason;
    } else {
      return "";
    }
  }

  getProjectName() {
    if (this.state.projectName) {
      return this.state.projectName;
    } else {
      return "";
    }
  }

  render() {
    const {
      projectState,
      useMainDepartmentControl,
      seasonsData,
      departmentsData,
      projectDepartments,
      loadingSeasonsData,
      loadingGroupsData,
      loadingPlannerData,
      savingProject,
      defaultColDefForFilters,
      columnDefsForFilters,
      gridOptionsForFilters,
      unfilteredRangeData,
      frameworkComponents,
      projectSeasons,
    } = this.state;

    if (
      loadingSeasonsData ||
      loadingGroupsData ||
      loadingPlannerData ||
      savingProject ||
      false
    ) {
      return <LoadingIndicator />;
    }

    if (projectState === newProjectState.Setup || false) {
      return (
        <div>
          <div className="create-project-content">
            <Container size="lg" soft>
              <h2 className="ln-u-padding-top*4">Create a new project</h2>
              {this.renderStatus()}
              {this.props.projectToClone && (
                <div className="create-project-alert">
                  <svg
                    className="ln-c-icon"
                    role="img"
                    viewBox="0 0 24 24"
                    data-reactroot=""
                  >
                    <path
                      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1.54-14.872c0 .656-.496 1.184-1.152 1.184-.656 0-1.2-.528-1.2-1.184 0-.656.528-1.184 1.184-1.184.656 0 1.168.528 1.168 1.184zm-2.176 3.616h-.928V9.24h2.56c.368 0 .496.176.496.512v5.456c0 .336.128.48.384.48.192 0 .48-.112.608-.208l-.064 1.216c-.304.208-.832.4-1.408.4-.656 0-1.104-.192-1.36-.544-.208-.288-.288-.736-.288-1.248v-4.56z"
                      fill="currentColor"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                  You are creating a new project by cloning the grades, ranges
                  and layouts from {this.props.projectToClone.name}
                </div>
              )}
              <Card className="project-body">
                <div className="project-name">
                  <TextInputField
                    name="projectName"
                    label="Project name"
                    onChange={(e) =>
                      this.HandleProjectNameChange(e.currentTarget.value)
                    }
                    value={this.getProjectName()}
                  />
                </div>
                <div className="project-department">
                  <MultiAutocompleteField
                    name="seasonSelector"
                    placeholder="Please select"
                    label="Season"
                    options={seasonsData}
                    onSelect={(e) => this.handleSeasonsChange(e)}
                    defaultSelectedOptions={projectSeasons}
                  />
                </div>
                <div>
                  <CheckboxField
                    name="groupSelector"
                    label="Group"
                    options={this.generateGroupOptions()}
                    onChange={(e) => this.handleGroupsChange(e)}
                  />
                </div>
                <div className="project-department">
                  {useMainDepartmentControl === true && (
                    <MultiAutocompleteField
                      className={
                        this.state.projectGroups.length === 0
                          ? "new-project-departments--disabled"
                          : ""
                      }
                      name="departmentsSelector"
                      placeholder="Please select"
                      label="Departments"
                      options={departmentsData}
                      onSelect={(e) => this.handleDepartmentChange(e)}
                      defaultSelectedOptions={projectDepartments}
                    />
                  )}
                  {useMainDepartmentControl === false && (
                    <MultiAutocompleteField
                      className={
                        this.state.projectGroups.length === 0
                          ? "new-project-departments--disabled"
                          : ""
                      }
                      name="departmentsSelector"
                      placeholder="Please select"
                      label="Departments"
                      options={departmentsData}
                      onSelect={(e) => this.handleDepartmentChange(e)}
                      defaultSelectedOptions={projectDepartments}
                    />
                  )}
                </div>
              </Card>
              <div className="project-button-wrapper">
                <Button
                  variant="filled"
                  className="ln-c-right-button"
                  disabled={this.nextDisabled()}
                  onClick={() => this.ContinueToFilter(null)}
                >
                  Next
                </Button>
                <OutlinedButton
                  color="dark"
                  className="ln-c-right-button"
                  onClick={(e) => this.handleCancelClick(e)}
                >
                  Cancel
                </OutlinedButton>
              </div>
            </Container>
          </div>
        </div>
      );
    }

    if (projectState === newProjectState.Filter || false) {
      return (
        <div>
          <div className="create-project-content create-project-content--grid">
            <Container size="lg" soft className="create-project-grid-container">
              <h2 className="ln-u-padding-top*4">Create a new project</h2>
              {this.renderStatus()}
              <div className="project-body">
                <div className="project-grid-wrapper ag-theme-balham">
                  <AgGridReact
                    defaultColDef={defaultColDefForFilters}
                    columnDefs={columnDefsForFilters}
                    gridOptions={gridOptionsForFilters}
                    suppressDragLeaveHidesColumns={true}
                    components={frameworkComponents}
                    rowData={unfilteredRangeData}
                    onGridReady={this.onFilterGridReady}
                    floatingFilter={false}
                  ></AgGridReact>
                </div>
              </div>
              <div className="project-button-wrapper">
                <OutlinedButton
                  color="dark"
                  onClick={() => this.handleBackToSetup(null)}
                >
                  Back
                </OutlinedButton>
                <Button
                  variant="filled"
                  className="ln-c-right-button"
                  disabled={this.state.selectedPeriod === null}
                  onClick={(e) => this.ContinueToExport(e)}
                >
                  Complete
                </Button>
                <OutlinedButton
                  color="dark"
                  className="ln-c-right-button"
                  onClick={(e) => this.handleCancelClick(e)}
                >
                  Cancel
                </OutlinedButton>
              </div>
            </Container>
          </div>
        </div>
      );
    }

    if (projectState === newProjectState.Export || false) {
      return (
        <div>
          <div className="create-project-content">
            <Container size="lg" soft>
              <h2 className="ln-u-padding-top*4">Create a new project</h2>
              {this.renderStatus()}
              <Card className="project-body">
                <div className="ExportButtonCentreOuterLocation">
                  <div className="ExportButtonCentreInnerLocation">
                    <h2>Your project has been created</h2>
                    <p className="ln-u-margin-bottom*4">
                      To download and print the range plan for this project
                      select the download option below.
                    </p>
                  </div>
                  <div>
                    <OutlinedButton
                      color="dark"
                      onClick={(e) => this.handleBackToProjectsClick(e)}
                    >
                      Back to projects
                    </OutlinedButton>
                    <FilledButton onClick={(e) => this.handleExportsClick(e)}>
                      {this.renderDownloadButtonText()}
                    </FilledButton>
                  </div>
                </div>
              </Card>
            </Container>
          </div>
        </div>
      );
    }
  }

  renderDownloadButtonText() {
    if (this.state.isDownloadingPickList === true) {
      return <span>Downloading</span>;
    } else {
      return <span>Download range plan</span>;
    }
  }

  renderStatus() {
    let filterActive = this.state.projectState === newProjectState.Filter;
    let exportActive = this.state.projectState === newProjectState.Export;

    let check = <Tick />;
    let setupStatus = { circle: "circle", stage: 1 };
    let filterStatus = { circle: "circle circle-not-reached", stage: 2 };
    let exportStatus = { circle: "circle circle-not-reached", stage: 3 };

    if (filterActive) {
      setupStatus = { circle: "circle circle-done", stage: check };
      filterStatus = { circle: "circle", stage: 2 };
      exportStatus = { circle: "circle circle-not-reached", stage: 3 };
    }

    if (this.state.selectedPeriod !== null) {
      setupStatus = { circle: "circle circle-done", stage: check };
      filterStatus = { circle: "circle circle-done", stage: check };
      exportStatus = { circle: "circle", stage: 3 };
    }

    if (exportActive) {
      setupStatus = { circle: "circle circle-done", stage: check };
      filterStatus = { circle: "circle circle-done", stage: check };
      exportStatus = { circle: "circle circle-done", stage: check };
    }

    return (
      <div className="project-progress-header">
        <div className="project_stage_indicator">
          <div className="line line-left" />
          <div className={setupStatus.circle}>
            <span>{setupStatus.stage}</span>
          </div>
          <div className="project_stage_title">Set-up project</div>
        </div>
        <div className="project_stage_indicator">
          <div className="line" />
          <div className={filterStatus.circle}>
            <span>{filterStatus.stage}</span>
          </div>
          <div className="project_stage_title">Choose period</div>{" "}
        </div>
        <div className="project_stage_indicator">
          <div className="line line-right" />
          <div className={exportStatus.circle}>
            <span>{exportStatus.stage}</span>
          </div>
          <div className="project_stage_title">Check and edit grades</div>
        </div>
      </div>
    );
  }
}
CreateProject.propTypes = {
  router: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(CreateProject);
