import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import "./sass/index.scss";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/configureStore";

// Import actions
import { setUser } from "./store/user/actions";

import { api } from "./api/api";

import { setbaseApiUrl } from "./store/settings/actions";

import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { login, logout, msalInstance } from "./api/auth";
import InactivityTimeout from "./components/InactivityTimeout";
import CreateProject from "./components/Projects/CreateProject";
import EditProject from "./components/Projects/EditProject";
import ManageProject from "./components/Projects/ManageProject";
import ProjectsLandingPage from "./components/Projects/ProjectsLandingPage";
import ViewProject from "./components/Projects/ViewProject";

import ErrorBoundary from "./components/Controls/ErrorBoundary/ErrorBoundary";
import Error from "./components/Error";
import MainSideMenu from "./components/Menus/MainSideMenu";
// Import Constants
import Constants from "./utils/constants";

//AG Grid License
import { LicenseManager } from "ag-grid-enterprise";
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-049644}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{SAINSBURYS_SUPERMARKETS_LTD}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TuVm}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{TuVm}_need_to_be_licensed___{TuVm}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{23_February_2025}____[v3]_[01]_MTc0MDI2ODgwMDAwMA==05abc83f0b4d22c49d28e25bc529cfd3"
);

const mountElement = document.getElementById("root");

const history = createBrowserHistory();
const store = configureStore(history);

const baseUrl = window.location.origin
  ? `${window.location.origin}/`
  : `${window.location.protocol}/${window.location.host}/`;

const redirectUrlLocalStorageKey = "vm-redirectUrl";
const getRedirectUrl = () => {
  return localStorage.getItem(redirectUrlLocalStorageKey) || baseUrl;
};

const handleLogin = async () => {
  const redirectUrl = window.location.href;

  if (redirectUrl === baseUrl) {
    await login(getRedirectUrl());
  } else {
    localStorage.setItem(redirectUrlLocalStorageKey, redirectUrl);
    window.location.replace(baseUrl);
  }
  // localStorage.setItem(redirectUrlLocalStorageKey, redirectUrl);
  // await login(getRedirectUrl());
};

const handleRedirect = () => {
  // const redirectUrl = localStorage.getItem(redirectUrlLocalStorageKey) || baseUrl
  const redirectUrl = getRedirectUrl();
  if (redirectUrl !== baseUrl) {
    localStorage.setItem(redirectUrlLocalStorageKey, baseUrl);
    //   window.location.replace(redirectUrl)
    //    return true
  }
  //  return false
};

const StartApplication = async () => {
  try {
    api
      .getTheBaseApiUrl()
      .then((dataSettings) => {
        store.dispatch(
          setbaseApiUrl({
            baseApiUrl: dataSettings.VmWebsiteSettings.VmToolApiUrl,
          })
        );

        api
          .getConfig(dataSettings.VmWebsiteSettings.VmToolApiUrl)
          .then((config) => {
            // Get environment specific inactivity timeout, else fall back to default
            const inactivityTimeoutInSeconds =
              parseInt(config.inactivityTimeoutInSeconds, 10) ||
              Constants.InactivityTimeoutInSeconds;

            // Get authorisation
            api
              .getAuth(dataSettings.VmWebsiteSettings.VmToolApiUrl)
              .then((auth) => {
                // Check user is authorised.
                if (!auth) {
                  ReactDOM.createRoot(mountElement).render(
                    <div>There was a problem checking your authorisation.</div>
                  );
                  return;
                }

                // Check user is authorised.
                if (!auth.isAuthorized) {
                  if (
                    typeof auth.emailAddress !== "undefined" &&
                    auth.emailAddress !== null &&
                    auth.emailAddress.length > 0
                  ) {
                    ReactDOM.createRoot(mountElement).render(
                      <div>
                        You are not currently authorised to use this application
                        - {auth.emailAddress}
                      </div>
                    );
                    return;
                  }

                  logout();
                  return;
                }

                // set user in redux
                const user = {
                  userDisplayName: auth.displayName,
                  userDisplayCaps: auth.displayCaps,
                  userEmailAddress: auth.emailAddress,
                };
                store.dispatch(setUser(user));

                ReactDOM.createRoot(mountElement).render(
                  <MsalProvider instance={msalInstance}>
                    <AuthenticatedTemplate>
                      <React.Fragment>
                        <InactivityTimeout
                          timeoutInSeconds={inactivityTimeoutInSeconds}
                        />
                        <Provider store={store}>
                          <BrowserRouter history={history}>
                            <ErrorBoundary>
                              <React.Fragment>
                                <MainSideMenu />
                                <Routes>
                                  <Route
                                    path="/"
                                    exact
                                    element={<ProjectsLandingPage />}
                                  />
                                  <Route
                                    path="/projects"
                                    exact
                                    element={<ProjectsLandingPage />}
                                  />
                                  <Route
                                    path="/projects/newproject"
                                    exact
                                    element={<CreateProject />}
                                  />
                                  <Route
                                    path="/projects/:projectId/manageproject"
                                    exact
                                    element={<ManageProject />}
                                  />
                                  <Route
                                    path="/projects/:projectId/view"
                                    exact
                                    element={<ViewProject />}
                                  />
                                  <Route
                                    path="/projects/:projectId/grades/:gradeNumber/ranges/:rangeId/edit"
                                    exact
                                    element={<EditProject />}
                                  />
                                  <Route
                                    path="/error"
                                    exact
                                    element={<Error fullscreen={true} />}
                                  />
                                </Routes>
                              </React.Fragment>
                            </ErrorBoundary>
                          </BrowserRouter>
                        </Provider>
                      </React.Fragment>
                    </AuthenticatedTemplate>
                  </MsalProvider>
                );
              })
              .catch((error) => {
                console.error(error);
                ReactDOM.createRoot(mountElement).render(
                  <div>Application is not available</div>
                );
              });
          })
          .catch((error) => {
            ReactDOM.createRoot(mountElement).render(
              <div>Application is not available</div>
            );
          });
      })
      .catch((error) => {
        ReactDOM.createRoot(mountElement).render(
          <div>Application is not available</div>
        );
      });
  } catch (error) {
    ReactDOM.createRoot(mountElement).render(
      <div>Error starting application</div>
    );
  }

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
};

const main = async () => {
  await msalInstance.initialize();

  let redirectResponse = await msalInstance.handleRedirectPromise(
    window.location.hash
  );
  if (redirectResponse && redirectResponse.account) {
    // getAccessTokenBlob();
    handleRedirect();
    StartApplication();
  } else {
    await handleLogin();
    return;
  }
};

main();
