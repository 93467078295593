import React, { Component } from 'react';
import {connect} from 'react-redux';
import { List, ListItem } from '@jsluna/react';
import ImageCard from '../ImageCard/ImageCard';
import { ErrorCircle } from '@jsluna/icons';
import Constants from '../../../utils/constants'

export class ImageList extends Component{
	getRangePlannerData() {
		return this.props.maxLibraryImages !== Constants.allLibraryImagesValue ? this.props.rangePlannerData.slice(0, this.props.maxLibraryImages) : this.props.rangePlannerData
	}

	getMaxImagesDisplayed() {
		let maxImages = this.props.filteredResultData.length || 0
		if (this.props.maxLibraryImages !== Constants.allLibraryImagesValue && maxImages > this.props.maxLibraryImages) {
			maxImages = this.props.maxLibraryImages
		}
		return maxImages
	}
	
	render() {
		return (
			<div>
				<div id="totalFilteredItems">
					{`Clothing (${this.getMaxImagesDisplayed()}/${this.props.filteredResultData.length || 0})`}
				</div>
				{ this.props.filteredResultData.length === 0 ? 
					<div className={"NoSearchResults"}>  
						<ErrorCircle className={"ErrorCircle"}/> 
						<h4 className={"ln-u-h4"}>No Images Found</h4>
						<p className={"ln-u-body-2"}> Please update the applied filters and try again</p>
					</div> :
					<List 
						type="matrix" spaced                                                                                                                                         
						className="ImageLibraryList">
						{ 
							this.getRangePlannerData()
							.map( rangePlannerItem => (
								<ListItem key={rangePlannerItem.rangeId}>
									<ImageCard
										rangePlannerItem={rangePlannerItem}
										imageSelected={this.props.imageSelected}
										activeUsedImageCount={this.props.activeUsedImageCounts[rangePlannerItem.rangeId]}
									/>
								</ListItem>
							))
						}
					</List>
				}
			</div>
		)
	}
}

export default connect((state,props) => {
    return {
        isOpen: state.imageLibraryData.isFilterPanelOpen,
				filteredResultData: state.imageLibraryData.filteredResultData,
				maxLibraryImages: state.maxLibraryImages
    }})(ImageList);