import React from 'react'
import { PropTypes } from 'prop-types'
import { ButtonGroupPrimary, IconButton } from '@jsluna/react'
import { ArrowLeft, ArrowRight } from '@jsluna/icons'

function ListPagination(props) {
  return (
    <ButtonGroupPrimary className={`list-pagination ${props.className}`}>
      <IconButton
        data-testid="list-pagination-button"
        className={`list-pagination-button ${props.children != null ? 'ln-u-margin-right' : ''}`}
        onClick={props.enableLeft === true ? props.pageLeftClicked : null}
        variant="outlined"
        label="Page left"
        hideLabel
        color="dark"
        disabled={props.enableLeft === false}
      >
        <ArrowLeft />
      </IconButton>
      { props.children &&
        <div 
          className="list-pagination-label"
          data-testid="div-test">
          { props.children }
          
        </div>
      }
      <IconButton
        data-testid="list-pagination-button"
        className={"list-pagination-button ln-u-margin-left"}
        onClick={props.enableRight === true ? props.pageRightClicked : null}
        variant="outlined"
        label="Page left"
        hideLabel
        color="dark"
        disabled={props.enableRight === false}
      >
        <ArrowRight />
      </IconButton>
    </ButtonGroupPrimary>
  );
}

ListPagination.propTypes = {
  enableLeft: PropTypes.bool.isRequired,
  enableRight: PropTypes.bool.isRequired,
  pageLeftClicked: PropTypes.func.isRequired,
  pageRightClicked: PropTypes.func.isRequired
};

export default ListPagination