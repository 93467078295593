import React from 'react'
import { PropTypes } from 'prop-types'

class Countdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      seconds: this.props.durationInSeconds
    }

    this.timer = null
    this.countDown = this.countDown.bind(this)
  }

  componentDidMount() {
    this.timer = setInterval(this.countDown, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  countDown() {
    this.setState(prevState => ({ seconds: prevState.seconds - 1 }))
    if (this.state.seconds === 0) {
      this.props.onTimeout()
    }
  }

  render() {
    return (
      <span role="timer">{this.state.seconds}</span>
    )
  }
}

Countdown.propTypes = {
  durationInSeconds: PropTypes.number,
  onTimeout: PropTypes.func.isRequired
}

Countdown.defaultProps = {
  durationInSeconds: 60
}

export default Countdown
