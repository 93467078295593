import React from 'react'
import { PropTypes } from 'prop-types'
import {
  ButtonGroupWrapper, OutlinedButton, FilledButton,
  ModalHeading, Modal, Card, ButtonGroupPrimary
} from '@jsluna/react';
import { ErrorCircle } from '@jsluna/icons'
import ModalStylingFixForWin32 from '../../../common/ModalStylingFixForWin32'

function DeleteProjectModal(props) {
  return (
    <Modal
      restrictClose
      open={props.isOpen}
      className={"delete-range-modal"}
    >
      <Card className={"delete-range-modal-body"}>
        <ErrorCircle className="delete-range-modal-errorcirle ln-u-color-red" />
        <ModalHeading className={"modalheading"}>Are you sure you want to delete the project {props.project.name}?</ModalHeading>
        <p className={"delete-modal-description"}>Deleting this project will remove all grades, ranges, clothing layouts and the project range plan.</p>
        <p className={"delete-modal-description"}>You will not be able to undo this action.</p>
        <ButtonGroupWrapper actionBar className={"DeleteCancelButtonWrapper"}>
          <ButtonGroupPrimary>
            <FilledButton className={"yes-button"} onClick={props.onDeleteProject}>
              Delete Project
            </FilledButton>
            <OutlinedButton color="dark" className={"no-button"} onClick={props.onModalClose}>
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Card>
    </Modal>
  )
}

DeleteProjectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
}

export default ModalStylingFixForWin32(DeleteProjectModal)