import Constants from '../../constants'

const applyAllFiltersButThisOne = (rangePlannerData, filters, filterName) => {
  var result = rangePlannerData.filter(item =>
    (
      filters.styleFilter === "" ||
      filterName === "styleFilter" ||
      filters.styleFilter === item.style
    ) &&
    (
      filters.departmentFilter === "" ||
      filterName === "departmentFilter" ||
      filters.departmentFilter === item.department
    ) &&
    (
      filters.collectionFilter === "" ||
      filterName === "collectionFilter" ||
      filters.collectionFilter === item.collection
    ) &&
    (
      filters.classFilter === "" ||
      filterName === "classFilter" ||
      filters.classFilter === item.class
    ) &&
    (
      filters.colourFilter === "" ||
      filterName === "colourFilter" ||
      filters.colourFilter === item.colour) &&
    (
      filters.gradeFilter === "" ||
      filterName === "gradeFilter" ||
      parseInt(filters.gradeFilter) <= item.grade
    ))

  return result;
};

const getDistribution = (rangePlannerData, filters, property) => {
  var stateFilterName = property + "Filter";
  var workingSet = applyAllFiltersButThisOne(rangePlannerData, filters, stateFilterName);
  var values = [...new Set(workingSet.map(x => x[property]))].sort(function (a, b) { return a - b })
  var hitCounts = Array.from(values => 0);

  var propertyDistribution = [];

  if (stateFilterName === "styleFilter") {
    for (let i = 0; i < values.length; i++) {
      propertyDistribution.push({
        label: values[i],
        value: values[i]
      })
    }
    return propertyDistribution
  }

  for (let i = 0; i < values.length; i++) {
    hitCounts[i] = workingSet
      .filter(item => item[property] === values[i])
      .length;
  }
  
  if (stateFilterName === "gradeFilter") {
    var gradeFilterValues = [...new Set(rangePlannerData.map(x => x[property]))].sort(function (a, b) { return a - b })
    for (let i = 0; i < gradeFilterValues.length; i++) {
      hitCounts[i] = workingSet
        .filter(item => item[property] === gradeFilterValues[i])
        .length;
    }
    for (let i = 0; i < hitCounts.length; i++) {
      hitCounts[i] = hitCounts.reduce(
        function (a, b, x) {
          if (x > i) {
            return a + b
          }
          else {
            return a
          }
        }, hitCounts[i])
    }

    for (let i = 0; i < gradeFilterValues.length; i++) {
      propertyDistribution.push({
        label: gradeFilterValues[i] + " (" + hitCounts[i] + ")",
        value: gradeFilterValues[i]
      });
    }
    return propertyDistribution
  }

  var indexOfNotSet = -1
  for (let i = 0; i < values.length; i++) {
    // 'Not set' values are returned if the actual value is missing so skip this iteration and 
    // add a 'Not set' entry at the end of the list 
    if (values[i] === Constants.NotSetFilterValue) {
      indexOfNotSet = i
      continue
    }
    
    propertyDistribution.push({
      label: values[i] + " (" + hitCounts[i] + ")",
      value: values[i]
    });
  }

  // Add a 'Not set' entry if required
  if (indexOfNotSet > -1) {
    propertyDistribution.push({
      label: `${Constants.NotSetFilterLabel} (${hitCounts[indexOfNotSet]})`,
      value: Constants.NotSetFilterValue
    });
  }

  return propertyDistribution
};

const getUsedImageOptions = (filteredResultData, usedImageFilter) => {
  if (filteredResultData == null) {
    return ([
      { value: 'used', label: 'Used images', checked: usedImageFilter === 'used' },
      { value: 'unused', label: 'Unused images', checked: usedImageFilter === 'unused' },
      { value: 'all', label: 'All images', checked: usedImageFilter === 'all' || usedImageFilter === '' }
    ])
  }

  const savedUsedImageCounts = filteredResultData
        .filter(item => item.savedUsedImageCount > 0)
        .length;
  let options=[
    { value: 'used', label: `Used images (${savedUsedImageCounts})`, checked: usedImageFilter === 'used' },
    { value: 'unused', label: `Unused images (${filteredResultData.length - savedUsedImageCounts})`, checked: usedImageFilter === 'unused' },
    { value: 'all', label: `All images (${filteredResultData.length})`, checked: usedImageFilter === 'all' || usedImageFilter === '' }
  ]
  return options
}

const getDropdownValues = (rangePlannerData, filters, filteredResultData) => {
  var styleDistribution = getDistribution(rangePlannerData, filters, "style");
  var departmentDistribution = getDistribution(rangePlannerData, filters, "department");
  var collectionDistribution = getDistribution(rangePlannerData, filters, "collection");
  var gradeDistribution = getDistribution(rangePlannerData, filters, "grade");
  var classDistribution = getDistribution(rangePlannerData, filters, "class");
  var colourDistribution = getDistribution(rangePlannerData, filters, "colour");
  var usedImageOptions = getUsedImageOptions(filteredResultData, filters.usedImageFilter)

  let result = {
    styles: styleDistribution,
    departments: departmentDistribution,
    collections: collectionDistribution,
    grades: gradeDistribution,
    classes: classDistribution,
    colours: colourDistribution,
    usedImageOptions: usedImageOptions
  }

  return result;
};

export { getDropdownValues }