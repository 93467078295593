import * as PIXI from 'pixi.js'
import Gondola from './Gondola'
import Item from './Item'
import MergeManager from './MergeManager'
import Utils from './Utils'

export default class RangeBuilder extends PIXI.Container {
  topMargin = 80
  bottomMargin = 135
  mergePanelLocation = { top: 1, bottom: 2 }
  mergeTopGondolaY = 0
  mergeBottomGondolaY = Gondola.getGondolaHeight() + 150
  separatorLineProperties = {
    lineWidth: 1,
    colour: 0x000000,
    alpha: 1,
    dashLength: 10,
    marginTop: 30
  }

  constructor(gondolas, rangePlannerData, maxCanvasWidth, onRenderGondolasComplete, onScrollCallBack, setIsDirtyCallback, onItemsRemoved) {
    super()
    this.maxCanvasWidth = maxCanvasWidth
    this.selectedGondola = {}
    this.baysInRange = 0
    this.rangePlannerData = rangePlannerData
    this.onItemsRemoved = onItemsRemoved
    this.onRenderGondolasComplete = onRenderGondolasComplete
    this.selectedPrimaryGondolaIndex = -1
    this.selectedSecondaryGondolaIndex = -1
    this.selectedSecondaryRangeIndex = -1
    this.secondaryRangesLoaded = false
    this.deletedGondolas = []
    this.mergeModeOpen = false
    this.mergeManager = new MergeManager()
    this.newGondolaId = 0;
    this.handleItemScroll = onScrollCallBack
    this.setIsDirtyCallback = setIsDirtyCallback
    this._createDashedLine()
    this.renderGondolas(gondolas)
    this.setIsDirtyCallback(false)
  }

  renderGondolas(gondolaModels, addToExisting = false) {
    let topOfNextGondola = this.topMargin
    this.baysInRange = 0
    let gondolaIndex = 0
    if (addToExisting === true) {
      const existingGondolas = this.getGondolas()
      if (existingGondolas != null && existingGondolas.length > 0) {
        for (let i = 0; i < existingGondolas.length; i++) {
          //add a gondola to this range builder.
          let g = existingGondolas[i]
          this._createSnappingPoints(g)
          topOfNextGondola = g.y + Gondola.getGondolaHeight() + this.bottomMargin + this.topMargin
          this.baysInRange += g.bayCount
          gondolaIndex++
        }
      }
    } else {
      this.children = []
    }

    for (let i = 0; i < gondolaModels.length; i++) {
      //add a gondola to this range builder.

      let g = this._makeGondola(gondolaModels[i], gondolaIndex++)
      g.y = topOfNextGondola
      this._createSnappingPoints(g)
      this.addChild(g)
      topOfNextGondola += this.topMargin + Gondola.getGondolaHeight() + this.bottomMargin
      this.baysInRange += gondolaModels[i].bayCount

      if (addToExisting === false && gondolaModels.length === 1 && g.bayCount === 1) {
        g._checkCanDeleteBay0()
      }
    }

    const gondolas = this.getGondolas()
    if (gondolas != null && gondolas.length > 0) {
      gondolas[0].setSelectedBay(0)
    }

    this.onRenderGondolasComplete(this.getGondolaCount())
  }

  deleteBay(gondolaIndex, bayIndex) {
    let gondolas = this.children.filter(g => g.type === Gondola.typeIdentifier)
    if (gondolas.length > 0 && gondolas[gondolaIndex]) {
      gondolas[gondolaIndex].deleteBay(bayIndex)
    }
  }

  deleteGondola(gondola) {
    this.deletedGondolas.push(gondola)
    this.loadPrimaryGondolasFromBuildMode()
    let toKeep = this.children.filter(g => g.type === Gondola.typeIdentifier && g.gondolaIndex !== gondola.gondolaIndex)
    this.children = []
    let refreshedModels = []
    for (let c = 0; c < toKeep.length; c++) {
      refreshedModels.push(toKeep[c].toJson())
    }
    this.renderGondolas(refreshedModels)
    this.resizeCanvas()

    const removedItems = gondola.children.filter(g => g.type === Item.typeIdentifier)
    if (removedItems.length > 0) {
      this.onItemsRemoved(removedItems.map(i => i.rangeId))
    }
  }

  addItem(rangeId, selectedItemPhoto, rangeItemPhotos) {
    if (this.selectedGondola.addItem) {
      this.selectedGondola.addItem(rangeId, selectedItemPhoto, rangeItemPhotos, this.maxCanvasWidth, this)
    }
  }

  addGondolas(gondolasToAdd) {
    gondolasToAdd.map(i => {
      i.id = this.newGondolaId -= 1
      let bars = Gondola.createDefaultBars(i.bayCount)
      let json = []
      for (let x = 0; x < bars.length; x++) {
        bars[x].parent = { bayCount: i.bayCount }
        json.push(bars[x].toJson())
      }
      i.items = JSON.stringify(json)
      return null
    })


    this.renderGondolas(gondolasToAdd, true)
    this.resizeCanvas()
    this.setStateIsDirty(true)
    return true
  }

  setStateIsDirty(isDirty) {
    this.setIsDirtyCallback(isDirty)
  }

  switchToMergeMode() {
    //initalise the merge manager with the current primary range 
    this.loadPrimaryGondolasFromBuildMode()
    //destroy yhe stage graph and re add a single gondola
    this.children = []
    this.mergeModeOpen = true
    this.nextPrimaryGondola(true)
    this._showMergeSeperator()
    this.resizeCanvas()
  }

  setupEnablingCallbacksForGondolaNavigationControl(primGondolaChanged, secGondlaChanged) {
    this.primaryGondolaChanged = primGondolaChanged
    this.secondaryGondolaChanged = secGondlaChanged
  }

  _createDashedLine() {
    const g = new PIXI.Graphics()
    g.zIndex = 1
    g.clear()
    g.x = 0

    //g.y = Gondola.getGondolaHeight() + 50
    g.lineStyle(this.separatorLineProperties.lineWidth, this.separatorLineProperties.colour, this.separatorLineProperties.alpha)
    Utils.createDashedLine(g, 0, Gondola.getBayWidth(7), this.separatorLineProperties.dashLength, Gondola.getGondolaHeight() + this.separatorLineProperties.marginTop, true)
    this.mergeSeperator = g
    return g
  }

  _showMergeSeperator() {
    this.addChild(this.mergeSeperator)
  }

  _hideMergeSeperator() {
    this.removeChild(this.mergeSeperator)
  }

  reloadSavedResult(savedresults) {
    for (let i = 0; i < savedresults.length; i++) {
      let savedGondola = savedresults[i];
      if (savedGondola.preSavedGondolaId < 0) {
        //find the new gondola in the primary range
        for (let j = 0; j < this.mergeManager.getPrimaryRangeLength(); j++) {
          let currentGondola = this.mergeManager.getPrimaryGondola(j)
          if (currentGondola.gondolaId === savedGondola.preSavedGondolaId) {
            currentGondola.gondolaId = savedGondola.id
            this.mergeManager.setPrimaryGondola(currentGondola, j, !this.mergeModeOpen)
          }
        }
      }
    }
  }

  loadPrimaryGondolasFromBuildMode(doNotHideLabels = false) {
    let allGondolas = this.getGondolas()
    for (let i = 0; i < allGondolas.length; i++) {
      this.mergeManager.setPrimaryGondola(allGondolas[i], i, doNotHideLabels)
    }
    this.deletedGondolas.forEach(g => {
      //deleteed only from primary
      if (!g.deletedFromMergeManager) {
        this.mergeManager.removePrimaryGondola(g)
        g.deletedFromMergeManager = true
      }
    })
  }

  closeMergeMode() {
    let modelsForBuildMode = []
    //take the primaryrange out of merge manager 
    for (let i = 0; i < this.mergeManager.getPrimaryRangeLength(); i++) {
      let g = this.mergeManager.getPrimaryGondola(i)
      let j = g.toJson()
      modelsForBuildMode.push(j)
    }
    this._hideMergeSeperator()
    this.mergeModeOpen = false
    this.renderGondolas(modelsForBuildMode)
    this.bottomGondola = null
    this.resizeCanvas()
  }

  setSelectedRangeIndex(rangeIndex) {
    this.selectedSecondaryRangeIndex = rangeIndex
  }

  _setTopGondola(gondola, newGondolaIndex) {
    if (gondola) {
      if (this.topGondola) {
        this.topGondola.deselectItems()
        let oldGondola = this.topGondola
        this.removeChild(this.topGondola)
        this.mergeManager.setPrimaryGondola(oldGondola, this.selectedPrimaryGondolaIndex)
      }
      gondola.y = this.mergeTopGondolaY
      this.topGondola = gondola
      this.selectedPrimaryGondolaIndex = newGondolaIndex
      this.addChild(this.topGondola)
      this.selectedGondola = this.topGondola
      this.topGondola.deselectItems()
    }
  }

  _setBottomGondola(gondola, newGondolaIndex, newRangeIndex) {
    if (gondola) {
      if (this.bottomGondola) {
        let oldGondola = this.bottomGondola
        this.bottomGondola.deselectItems()
        this.removeChild(this.bottomGondola)
        this.mergeManager.setSecondaryGondola(oldGondola, this.selectedSecondaryRangeIndex, this.selectedSecondaryGondolaIndex)
      }
      gondola.y = this.mergeBottomGondolaY
      this.bottomGondola = gondola
      this.selectedSecondaryRangeIndex = newRangeIndex
      this.selectedSecondaryGondolaIndex = newGondolaIndex
      this.addChild(this.bottomGondola)
    }
  }

  _clearSelectedGondola() {
    this.selectedGondola = {}
  }

  _setSelectedGondolaAndBay0() {
    this.selectedGondola = this.topGondola;
    this.topGondola.setSelectedBay(0)
  }

  setStateForPrimaryNav() {
    let g = this.mergeManager.getPrimaryGondola(this.selectedPrimaryGondolaIndex)
    this.primaryGondolaChanged(this.selectedPrimaryGondolaIndex, this.mergeManager.getPrimaryRangeLength(), g.bayCount)
  }

  setStateForSecondaryNav() {
    let g = this.mergeManager.getSecondaryGondola(this.selectedSecondaryRangeIndex, this.selectedSecondaryGondolaIndex)
    let rangesCount = this.mergeManager.getSecondaryRangesLength()
    this.secondaryGondolaChanged(this.selectedSecondaryGondolaIndex, this.mergeManager.getSecondaryRangeLength(this.selectedSecondaryRangeIndex), g.bayCount, g.rangeName, this.selectedSecondaryRangeIndex, rangesCount)
  }

  nextPrimaryGondola(startOfRange = false) {

    if (startOfRange) {
      this.selectedPrimaryGondolaIndex = -1
    }
    if (this.selectedPrimaryGondolaIndex < this.mergeManager.getPrimaryRangeLength()) {
      let newGondola = this.mergeManager.getPrimaryGondola(this.selectedPrimaryGondolaIndex + 1)
      this._setTopGondola(newGondola, this.selectedPrimaryGondolaIndex + 1)
      this.setStateForPrimaryNav()
    }
    this._setSelectedGondolaAndBay0()
  }

  prevPrimaryGondola() {

    if (this.selectedPrimaryGondolaIndex > 0) {
      let newGondola = this.mergeManager.getPrimaryGondola(this.selectedPrimaryGondolaIndex - 1)
      this._setTopGondola(newGondola, this.selectedPrimaryGondolaIndex - 1)
      this.setStateForPrimaryNav()
    }
    this._setSelectedGondolaAndBay0()
  }

  nextSecondaryGondola() {
    if (this.selectedSecondaryGondolaIndex < this.mergeManager.getSecondaryRangeLength(this.selectedSecondaryRangeIndex)) {
      let newGondola = this.mergeManager.getSecondaryGondola(this.selectedSecondaryRangeIndex, this.selectedSecondaryGondolaIndex + 1)
      this._setBottomGondola(newGondola, this.selectedSecondaryGondolaIndex + 1, this.selectedSecondaryRangeIndex)
      this.setStateForSecondaryNav()
    }
    this._setSelectedGondolaAndBay0()
  }

  prevSecondaryGondola() {
    if (this.selectedSecondaryGondolaIndex > 0) {
      let newGondola = this.mergeManager.getSecondaryGondola(this.selectedSecondaryRangeIndex, this.selectedSecondaryGondolaIndex - 1)
      this._setBottomGondola(newGondola, this.selectedSecondaryGondolaIndex - 1, this.selectedSecondaryRangeIndex)
      this.setStateForSecondaryNav()
    }
    this._setSelectedGondolaAndBay0()
  }

  nextSecondaryRange() {
    if (this.selectedSecondaryRangeIndex < this.mergeManager.getSecondaryRangesLength()) {
      let newGondola = this.mergeManager.getSecondaryGondola(this.selectedSecondaryRangeIndex + 1, 0)
      this._setBottomGondola(newGondola, 0, this.selectedSecondaryRangeIndex + 1)
      this.setStateForSecondaryNav()
    }
    this._setSelectedGondolaAndBay0()
  }

  prevSecondaryRange() {

    if (this.selectedSecondaryRangeIndex > 0) {
      let newGondola = this.mergeManager.getSecondaryGondola(this.selectedSecondaryRangeIndex - 1, 0)
      this._setBottomGondola(newGondola, 0, this.selectedSecondaryRangeIndex - 1)
      this.setStateForSecondaryNav()
    }
    this._setSelectedGondolaAndBay0()
  }

  loadSecondaryRanges(ranges) {
    //selectedRangeINdex is already set 
    this.selectedSecondaryGondolaIndex = 0
    if (!this.secondaryRangesLoaded) {
      for (let i = 0; i < ranges.length; i++) {
        for (let r = 0; r < ranges[i].gondolas.length; r++) {
          let gondola = ranges[i].gondolas[r]
          gondola.rangeName = ranges[i].name
          let gondContainer = this._makeGondola(gondola, r)
          this._createSnappingPoints(gondContainer)
          this.mergeManager.setSecondaryGondola(gondContainer, i, r)
        }
      }
      this.secondaryRangesLoaded = true
    }
    let bottom = this.mergeManager.getSecondaryGondola(this.selectedSecondaryRangeIndex, this.selectedSecondaryGondolaIndex)
    this._setBottomGondola(bottom, this.selectedSecondaryGondolaIndex, this.selectedSecondaryRangeIndex)
    this.setStateForSecondaryNav()
    this.resizeCanvas()
  }

  deselectItems() {
    let allGondolas = this.children.filter(g => g.type === Gondola.typeIdentifier)
    allGondolas.map(g => g.deselectItems())
  }

  getGondolaCount() {
    return this.getGondolas().length
  }

  getGondolas() {
    return this.children.filter(g => g.type === Gondola.typeIdentifier)
  }

  _getRangeItemPhotos(rangeId) {
    return this.rangePlannerData.find(x => x.rangeId)
  }

  _findRangeData(rangeId) {
    const rangeData = this.rangePlannerData.find(p => p.rangeId === rangeId)
    const rangeItemPhotos = rangeData !== null ? rangeData.itemPhotos : null
    return rangeItemPhotos
  }

  _makeGondola(gondola, index) {
    let g = new Gondola(gondola, this._handleBaySelected, this._handleBayAdded, this.onItemsRemoved, index, this.baysInRange, this)
    if (gondola && gondola.items) {
      let gondolaItems = JSON.parse(gondola.items)
      gondolaItems.forEach(item => {
        if(item.children ) {
          item.children.forEach(child => 
            // Find rangeItemPhotos for each child
            child.rangeItemPhotos = this._findRangeData(child.rangeId)
          )
        }
        // Find rangeItemPhotos for individual item
        else if(item.hasOwnProperty("rangeId") && item.rangeId > 0){
          item.rangeItemPhotos = this._findRangeData(item.rangeId)
        } 
        g.loadItem(item, item.rangeItemPhotos, this.maxCanvasWidth, this)
      })
    }
    return g
  }

  _handleBaySelected = (gondola) => {
    this._deselectBaysForOtherGondolas(gondola)
  }

  _handleBayAdded = () => {
    let startingIndex = 0
    const gondolas = this.getGondolas()
    for (let i = 0; i < gondolas.length; i++) {
      let g = gondolas[i]
      if (g.rerenderLabels) {
        g.rerenderLabels(startingIndex)
        g.xSnappingPoints = g.createXSnappingPoints()
        startingIndex += g.bayCount
      }
    }
  }

  _deselectAllBays() {
    for (let i = 0; i < this.children.length; i++) {
      let g = this.children[i]
      if (g.deselectBays) {
        g.deselectBays();
      }
    }
  }

  _deselectBaysForOtherGondolas = (gondola) => {
    for (let i = 0; i < this.children.length; i++) {
      let g = this.children[i]
      if (gondola !== g && g.deselectBays) {
        g.deselectBays();
      }
    }
  }

  _clearContainer() {
    this.children = []
  }

  getGondolasToSave() {

    let toSave = []
    //pull buildmode gonds into merge manger but only if its open
    //if its not, what we have in MM is most curent
    if (!this.mergeModeOpen) {
      if (!this.mergeManager) {
        this.mergeManager = new MergeManager()
      }
      this.loadPrimaryGondolasFromBuildMode(true)
    }

    for (let i = 0; i < this.mergeManager.getPrimaryRangeLength(); i++) {
      let g = this.mergeManager.getPrimaryGondola(i)
      let j = g.toJson()
      toSave.push(j)
    }

    for (let i = 0; i < this.mergeManager.getSecondaryRangesLength(); i++) {
      for (let g = 0; g < this.mergeManager.getSecondaryRangeLength(i); g++) {
        let sg = this.mergeManager.getSecondaryGondola(i, g)
        let jsg = sg.toJson()
        toSave.push(jsg)
      }
    }
    return toSave
  }

  _createSnappingPoints(gondolaObject) {
    gondolaObject.xSnappingPoints = gondolaObject.createXSnappingPoints()
    gondolaObject.ySnappingPoints = gondolaObject.createYSnappingPoints()
  }
}
