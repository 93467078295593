import * as PIXI from 'pixi.js'
import deleteButtonDefault from '../assets/images/delete-button-default.png'
import deleteButtonDisabled from '../assets/images/delete-button-disabled.png'
import deleteButtonHovered from  '../assets/images/delete-button-hovered.png'
import buttonFrontDefault from '../assets/images/button-front.png'
import buttonFrontDefaultHovered from  '../assets/images/button-front-hovered.png'
import buttonFrontSelected from '../assets/images/button-front-selected.png'
import buttonFrontSelectedHovered from  '../assets/images/button-front-selected-hovered.png'
import buttonFrontDisabled from  '../assets/images/button-front-disabled.png'
import buttonSideDefault from '../assets/images/button-side.png'
import buttonSideDefaultHovered from  '../assets/images/button-side-hovered.png'
import buttonSideSelected from '../assets/images/button-side-selected.png'
import buttonSideSelectedHovered from  '../assets/images/button-side-selected-hovered.png'
import buttonSideDisabled from  '../assets/images/button-side-disabled.png'
import buttonBackDefault from '../assets/images/button-back.png'
import buttonBackDefaultHovered from  '../assets/images/button-back-hovered.png'
import buttonBackSelected from '../assets/images/button-back-selected.png'
import buttonBackSelectedHovered from  '../assets/images/button-back-selected-hovered.png'
import buttonBackDisabled from  '../assets/images/button-back-disabled.png'
import HoverButton from './HoverButton'
import ItemHoverButton from './ItemHoverButton'

class ItemControls extends PIXI.Container {
  constructor(parentLeftX, parentRightX, y, parentHeight, orientation, itemPhotos, onOrientationButtonClicked, onDeleteButtonClicked, anchorRight = true) {
      super()
      
      this.type = ItemControls.typeIdentifier

      this.xOffset = 3
      this.yOffset = 0
      
      this.buttonProperties = {
        scale: 1,
        width: 49,
        height: 49,
        verticalMargin: 1
      }
      this.separatorProperties = {
        horizontalMargin: 8,
        verticalMargin: 8,
        width: 41,
        lineWidth: 2,
        colour: 0x8E8E8E,
        alpha: 1
      }
      this.buttonFrameProperties = {
        lineWidth: 1,
        colour: 0x0C0C0C,
        alpha: 1,
        margin: 4,
        width: this.buttonProperties.width + 8,
        height: (this.buttonProperties.height * 4) + (2 * this.buttonProperties.verticalMargin) + (2 * this.separatorProperties.verticalMargin) + this.separatorProperties.lineWidth + 8
      }

      this.rightX = parentRightX
      this.leftX = parentLeftX - this.buttonFrameProperties.width - (2 * this.xOffset)
      this.bottomY = y
      this.topY = this.buttonFrameProperties.height - parentHeight > 0 ? y - (this.buttonFrameProperties.height - parentHeight) : y
      this.x = anchorRight ? this.rightX: this.leftX
      this.y = y
      this.itemPhotos = itemPhotos

      this.orientation = orientation
      this.previousOrientation = orientation

      this.orientationButtons = [
        { orientation: 'F', button: null },
        { orientation: 'S', button: null },
        { orientation: 'B', button: null }]

      this.click = this.onClick
      this.pointerdown = this.onClick
      this.cursor = 'default'
      
      this._createControls(onOrientationButtonClicked, onDeleteButtonClicked)
  }

  onClick(e) {
    // Don't propagate click events for ItemControls
    if (e != null) {
      e.stopPropagation()
    }
  }

  moveLeft() {
    if (this.x !== this.leftX) {
      this.x = this.leftX
    }
  }

  moveRight() {
    if (this.x !== this.rightX) {
      this.x = this.rightX 
    }
  }

  moveUp() {
    if (this.y !== this.topY) {
      this.y = this.topY
    }
  }

  moveDown() {
    if (this.y !== this.bottomY) {
      this.y = this.bottomY
    }
  }

  getTotalHeight() {
    return this.height + this.yOffset
  }

  getTotalWidth() {
    return this.width + this.xOffset
  }

  revertOrientation() {
    this.orientation = this.previousOrientation
    this._enableDisableOrientationButtons()
  }

  _handleOrientationButtonClicked(e, orientation, onOrientationButtonClicked) {
    if (this.orientation === orientation) {
      return
    }
    this.previousOrientation = this.orientation
    this.orientation = orientation
    this._enableDisableOrientationButtons()
    onOrientationButtonClicked(e, orientation)
  }

  _enableDisableOrientationButtons() {
    this.orientationButtons.forEach(orientationButton => {
      const itemPhoto = this.itemPhotos.find(i => i.orientation === orientationButton.orientation)
      
      if (itemPhoto == null) {
        orientationButton.button.disable()
      } else {
        orientationButton.button.setSelected(itemPhoto.orientation === this.orientation)
      }
    })
  }

  _createControls(onOrientationButtonClicked, onDeleteButtonClicked) {
    const buttonFrame = this._createSelectFrame(this.xOffset, this.yOffset)
    
    let nextY = this.yOffset + this.buttonFrameProperties.margin
    this.orientationButtons[0].button = this._createFrontButton(nextY, (e) => this._handleOrientationButtonClicked(e, this.orientationButtons[0].orientation, onOrientationButtonClicked))
    buttonFrame.addChild(this.orientationButtons[0].button)
    
    nextY += this.buttonProperties.height + this.buttonProperties.verticalMargin
    this.orientationButtons[1].button = this._createSideButton(nextY, (e) => this._handleOrientationButtonClicked(e, this.orientationButtons[1].orientation, onOrientationButtonClicked))
    buttonFrame.addChild(this.orientationButtons[1].button)

    nextY += this.buttonProperties.height + this.buttonProperties.verticalMargin
    this.orientationButtons[2].button = this._createBackButton(nextY, (e) => this._handleOrientationButtonClicked(e, this.orientationButtons[2].orientation, onOrientationButtonClicked))
    buttonFrame.addChild(this.orientationButtons[2].button)

    nextY += this.buttonProperties.height + this.separatorProperties.verticalMargin
    buttonFrame.addChild(this._createButtonSeparator(nextY))

    nextY += this.separatorProperties.lineWidth + this.separatorProperties.verticalMargin
    buttonFrame.addChild(this._createDeleteButton(nextY, (e) => onDeleteButtonClicked(e)))

    this._enableDisableOrientationButtons()

    this.addChild(buttonFrame)
  }

  _createSelectFrame(x, y) {
    const buttonFrame = new PIXI.Graphics()
		buttonFrame.clear()
		buttonFrame.lineStyle(this.buttonFrameProperties.lineWidth, this.buttonFrameProperties.colour, this.buttonFrameProperties.alpha)
		buttonFrame.drawRect(x, y, this.buttonFrameProperties.width, this.buttonFrameProperties.height)
    buttonFrame.endFill()
    buttonFrame.visibleForMerge = true
    return buttonFrame
  }

  _createFrontButton(y, onFrontButtonClicked) {
    const frontButton = new ItemHoverButton(
      onFrontButtonClicked,
      buttonFrontDefault,
      buttonFrontDefaultHovered,
      buttonFrontDisabled,
      buttonFrontSelected,
      buttonFrontSelectedHovered
    )
    frontButton.anchor.set(0, 0)
    frontButton.x = this.xOffset + this.buttonFrameProperties.margin
    frontButton.y = y
    frontButton.visibleForMerge = true
    return frontButton
  }

  _createSideButton(y, onSideButtonClicked) {
    const sideButton = new ItemHoverButton(
      onSideButtonClicked,
      buttonSideDefault,
      buttonSideDefaultHovered,
      buttonSideDisabled,
      buttonSideSelected,
      buttonSideSelectedHovered
    )
    sideButton.anchor.set(0, 0)
    sideButton.x = this.xOffset + this.buttonFrameProperties.margin
    sideButton.y = y
    sideButton.visibleForMerge = true
    return sideButton
  }

  _createBackButton(y, onBackButtonClicked) {
    const backButton = new ItemHoverButton(
      onBackButtonClicked,
      buttonBackDefault,
      buttonBackDefaultHovered,
      buttonBackDisabled,
      buttonBackSelected,
      buttonBackSelectedHovered
    )
    backButton.anchor.set(0, 0)
    backButton.x = this.xOffset + this.buttonFrameProperties.margin
    backButton.y = y
    backButton.visibleForMerge = true
    return backButton
  }

  _createButtonSeparator(y) {
    const buttonSeparator = new PIXI.Graphics()
		buttonSeparator.clear()
		buttonSeparator.lineStyle(this.separatorProperties.lineWidth, this.separatorProperties.colour, this.separatorProperties.alpha)
		buttonSeparator.moveTo(this.xOffset + this.separatorProperties.horizontalMargin, y)
    buttonSeparator.lineTo(this.xOffset + this.separatorProperties.horizontalMargin + this.separatorProperties.width, y)
    buttonSeparator.visibleForMerge = true
    return buttonSeparator
  }

  _createDeleteButton(y, onDeleteButtonClicked) {
    const deleteButton = new HoverButton(
      onDeleteButtonClicked,
      deleteButtonDefault,
      deleteButtonHovered,
      deleteButtonDisabled
    )
    deleteButton.anchor.set(0, 0)
    deleteButton.x = this.xOffset + this.buttonFrameProperties.margin
    deleteButton.y = y
    deleteButton.visibleForMerge = true
    return deleteButton
  }

  static typeIdentifier = "ItemControls"
}

export default ItemControls