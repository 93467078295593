import React from 'react'
import { PropTypes } from 'prop-types'
import { IconButton, ButtonGroupWrapper, OutlinedButton, ListGroup, ListGroupItem } from '@jsluna/react'
import {  Plus, ArrowRight } from '@jsluna/icons'

function GradeList(props) {
  const renderGradeList = grades => {
    const gradeList = []
    grades.map((grade) => (
      gradeList.push(renderGradeListItem(grade))
    ))
    return gradeList
  }

  const renderGradeListItem = grade => {
      return (
      <ListGroupItem data-testid='list-group-item' key={grade.gradeNumber.toString()} active={grade.gradeNumber === props.activeGradeNumber} onClick={(e) => props.onGradeItemClick(e, grade) }>
        { `Grade ${grade.gradeNumber} `}
        <ArrowRight />
      </ListGroupItem>
      );
  }

  return (
    <div className="grade-list"> 
      <div className="grade-list-content">
        <h4>Grades</h4>
        <p data-testid="paragraph">
          To view and edit a grade select a grade from the menu below
        </p>
        <ListGroup 
          data-testid="list-group"
          actionable type="full">
          { renderGradeList(props.grades) }
        </ListGroup>
        <IconButton data-testid='icon-button' variant="filled" label="Add grade" onClick={props.onAddGradeClick} disabled={props.disableAddGrade}>
          <Plus />
        </IconButton>
        <OutlinedButton color="dark" className="grade-list-delete-button" onClick={props.onDeleteGradeClick} disabled={props.grades.length <= 1}>
        Delete a grade
        </OutlinedButton>
      </div>
      <ButtonGroupWrapper className="grade-list-close-button">
        <OutlinedButton data-testid='outlined-button' color="dark" onClick={props.onCloseGradeMenuClick}>
          Close
        </OutlinedButton>
      </ButtonGroupWrapper>
    </div>
  )
}

GradeList.propTypes = {
  grades: PropTypes.arrayOf(
    PropTypes.shape({
      gradeId: PropTypes.number.isRequired,
      gradeNumber: PropTypes.number.isRequired
    })).isRequired,
  activeGradeNumber: PropTypes.number.isRequired,
  onCloseGradeMenuClick: PropTypes.func.isRequired,
  onGradeItemClick: PropTypes.func.isRequired,
  onAddGradeClick: PropTypes.func.isRequired,
  onDeleteGradeClick: PropTypes.func.isRequired,
  disableAddGrade: PropTypes.bool.isRequired
}

export default GradeList
