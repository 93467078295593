export const actions = {
    SET_STYLE_FILTER: 'SET_STYLE_FILTER',
    SET_CURRENT_STYLE_INPUT_VALUE: 'SET_CURRENT_STYLE_INPUT_VALUE',
    SET_RANGEPLANNER_DATA: 'SET_RANGEPLANNER_DATA',
    UPDATE_RANGE_PLANNER_DATA: "UPDATE_RANGE_PLANNER_DATA",
    SET_DEPARTMENT_FILTER: 'SET_DEPARTMENT_FILTER',
    SET_COLLECTION_FILTER: 'SET_COLLECTION_FILTER',
    SET_GRADE_FILTER: "SET_GRADE_FILTER",
    SET_CLASS_FILTER: "SET_CLASS_FILTER",
    SET_COLOUR_FILTER: "SET_COLOUR_FILTER",
    SET_USED_IMAGE_FILTER: "SET_USED_IMAGE_FILTER",
    TOGGLE_FILTER_PANEL: "TOGGLE_FILTER_PANEL",
    DISABLE_DROPDOWNS: "DISABLE_DROPDOWNS"
}

export function disableDropdowns(value){
    return {type: actions.DISABLE_DROPDOWNS, data: value}
}

export function setStyleFilter(value){
    return {type: actions.SET_STYLE_FILTER, data: value}
}

export function setCurrentStyleInputValue(value){
    return {type: actions.SET_CURRENT_STYLE_INPUT_VALUE, data: value}
}

export function setRangePlannerData(data){
    return {type: actions.SET_RANGEPLANNER_DATA, data: data}
}

export function updateRangePlannerData(data){
    return {type: actions.UPDATE_RANGE_PLANNER_DATA, data: data}
}

export function setDepartmentFilter(value){
    return {type: actions.SET_DEPARTMENT_FILTER, data: value}
}

export function setCollectionFilter(value){
    return {type: actions.SET_COLLECTION_FILTER, data: value}
}

export function setGradeFilter(value){
    return {type: actions.SET_GRADE_FILTER, data: value}
}

export function setClassFilter(value){
    return {type: actions.SET_CLASS_FILTER, data: value}
}

export function setColourFilter(value){
    return {type: actions.SET_COLOUR_FILTER, data: value}
}

export function setUsedImageFilter(value){
    return {type: actions.SET_USED_IMAGE_FILTER, data: value}
}

export function toggleFilterPanel(){
    return {type: actions.TOGGLE_FILTER_PANEL}
}

export default actions