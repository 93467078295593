import React from 'react'
import { PropTypes } from 'prop-types'
import ModalConfirm from '../../ModalConfirm'
import Countdown from '../../Controls/Countdown'

const TimeoutModal = (props) => {
  return (
    <ModalConfirm
      isOpen={Boolean(props.isOpen)}
      confirmFunction={props.confirmFunction}
      cancelFunction={props.cancelFunction}
      confirmButtonText={'Stay logged in'}
      cancelButtonText={'Log out'}
    >
      <div>
        <h3 role="alert">Timeout warning</h3>
        <p>We haven&apos;t detected you using the Visual Merchandising tool for a while. So, to help keep our data secure, we&apos;ll log you out in</p>
        <div className="c-countdown">
          <Countdown onTimeout={props.cancelFunction} />
          <span className="c-countdown-footer">seconds</span>
        </div>
        <p>Do you want to remain logged in?</p>
      </div>
    </ModalConfirm>
  )
}

TimeoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  confirmFunction: PropTypes.func.isRequired,
  cancelFunction: PropTypes.func.isRequired
}

export default TimeoutModal