import * as PIXI from 'pixi.js'
import { api } from '../api/api'
import Gondola from './Gondola'
import DraggableSprite from './DraggableSprite'
import ItemControls from './ItemControls'
import Bar from './Bar'

export default class Item extends DraggableSprite {

  selectFrameProperties = {
    lineWidth: 1.5,
    colour: 0xF06C00,
    alpha: 1
  }

  barDifference = 2

  constructor(imageId, imageUrl, rangeId, orientation, rangeItemPhotos, maxCanvasWidth, parentRelativePos, position, rangeBuilder, onItemDelete) {
    super(position)
    this.type = Item.typeIdentifier
    this.maxCanvasWidth = maxCanvasWidth
    this.rangeId = rangeId
    this.orientation = orientation
    this.itemPhotos = this._createImageList(imageId, imageUrl, rangeId, orientation, rangeItemPhotos)
    this.imageTextures = {}
    this.buttonMode = true
    this.sortableChildren = true 
    this.rangeBuilder = rangeBuilder
    this.visibleForMerge = true
    this.selected = false
    this.onItemDelete = onItemDelete
    this.itemControls = null
    this._loadImageTexture(orientation)
    this.gondolaRelativePosition = parentRelativePos
  }

  // Create a list of images for the different orientations. Use the selected image for the selected location but
  // use the range item photos for the other orientations
  _createImageList(imageId, imageUrl, rangeId, orientation, rangeItemPhotos) {
    const itemPhotos = []
    rangeItemPhotos.forEach(itemPhoto => {
      if (itemPhoto.orientation === orientation) {
        itemPhotos.push({
          imageId: imageId,
          imageUrl: imageUrl,
          rangeId: rangeId,
          orientation: orientation
        })
      } else {
        itemPhotos.push({
          imageId: itemPhoto.imageId,
          imageUrl: itemPhoto.resizedUri,
          rangeId: itemPhoto.rangeId,
          orientation: itemPhoto.orientation
        })
      }
    })
    
    return itemPhotos
  }

  toJson() {
    const itemPhoto = this.itemPhotos.find(o => o.orientation === this.orientation)
    //(imageId, imageUrl, rangeId, orientation, position,
    // if item is a child of bar
    if (this.parent.type === Bar.typeIdentifier) {
      
      return {
        imageId: itemPhoto.imageId,
        imageUrl: itemPhoto.imageUrl,
        rangeId: itemPhoto.rangeId,
        orientation: itemPhoto.orientation,
        //x: 1/(Gondola.getBayWidth(this.parent.parent.bayCount) / this.gondolaRelativePosition.x), // gondolaRelative position
        //y: 1/(Gondola.getGondolaHeight() / (this.parent.y - Bar.getBarHeight() / 2)+ (this.height / 2)),
        x: 1/ (Bar.getBarWidth() / this.x),
        y: 1/ (Bar.getBarHeight() / this.y),
        parentType: this.parent.type,
        parentRelativeXPos: 1/(Gondola.getBayWidth(this.parent.parent.bayCount) / this.parent.x),
        parentRelativeYPos: 1/(Gondola.getGondolaHeight() / this.parent.y)
      }
    }
    else {
      return {
        imageId: itemPhoto.imageId,
        imageUrl: itemPhoto.imageUrl,
        rangeId: itemPhoto.rangeId,
        orientation: itemPhoto.orientation,
        x: 1/(Gondola.getBayWidth(this.parent.bayCount) / this.x),
        y: 1/(Gondola.getGondolaHeight() / this.y)
      }
    }
  }

  drawSelectedGraphics() {
    const itemWidth = this.width 
    const itemHeight = this.height
    const itemX = (itemWidth * this.anchor.x) * -1
    const itemY = (itemHeight * this.anchor.y) * -1
    const selectFrame = new PIXI.Graphics()
    selectFrame.visibleForMerge = true
    selectFrame.clear()
    selectFrame.lineStyle(this.selectFrameProperties.lineWidth, this.selectFrameProperties.colour, this.selectFrameProperties.alpha)
    selectFrame.drawRect(itemX, itemY, itemWidth, itemHeight)
    selectFrame.endFill()
    
    this.itemControls = new ItemControls(itemX, itemX + itemWidth, itemY, itemHeight, this.orientation, this.itemPhotos, (e, orientation) => this._handleOrientationButtonClicked(e, orientation), (e) => this.onItemDeleteClicked(e))
    this.itemControls.visible = false
    this._showItemControls()

    selectFrame.addChild(this.itemControls)
    return selectFrame
  }

  onItemDeleteClicked(e) {
    e.stopPropagation(); 
    this.parent.removeChild(this);
    this.onItemDelete(this);
  }

  onStartDragMoveCallback() {
    this._hideItemControls()
  }

  onDragEndCallback() {
    this._showItemControls()
  }

  _getRightEdgeX() {
    return this.x - (this.anchor.x * this.width) + this.width
  }

  _getTopEdgeY() {
    return this.y - (this.anchor.y * this.height)
  }

  _showItemControls() {
    if (this.itemControls == null || (this.parent instanceof Gondola) === false) {
      return
    }

    // If the right hand side of the item controls exceeds the maximum canvas width,
    // move the item controls to the left of the item. Otherwise move them to the right.
    if (this._getRightEdgeX() + this.itemControls.getTotalWidth() >= this.maxCanvasWidth) {
      this.itemControls.moveLeft()
    } else {
      this.itemControls.moveRight()
    }

    // If the bottom edge of the item controls is below the bottom of the gondola,
    // move the bottom of the item controls in line with the bottom of the image.
    // Otherwise move them in line with the top of the image.
    if (this._getTopEdgeY() + this.itemControls.getTotalHeight() >= Gondola.getGondolaHeight()) {
      this.itemControls.moveUp()
    } else {
      this.itemControls.moveDown()
    }

    this.itemControls.visible = true
  }
  
  _hideItemControls() {
    if (this.itemControls == null) {
      return
    }
    this.itemControls.visible = false
	}

  async _loadImageTexture(orientation) {
    try {
      this.texture = await this._getImageTexture(orientation)
    } catch(e) {
      this.texture = null
    }
  }

  async _getImageTexture(orientation) {
    let imageTexture = this.imageTextures[orientation]
    
    try {
      if (imageTexture == null) {
        const itemPhoto = this.itemPhotos.find(o => o.orientation === orientation)
        if (itemPhoto != null) {
          const d = await api.getImageFromBlob(itemPhoto.imageUrl)
          imageTexture = PIXI.Texture.from(d)
          imageTexture.orientation = orientation
          this.imageTextures[orientation] = imageTexture
        }
      }
    } catch(e) {
      throw e
    }

    return imageTexture
  }
  
  async _handleOrientationButtonClicked(e, orientation) {
    e.stopPropagation()

    const itemPhoto = this.itemPhotos.find(o => o.orientation === orientation)

    if (itemPhoto) {
      let newTexture = null
      try {
        newTexture = await this._getImageTexture(orientation, true)
      } catch {
        this.itemControls.revertOrientation()
        return
      }
      
      if (newTexture.baseTexture.valid) {
        this._changeOrientation(newTexture, orientation)
      } else {        
        newTexture.on('update', () => this._changeOrientation(newTexture, orientation))
      }
    }
  }

  _changeOrientation(newTexture, newOrientation) {
    const itemPhoto = this.itemPhotos.find(o => o.orientation === newOrientation)
    
    if (itemPhoto) {
      const topOfImage = this.position.y - (this.height / 2)
      const topOfNewImage = this.position.y - (newTexture.height / 2)
      const diffY = topOfImage - topOfNewImage
      const newY = this.position.y + diffY
      this.texture = newTexture
      this.position.y = newY
      this.rangeId = itemPhoto.rangeId
      this.orientation = itemPhoto.orientation
      this.redrawFrames()
    }
  }

  _attachToParentBar(parentBar) {
    parentBar.addChild(this)
  }

  _findBarItem(spriteType, topOfImage, itemCentreX) {
    const barLeft = spriteType.x - (spriteType.anchor.x * spriteType.width)
    const barRight = barLeft + spriteType.width
    const barY = spriteType.position.y
    if ( (Math.abs(topOfImage - barY) <= 12 || Math.abs(barY - topOfImage) <= 6) && (itemCentreX > barLeft && itemCentreX < barRight)) {
      return true
    }
    return false
  }

  onSnapToBarCallback(e) {
    const parentGondola = this.parent
    const currentX = this.x
    const topOfImage = this.y - (this.height / 2)
    var findBar = parentGondola.children.find(spriteType => spriteType.type === Bar.typeIdentifier && this._findBarItem(spriteType, topOfImage, currentX))
    if (findBar != null) {
      
      // this.position.y = findBar.y + ( this.height / 2 ) - (findBar.height / 2) old snap pos
      this._attachToParentBar(findBar)
      
      // Bring parent bar to front by changing its child index in the gondola
      parentGondola.setChildIndex(findBar, parentGondola.children.length - 1)
      
      //work out the x position after its became a child. currently will go to middle of bar as anchor is 0,0
      this.x = (currentX - findBar.x)
      this.y = ( this.height / 2 ) - (findBar.height / this.barDifference)
      this.gondolaRelativePosition = e.data.getLocalPosition(this.parent.parent)
    }
    else {
      this.gondolaRelativePosition.x = this.x
      this.gondolaRelativePosition.y = this.y
    }
  }

  static typeIdentifier = "Item"
}
