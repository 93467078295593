import * as PIXI from 'pixi.js'
import bayImage1 from '../assets/images/buildmode/gondola-01.png'
import bayImage2 from '../assets/images/buildmode/gondola-02.png'
import bayImage3 from '../assets/images/buildmode/gondola-03.png'
import bayImage4 from '../assets/images/buildmode/gondola-04.png'
import bayImage5 from '../assets/images/buildmode/gondola-05.png'
import bayImage6 from '../assets/images/buildmode/gondola-06.png'
import bayImage7 from '../assets/images/buildmode/gondola-07.png'
import HoverButton from './HoverButton'
import Item from './Item'
import delButtonDefault from '../assets/images/delete-button-default.png'
import delButtonHovered from '../assets/images/delete-button-hovered.png'
import delButtonDisabled from '../assets/images/delete-button-disabled.png'
import addButtonDefault from '../assets/images/addbay-button-default.png'
import addButtonHovered from '../assets/images/addbay-button-hovered.png'
import addButtonDisabled from '../assets/images/addbay-button-disabled.png'
import Bar from './Bar'


export default class Gondola extends PIXI.Container {
  bayCount = 1
	style = new PIXI.TextStyle({fontFamily: "Mary Ann", fontSize: 22, fontWeight: 'bold', letterSpacing: 0.5, stroke: '#4c4c4c', strokeThickness: 1, align: 'center', fill: "#4c4c4c"});
	selectedStyle = new PIXI.TextStyle({fontFamily: "Mary Ann", fontSize: 22, fontWeight: 'bold', letterSpacing: 0.5, stroke: '#E55000', strokeThickness: 1, align: 'center', fill: "#E55000"}); 	
  static defaultBarYLocations = [6, 210] // y cooridinates of bars to drop by default 
  itemsIdentifiers = [Item.typeIdentifier, Bar.typeIdentifier]
  clothingIdentifiers = [Item.typeIdentifier]
  xStartPointOfGondola = 3.5
  yBottomSnapPoint = 438

  constructor(model, baySelectedCallback, bayAddedCallback, itemsRemovedCallback, gondolaIndex, startingBayIndex = 0, rangeBuilder) {
    super()
    this.rangeBuilder = rangeBuilder
    this.titleYOffset = -55
    this.addButtonProperties = {
      yOffset: this.titleYOffset - 10,
      scale:  { x: 0.325, y: 0.325 },
      horizontalPositionFactor: 0.7
    }
    this.deleteButtonProperties = {
      width: 48,
      xOffset: 5,
      yOffset: 10
    }
    this.bayLabelProperties = {
      type: 'bayLabelContainer',
      yOffset: 24,
      width: 70,
      xOffset: ((Gondola.singleBayWidth() - 70 - this.deleteButtonProperties.width) / 2) - 5,
      yUnderlineOffset: 30,
      underlineLength: 55,
      underlineLengthForTwoDigits: 65
    }

    this.gondolaId = model ? model.id : 0
    this.projectGradeRangeId = model ? model.projectGradeRangeId : 999
    this.rangeName = model.rangeName
    this.gondolaIndex = gondolaIndex
    this.type = Gondola.typeIdentifier
    this.interactive = true
    // initalise the background sprite and set width
    this.backGroundSprite = new PIXI.Sprite()
    this.backGroundSprite.visibleForMerge = true 
    this._setBayCount(model.bayCount)
    this.labelsContainer = new PIXI.Container()
    this.addChild(this.labelsContainer)
    this.visibleForMerge = true

    this.backGroundSprite.height = Gondola.getGondolaHeight()
    this.backGroundSprite.x = 0
    this.backGroundSprite.y = 0
    this.addChild(this.backGroundSprite)
    // stash the bay index if we arent the first gondola in range
    // and create labels
    this.startingBayIndex = startingBayIndex
    this._addLabelsAndButtons()
    this.baySelectedCallback = baySelectedCallback
    this.bayAddedCallback = bayAddedCallback
    this.itemsRemovedCallback = itemsRemovedCallback
    
    // add the addbay button
    this.addBtn = this._createAddBayButton()
    this.addChild(this.addBtn)
    // set up click event.
    this.pointerdown = this.bayClicked
    // Set the selected bay index to 'none selected' in this gondola
    this.selectedBayIndex = -1

    //Snapping Points for Gondola
    this.xSnappingPoints = []
    this.ySnappingPoints = []
  }

  hasBars(){
    return this.children.filter(item => item.type === Bar.typeIdentifier).length > 0    
  }

  deselectItems(){
    let items = this.children.filter(i => this.itemsIdentifiers.includes(i.type))
    items.map(i => i.removeSelectFrame())   
  }

  _addDefaultBars()
  {
    Gondola.createDefaultBars(this.bayCount, this.rangeBuilder)
  }

  static createDefaultBars(bayCount,rangeBuilder = null){
    let bars = []
    let barXCordinate = 0
    for(let i=0 ; i < bayCount ; i++)
    {
      barXCordinate = Gondola.getBayWidth(i + 1) - ( Gondola.getBayWidth(1) / 2)
      for(let x = 0 ; x < Gondola.defaultBarYLocations.length ; x++){
        bars.push(new Bar({x:barXCordinate,y: Gondola.defaultBarYLocations[x]}, rangeBuilder))
      }
    }
    return bars
  }

  _addBar(b){
    this.addChild(b)
  }

  _checkCanDeleteBay0(){
    let gondolas  = this.parent.children.filter(g => g.type ===Gondola.typeIdentifier)
    if(gondolas.length <= 1 && this.bayCount === 1)
    {
      //disable last bay delte button
      this.firstBayDelete.disable()
    }
  }

  toJson(){
    let items = this.children.filter(x => this.itemsIdentifiers.includes(x.type))
    let itemsModel = items.map(i => i.toJson())
    return {bayCount: this.bayCount, id: this.gondolaId, projectGradeRangeId : this.projectGradeRangeId ,items:JSON.stringify(itemsModel)}
  }

  bayClicked(e){
    this.parent.deselectItems()
		let bayIndex = Gondola.getBayIndex(e.data.global, this.bayCount)
    this.setSelectedBay(bayIndex)
  }

  setDropPositionToBayIndex(clickedBayIndex){
    if(clickedBayIndex === -1) {
      this.clickPosition = {}
    }
    let cpX = this.backGroundSprite.x + (clickedBayIndex * Gondola.singleBayWidth()) + ( Gondola.singleBayWidth()/ 2)
		let cpY = this.backGroundSprite.y + (Gondola.getGondolaHeight() / 2)
		this.clickPosition = { x: cpX, y: cpY }
  }

  addItem(rangeId, selectedItemPhoto, rangeItemPhotos, maxCanvasWidth, rootContainer){
    let item = new Item(selectedItemPhoto.imageId, selectedItemPhoto.resizedUri, rangeId, selectedItemPhoto.orientation, rangeItemPhotos, maxCanvasWidth, this.clickPosition, this.clickPosition, rootContainer, this.handleItemDelete)
    this.addChild(item)
    this.parent.setStateIsDirty(true)    
  }

  loadItem(model, rangeItemPhotos, maxCanvasWidth, rootContainer){
    let width = Gondola.getBayWidth(this.bayCount)
    let height = Gondola.getGondolaHeight()
    let equipmentWidth
    let equipmentHeight
    let sprite = null
    if (model.type) {
      sprite = new Bar({ x: model.x * width, y: model.y * height }, rootContainer)
      if (model.children) {
        model.children.forEach(child => {
          if (child.parentRelativeXPos || child.parentRelativeYPos) {
            switch (child.parentType) {
              case "Bar":
                {
                  equipmentWidth = Bar.getBarWidth()
                  equipmentHeight = Bar.getBarHeight()
                  break;
                }
                //more equipment can be put here
              default:
                break;
            }

            let childItem = new Item(child.imageId, child.imageUrl, child.rangeId, child.orientation, child.rangeItemPhotos, maxCanvasWidth, { x: model.X, y: model.y },
              { x: child.x * equipmentWidth, y: child.y * equipmentHeight }, rootContainer, this.handleItemDelete)
            sprite.addChild(childItem)
          }
        })
      }
    }
    else {
      sprite = new Item(model.imageId, model.imageUrl, model.rangeId, model.orientation, rangeItemPhotos, maxCanvasWidth, { x: null, y: null }, { x: model.x * width, y: model.y * height }, rootContainer, this.handleItemDelete)
    }

    this.addChild(sprite)
  }

  //Bind or arrow?
  handleItemDelete = (item) => {
    if (item != null) {
      this.itemsRemovedCallback([item.rangeId])
    }
  }

  setSelectedBay(index) {
    this.selectedBayIndex = index
    this._selectBay()
  }
  
  deselectBays(){
    // dont drop here anymore.
    this.selectedBayIndex = -1
    this.setDropPositionToBayIndex(this.selectedBayIndex)

    // foreach child reset the style.
    this._setAllBayLabelsToUnselectStyle()
  }

  _setAllBayLabelsToUnselectStyle(){
    this.labelsContainer.children.map(c => {
      if (c.children != null) {
        const bayLabelContainer = c.children.find(l => l.type === this.bayLabelProperties.type)
        if (bayLabelContainer != null && bayLabelContainer.children != null) {
          bayLabelContainer.children[0].style = this.style
          bayLabelContainer.children[1].visible = false
        }
      }
      return null
    })
  }

  _setBayLabelToSelectStyle(bayIndex){
    let container = this.labelsContainer.children[bayIndex]
    if (container != null) {
      const bayLabelContainer = container.children.find(l => l.type === this.bayLabelProperties.type)
      if (bayLabelContainer != null && bayLabelContainer.children != null) {
        bayLabelContainer.children[0].style = this.selectedStyle
        bayLabelContainer.children[1].visible = true
      }        
    }
  }

  rerenderLabels(newStartingBayIndex){
    this.startingBayIndex = newStartingBayIndex
    this.labelsContainer.children = []
    this._addLabelsAndButtons()
  }

  _setSelectedGondola(){
    this.parent.selectedGondola = this
  }

  _selectBay(){
    if (this.selectedBayIndex > -1) {
      // deselect bays in the other gondolas
      if (this.baySelectedCallback) { 
        this.baySelectedCallback(this)
      }
      this.setDropPositionToBayIndex(this.selectedBayIndex)
      this._setSelectedGondola()
      
      // foreach child reset the style.
      this._setAllBayLabelsToUnselectStyle()
      
      // set the orange style to the selected index
      this._setBayLabelToSelectStyle(this.selectedBayIndex)
    }
  }

  _getGondolaImage(){
		switch(this.bayCount)	{
			case 1: return bayImage1
			case 2: return bayImage2
			case 3: return bayImage3
			case 4: return bayImage4
			case 5: return bayImage5
			case 6: return bayImage6
			case 7: return bayImage7
			default : return null
		}
	}

  _setBayCount(number){
    this.bayCount = number
    this._setBackgroundTexture() 
  }

  _setBackgroundTexture(){
    if( this.bayCount > 0)
    {
      this.backGroundSprite.texture = PIXI.Texture.from(this._getGondolaImage())   
    }  
    this.backGroundSprite.width = Gondola.getBayWidth(this.bayCount)
  }

  _getBayLabelXPos(index){
    return (Gondola.singleBayWidth() * index) + this.bayLabelProperties.xOffset
  }

  _getBayLabelYPos(){
    return Gondola.getGondolaHeight() + this.bayLabelProperties.yOffset
  }

  _getDeleteButtonXPos(index){
    return this._getBayLabelXPos(index) + this.bayLabelProperties.width + this.deleteButtonProperties.xOffset
  }

  _getDeleteButtonYPos(){
    return Gondola.getGondolaHeight() + this.deleteButtonProperties.yOffset
  }
  
  _createGondolaTitle(){
    let title = new PIXI.Text()
    title.id = "gondTitle"
    title.anchor.set(0, 0)
    title.x = 0
    title.y = this.titleYOffset
    title.style = this.style
    title.text = "Gondola  " + (this.gondolaIndex + 1) + ", " + this.bayCount + " Bays"
    return title
  }

  _createBayLabel(i, bayNumber){
    const bayLabelUnderlineLengthToUse = bayNumber < 10 ? this.bayLabelProperties.underlineLength : this.bayLabelProperties.underlineLengthForTwoDigits

    let bayLabelContainer = new PIXI.Container()
    bayLabelContainer.type = this.bayLabelProperties.type
    
    let bayLabel = new PIXI.Text()
    bayLabel.id = i
    bayLabel.anchor.set(0, 0)
    bayLabel.x = this._getBayLabelXPos(i)
    bayLabel.y = this._getBayLabelYPos()
    bayLabel.style = i === this.selectedBayIndex ? this.selectedStyle : this.style
    bayLabel.text = "Bay " + (bayNumber + 1)
    bayLabelContainer.addChild(bayLabel)

    const bayLabelUnderline = new PIXI.Graphics()
    bayLabelUnderline.visible = i === this.selectedBayIndex 
    bayLabelUnderline.lineStyle(4, 0xE55000, 1)
		bayLabelUnderline.moveTo(bayLabel.x, bayLabel.y + this.bayLabelProperties.yUnderlineOffset)
    bayLabelUnderline.lineTo(bayLabel.x + bayLabelUnderlineLengthToUse, bayLabel.y + this.bayLabelProperties.yUnderlineOffset)
    bayLabelContainer.addChild(bayLabelUnderline)

    return bayLabelContainer
  }

  _createDeleteButton(i){
    let delButton = new HoverButton((e) => {this._tryDeleteBay(e,i)}, delButtonDefault, delButtonHovered, delButtonDisabled)
    delButton.id = 'delbtn' + i
    delButton.anchor.set(0, 0)
    delButton.x = this._getDeleteButtonXPos(i)
    delButton.y = this._getDeleteButtonYPos()
    return delButton
  } 
  
  _createAddBayButton(i){
    let addButton = new HoverButton((e) => {this._addBay(e)}, addButtonDefault, addButtonHovered, addButtonDisabled)
    addButton.id = 'addbtn' + i
    addButton.anchor.set(0, 0) 
    addButton.scale = this.addButtonProperties.scale
    addButton.x = Gondola.singleBayWidth() * this.addButtonProperties.horizontalPositionFactor
    addButton.y = this.addButtonProperties.yOffset
    if (this.bayCount >= 7 ) {
      addButton.disable()
    }
    return addButton
  } 

  _addLabelsAndButtons(){
    for (var i = 0; i < this.bayCount; i++) { 
      var container = new PIXI.Container()
      //keep as a child of labels container to stop breaking the bay index selection process.

      let bayNumber = this.startingBayIndex + i 
      let bayLabel = this._createBayLabel(i, bayNumber)
      this.lastBayNumber  = bayNumber // dont do async!!!
      let deleteButton = this._createDeleteButton(i)
      container.addChild(bayLabel)
      container.addChild(deleteButton)
      this.firstBayDelete = deleteButton
      if (i === 0) {
        let title = this._createGondolaTitle()
        container.addChild(title)
      }
      this.labelsContainer.addChild(container)
    }
  }

  _addBay(event) {
    this._setBayCount(this.bayCount + 1)
    if (this.bayCount === 7) {
      this.addBtn.disable()
    }
    this.parent.setStateIsDirty(true)
    this.addBarsToLastBay()
    this.bayAddedCallback()
    event.stopPropagation()
  }

  _tryDeleteBay(event , bayIndex) {
    event.stopPropagation()
    if (this._hasItems(bayIndex)) {
      this.parent.openDeleteBayModal(bayIndex + 1, this.gondolaIndex)
    } else {
      this.deleteBay(bayIndex)
    }
  }



  deleteBay(bayIndex) {
    // change the image and set new width
    this._setBayCount(this.bayCount - 1)
    if (this.bayCount < 7) {
      this.addBtn.enable()
    }

    this.parent.setStateIsDirty(true)
    if (this.bayCount < 1) {
      this.parent.deleteGondola(this)
    } else {
      this._removeBay(bayIndex)

      // Work out which bay to select
      let newSelectedBayIndex = -1
      if (this.selectedBayIndex > -1) {
        newSelectedBayIndex = this.selectedBayIndex
        if (this.bayCount === 0) {
          newSelectedBayIndex = -1
        } else if (this.selectedBayIndex > bayIndex || (this.selectedBayIndex === bayIndex && this.selectedBayIndex === this.bayCount)) {
          // If we are deleting a bay to the left of the selected bay or the last bay (if it is selected), move the selected bay
          // one place to the left and move the click position as well
          newSelectedBayIndex = this.selectedBayIndex - 1
        }
      }

      this.setSelectedBay(newSelectedBayIndex)
    }
  }

  _hasItems(bayIndex) {
    let leftEdgeOfBayBeingDeleted = Gondola.getBayWidth(this.bayCount, bayIndex)
    let rightEdgeOfBayBeingDeleted = leftEdgeOfBayBeingDeleted + Gondola.singleBayWidth()
    let items = []
    this.children.forEach(b => {
      if (this.itemsIdentifiers.includes(b.type) && b.x > leftEdgeOfBayBeingDeleted && b.x < rightEdgeOfBayBeingDeleted) {
        if (this.clothingIdentifiers.includes(b.type)) {
          items.push(b)
        }
        else {
          let children = b.children.filter(c => this.clothingIdentifiers.includes(c.type))
          if (children.length > 0) {
            items.push(children)
          }
        }
      }
    })
    if(items.length > 0) {
      return items.flat().length > 0
    }
    return false
  }

  _removeBay(bayIndex){
    // remove the labels and buttons 
    this.labelsContainer.children.splice(bayIndex, 1)
    // call back to rangeBuilder to re label gondolas
    this.bayAddedCallback()
    this._removeItems(bayIndex)
    this._shiftItems(bayIndex)
    this._checkCanDeleteBay0()
  }

  _shiftItems(bayIndex){
    let rightHandEdgeOfDeltedBay = Gondola.getBayWidth(this.bayCount, bayIndex ) + Gondola.singleBayWidth()
    let childItems = this.children.filter(c => this.itemsIdentifiers.includes(c.type) && c.x + (c.texture.width / 2) > rightHandEdgeOfDeltedBay)
    childItems.map(c => {
      c.x = c.x - Gondola.singleBayWidth()
      return null
    })
    this.children.filter(c => this.itemsIdentifiers.includes(c.type)).map(m => m.moveItemInsideGondolaBounds())
  }
 
  _removeItems(bayIndex){
     let leftEdgeOfBayBeingDelted = Gondola.getBayWidth(this.bayCount, bayIndex)
     let childItems = this.children.filter(c => this.itemsIdentifiers.includes(c.type) && c.x > leftEdgeOfBayBeingDelted && c.x < leftEdgeOfBayBeingDelted + Gondola.singleBayWidth())
     childItems.map(i => this.removeChild(i))
     if (childItems.length > 0) {
       this.itemsRemovedCallback(childItems.map(i => i.rangeId))
     }
  }

  
  createXSnappingPoints() {
    const baySize = 297
    let newXSnappingPoints = []
    let leftOfGondola = this.x + this.xStartPointOfGondola
    let rightOfGondola = leftOfGondola + (baySize * this.bayCount)
    for (var i = leftOfGondola; i <= rightOfGondola; i += baySize) {
      newXSnappingPoints.push(i)
    }

    return newXSnappingPoints
  }

  createYSnappingPoints() {
    let newYSnappingPoints = []
    let bottomOfGondola = this.yBottomSnapPoint
    for (var i = 0 + 6; i <= bottomOfGondola; i += 12) {
      newYSnappingPoints.push(i)
    }
    return newYSnappingPoints
  }

  

  static getBayIndex(clickPosition, bayCount = 7){
    for (let i = 0; i < bayCount; i++) { 
      let bayRight = Gondola.getBayWidth(bayCount, i + 1)
      if (clickPosition.x <= bayRight) {
        return i
      }
		}
		return bayCount
  }

	static getBayWidth(bayCount, bayIndex = bayCount){
		let rightMargin = 5
		let leftMargin = 4
		let bayWidth = Gondola.singleBayWidth()
		let rightMarginAdjusted = 0

		if (bayCount === bayIndex) {
			rightMarginAdjusted = rightMargin
		}

		return leftMargin + (bayIndex * bayWidth) + rightMarginAdjusted
	}

  static sortOnY(a,b){
    if(a.y > b.y) return 1;
    if(b.y > a.y) return -1
  }

  static getGondolaHeight(){
    return 503
  }
  
  static singleBayWidth(){
		return 297
  }
  
  static totalGondolaHeightIncludingControls(){
    return 793
  }

  addBarsToLastBay(){
    let barXCordinate =  Gondola.getBayWidth(this.bayCount) - ( Gondola.getBayWidth(1) / 2)
    for(let x = 0 ; x < Gondola.defaultBarYLocations.length ; x++){
      let bar = new Bar({x:barXCordinate,y: Gondola.defaultBarYLocations[x]}, this.parent)
      this.addChild(bar)
    }
  }

  addDefaultBarsToAllBays(){
    for(let i = 0 ; i< this.bayCount + 1; i++){
      this.addBarsToBay(i)
    }
  }
  
  addBarsToBay(bayIndex){
    let barXCordinate =  Gondola.getBayWidth(bayIndex) - ( Gondola.getBayWidth(1) / 2)
    for(let x = 0 ; x < Gondola.defaultBarYLocations.length ; x++){
      let bar = new Bar({x:barXCordinate,y: Gondola.defaultBarYLocations[x]}, this.parent)
      this.addChild(bar)
    }
  }


  static generateBarItemsForNewGondola(bayCount, root = null )
  {
    let barXCordinate = 0
    let result = []
    for(let i=0 ; i < bayCount  ; i++)
    {
      barXCordinate = Gondola.getBayWidth(i + 1) - ( Gondola.getBayWidth(1) / 2)
      for(let x = 0 ; x < Gondola.defaultBarYLocations.length ; x++){
        let bar = new Bar({x:barXCordinate,y: Gondola.defaultBarYLocations[x]}, root)
        bar.parent = {bayCount : bayCount}
        result.push(bar.toJson())
      }
    }
    return result
  }

  static typeIdentifier = "Gondola"
}
